import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useLayoutEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import axios from "axios";
import SearchInput from "./SearchInput"; // Import the new component
import { CiHome } from "react-icons/ci";
import { SlMagnifier } from "react-icons/sl";
import LogoPng from '../images/logo.png'
import '../styles/header.css';
import GetQuoteForm from "./GetQuote";
import Whatsapp from '../components/landingpage/whatsapp';


function Nav() {
  const navigate = useNavigate();
  const location = useLocation();
  const [showFullNavbar, setShowFullNavbar] = useState(false);
  const [mediaWidth, setMediaWidth] = useState(true);
  const [user, setUser] = useState([]);
  const [val, setval] = useState("");
  const [key, setKey] = useState("");
  const [listcities, setCitiesList] = useState([]);
  const [allcats, setAllcats] = useState([]);
  const [notf, setNotf] = useState([]);
  const [pageLocation, setPageLocation] = useState(true);
  const [userType, setUserType] = useState("");

  const [inputSearch, setInputSearch] = useState(""); // State to hold dynamic search value

  const BuyerApps = [
    { name: 'Home', url: '/' },
    { name: 'Buyer', url: '/buyer' },
    // { name: 'Seller', url: '/seller' },
    { name: 'Services', url: '/services' },
    { name: 'EWallet', url: '/wallet' },
    { name: 'Blogs', url: '/blogs' }
  ]

  useLayoutEffect(() => {
    // const url = "/sub/electronics-electrical";
    if(location.pathname){
      const parts = location.pathname.split('/');  // Splitting the string by '/'
      const subpath = parts[1];
      console.log('pae_location', subpath);
      if(subpath === 'sub'){
        setPageLocation(false);
      }
    }
  }, [location])

  useEffect(() => {
    // Function to check the window's width
    const handleResize = () => {
      if (window.innerWidth <= 992) {
        setMediaWidth(true);
      } else {
        setMediaWidth(false);// Set to false if the width is greater than 935px
        setShowFullNavbar(false);
      }
    };

    // Call handleResize on initial render
    handleResize();

    // Add event listener to window resize event
    window.addEventListener('resize', handleResize);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  const logout = async () => {
    localStorage.removeItem("USER");
    navigate("/login");

  };

  const getServercats = async () => {
    const data = new FormData();

    data.append("id", "0");
    axios({
      method: "post",

      url: "/mainsite/suball.php",

      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log("all cats nav br",s.data)
        setAllcats(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };


  const Notfication = async (uid) => {
    const data = new FormData();
    data.append("val", "viewbyid");
    data.append("uid", uid);
    axios({
      method: "post",
      url: "/pro/notification/manage_notification.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("notfication abc", s.data);
        setNotf(s.data)

      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  // 2) Delete a single notification
  const handleRemoveNotification = async (id) => {
    const formData = new FormData();
    formData.append("val", "deletenotification");
    formData.append("id", id);
  
    try {
      const res = await axios.post("/pro/notification/manage_notification.php", formData);
      console.log("remove_notification response => ", res.data);
      
      if (res.data.code === "success") {
        // remove item in local state
        setNotf((prev) => prev.filter((n) => n.id !== id));
      } else {
        console.error("Failed to remove notification:", res.data.msg);
      }
    } catch (error) {
      console.error("Error removing notification", error);
    }
  };
  

  // 3) Delete all notifications for this uid
  const handleRemoveAllNotifications = async () => {
    const formData = new FormData();
    formData.append("val", "deleteallnotifications");
    formData.append("uid", user[0].id);

    try {
      const res = await axios.post("/pro/notification/manage_notification.php", formData);
      if (res.data.code === "success") {
        // Clear local state
        setNotf([]);
      } else {
        console.error("Failed to remove all notifications:", res.data.msg);
      }
    } catch (error) {
      console.error("Error removing all notifications", error);
    }
  };


  const loginuser = async (k) => {
    const data = new FormData();
    data.append("status", "key");
    data.append("key", k);
    axios({
      method: "post",
      url: "mainsite/login.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log(s.data);
        if (s.data[0].msg === "pass") {
          localStorage.setItem("USER", JSON.stringify(s.data));
          setUser(s.data);
        }
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  const updateSelection = () => {

    const url = new URL(window.location.href);
    const pathname = url.pathname;
    const searchParams = url.searchParams;

    // Determine the default value for the select box
    let defaultValue = "product";
    if (searchParams.has("product")) {
      defaultValue = "product";
      setKey(searchParams.get("product")); // Set input value
    } else if (searchParams.has("supplier")) {
      defaultValue = "company";
      setKey(searchParams.get("supplier")); // Set input value
    } else if (pathname.includes("/supplier")) {
      defaultValue = "supp";
      setKey(searchParams.get("name") || ""); // Set input value
    } else if (pathname.includes("/sub/")) {
      const dynamicValue = pathname.split("/sub/")[1]; // Extract the value after "/sub/"
      defaultValue = "cat";
      //setInputSearch(dynamicValue || ""); // Update the state with the dynamic value
      // Triggering the onSearch after setting the value
      handleOnSearchCat(dynamicValue || "", allcats); // Pass the dynamic value and items (allcats) to onSearch
    }

    // Set the select box value
    setval(defaultValue);
  }



  useEffect(() => {
    getCities();
    getServercats();

    const aa = localStorage.getItem("USER");
    if (aa != null) {
      const as = JSON.parse(aa);
      Notfication(as[0].id)


      console.log("sss", as);
      setUser(as);
      setUserType(as[0].roll)
    } else {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const key = params.get("a");

      if (key === null) {
        // console.log("key is null");
      } else {
        // console.log("value ", key);
        loginuser(key);
      }
    }

    updateSelection();
  }, []);



  const items = [
    { name: 'Punjab' },
    { name: 'Khyber Pakhtunkhwa' },
    { name: 'Sindh' },
    { name: 'Balochistān' },
    { name: 'Gilgit-Baltistan' }
  ]

  const handleOnSearch = (string, results) => {
    // console.log(string, results)

  }

  const handleOnSelect = (item) => {
    // console.log("finally selected",item.name)
    localStorage.setItem("title", item.name);
    window.location.href = "/search?c=" + item.name + "&s=" + item.name + "";
  }
  const handleOnSearchCat = (string, results) => {
    // console.log(string, results)

  }

  const handleOnSelectCat = (item) => {

    localStorage.setItem("title", item.title);
    window.location.href = "/sub/" + item.bread_crumbs;
  }

  const handleOnSearch_Pro = (string, results) => {
    // console.log(string, results)

  }

  const handleOnSelect_Pro = (item) => {

    localStorage.setItem("keycity", item.title);
    window.location.href = "/search?c=" + item.name + "&s=" + item.name + "";
  }


  const getCities = async () => {
    const data = new FormData();

    axios({
      method: "post",
      url: "/pro/auth/allcity_search.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setCitiesList(s.data)
      })
      .catch((s) => {

      });
  };

  return (
    <div>
      <Whatsapp />
      <div className="topbar">
        <div className="container-topbar">
          <div className="d-none d-xl-block">
            <div className="nav-small">
              <div >
                {/* <a href="https://www.google.com/maps/place/Alahdeen+B2B/@28.1790625,68.0482422,7z/data=!4m6!3m5!1s0x3eb33d004dd88a67:0x5be76a9ffcfcf8fe!8m2!3d24.8327703!4d67.0699403!16s%2Fg%2F11y73m3zvj?coh=219814&entry=tts&g_ep=EgoyMDI0MDgxOC4wKgBIAVAD"> */}
                <span className="banner-text" style={{ color: '#f1f5f9' }}>
                  <span className="banner-page-links"> About Us |</span>
                  <span className="banner-page-links"> Blog |</span>
                  <span className="banner-page-links"> Contact Us</span>

                </span>
                {/* </a> */}
              </div>


            </div>
          </div>
          <div className="info-topbar text-center d-none d-xl-block">
            <span className="color-brand-3">
              <strong className="banner-text">   The Fastest Growing B2B Network in Pakistan </strong>
            </span>
            {/* <span className="font-sm-bold color-success"> $75.00</span> */}
          </div>
          <div className=""> {/* menu-topbar-right */}
            <span className="banner-text" style={{ color: '#f1f5f9' }}>Need help? Call Us: </span>
            <span className="font-sm-bold banner-text"> 04249484993</span>


          </div>
        </div>
      </div>

      <header className="section-header-navbar">
        <section className="header-main border-bottom" >
          <div className="container-fluid">

            <div className="row">
              <div className="d-flex justify-content-between align-items-center">


                <div
                  onClick={() => {
                    navigate("/");
                  }}
                  className={showFullNavbar ? "d-none" : "d-block col-4 col-lg-2"}
                >
                  <a href="/">
                    <img width={'200px'} src={LogoPng} /> </a>
                </div>

                {/* Search Bar */}

                <div className={mediaWidth ? 'd-none' : "d-block col-lg-4 col-1 search-navbar-container"}>
                  <div
                    style={{
                      color: '#cbd5e1',
                      borderRadius: '12px',
                      border: '1px solid black'
                    }}
                  >
                    {/* <div style={{ marginTop: "4px" }} className="col-4">
                  <select
                      className="form-control form-control-lg form-select"
                      style={{ borderRadius: "0.5rem" }}
                      value={val}
                      onChange={(v) => {

                        setKey("");

                        if (v.target.value == "a") {
                        } else {
                          setval(v.target.value);
                        }
                      }}>
                      <option value="product"> Product </option>
                      <option value="company"> Supplier's Product </option>
                      <option value="supp"> Supplier's </option>
                      <option value="cat"> Category </option>
                    </select>

                  </div> */}

                    <div className="d-flex" style={{ zIndex: 2, }} aria-haspopup="true" aria-expanded="false" >

                      <select
                        style={{ fontSize: '14px', color: '#9ca3af', border: 'none', marginLeft: '11px' }}
                        // className="form-control form-control-lg form-select navbar-search-select"
                        // style={{ borderRadius: "0.5rem" }}
                        value={val}
                        onChange={(v) => {

                          setKey("");

                          if (v.target.value == "a") {
                          } else {
                            setval(v.target.value);
                          }
                        }}>
                        {/* <option style={{ color: '#9ca3af' }} value="product"> Search Type </option> */}
                        <option value="product"> Product </option>
                        <option value="company"> Supplier's Product </option>
                        <option value="supp"> Supplier's </option>
                        <option value="cat"> Category </option>
                      </select>

                      <div style={{
                        height: '100%',
                        color: 'black',
                        margin: '10px 5px'
                      }}>
                        |
                      </div>

                      <SearchInput
                        placeholder="Product Name"
                        value={key}
                        setValue={setKey}
                        itemValue={val}
                      />

                      {/* <div style={{
                      height: '100%',
                      color: 'black',
                      margin: '10px 5px'
                    }}>
                      |
                    </div> */}

                      <SlMagnifier
                        style={{ marginTop: 'auto', marginBottom: 'auto', fontWeight: 800, width: '36px', height: '32px', color: 'rgb(69, 172, 179)', marginRight: '5px' }}
                        onClick={() => {
                          // setShowFullNavbar(true);
                          if (key.length < 4) {
                            alert("search minimum 4 characters!")

                          } else {
                            localStorage.setItem("title", key);
                            window.location.href = "/search?product=" + key;
                          }
                        }}
                      />

                      {/* <button style={{ borderRadius: "1rem", padding: "10px" }} className="btn btn-search btn-primary" > Search </button> */}
                    </div>

                    {/* <div className="col-4" style={{ zIndex: 2, }} aria-haspopup="true" aria-expanded="false" >
                    {
                      val == "city" ?
                        <ReactSearchAutocomplete
                          items={listcities}
                          onSearch={handleOnSearch}
                          onSelect={handleOnSelect}
                          autoFocus
                        />
                        : val == "cat" ?
                          <>
                            <ReactSearchAutocomplete
                              style={"fo"}
                              items={allcats}
                              onSearch={handleOnSearchCat}
                              inputSearchString={inputSearch}
                              onSelect={handleOnSelectCat}
                              autoFocus

                            />



                          </>
                          : val == "pro" ? <ReactSearchAutocomplete
                            items={items}
                            onSearch={handleOnSearch_Pro}
                            onSelect={handleOnSelect_Pro}
                            autoFocus
                          /> : val == "company" ? (
                            <SearchInput
                              placeholder="Supplier Name"
                              value={key}
                              setValue={setKey}
                              itemValue={val}
                            />
                          ) : val == "supp" ? (
                            <SearchInput
                              placeholder="Supplier's"
                              value={key}
                              setValue={setKey}
                              itemValue={val}
                            />
                          ) : (
                            <SearchInput
                              placeholder="Product Name"
                              value={key}
                              setValue={setKey}
                              itemValue={val}
                            />
                          )
                    }
                  </div> */}
                    {/* <div className="col-2">

                    {
                      val == "cat" ? <>

                      </> :

                        val == "company" ?
                          <>
                            <button style={{ borderRadius: "1rem", padding: "10px" }} className="btn btn-search btn-primary" onClick={() => {
                              if (key.length < 4) {
                                alert("search minimum 4 characters!")

                              } else {
                                localStorage.setItem("title", key);
                                window.location.href = "/search?supplier=" + key;
                              }
                            }}> Search </button>
                          </> : val == "supp" ? <>

                            <button style={{ borderRadius: "1rem", padding: "10px" }} className="btn btn-search btn-primary" onClick={() => {
                              if (key.length < 4) {
                                alert("search minimum 4 characters!")

                              } else {
                                localStorage.setItem("title", key);
                                window.location.href = "/supplier?name=" + key;
                              }
                            }}> Find Now </button>

                          </>
                            :
                            <>
                              <button style={{ borderRadius: "1rem", padding: "10px" }} className="btn btn-search btn-primary" onClick={() => {
                                if (key.length < 4) {
                                  alert("search minimum 4 characters!")

                                } else {
                                  localStorage.setItem("title", key);
                                  window.location.href = "/search?product=" + key;
                                }
                              }}> Search </button></>
                    }



                  </div> */}
                  </div>



                </div>


                {<div className={showFullNavbar ? 'd-block col-12' : "d-none"}>
                  <div
                    // className="row"
                    style={{
                      color: '#cbd5e1',
                      borderRadius: '12px',
                      border: '1px solid black'
                    }}
                  >
                    {/* <div style={{ marginTop: "4px" }} className="col-4">
                  <select
                      className="form-control form-control-lg form-select"
                      style={{ borderRadius: "0.5rem" }}
                      value={val}
                      onChange={(v) => {

                        setKey("");

                        if (v.target.value == "a") {
                        } else {
                          setval(v.target.value);
                        }
                      }}>
                      <option value="product"> Product </option>
                      <option value="company"> Supplier's Product </option>
                      <option value="supp"> Supplier's </option>
                      <option value="cat"> Category </option>
                    </select>

                  </div> */}

                    <div className="d-flex" style={{ zIndex: 2, }} aria-haspopup="true" aria-expanded="false" >

                      <select
                        style={{ fontSize: '14px', color: '#9ca3af', border: 'none', marginLeft: '11px' }}
                        // className="form-control form-control-lg form-select navbar-search-select"
                        // style={{ borderRadius: "0.5rem" }}
                        value={val}
                        onChange={(v) => {

                          setKey("");

                          if (v.target.value == "a") {
                          } else {
                            setval(v.target.value);
                          }
                        }}>
                        {/* <option value="product"> Search Type </option> */}
                        <option value="product"> Product </option>
                        <option value="company"> Supplier's Product </option>
                        <option value="supp"> Supplier's </option>
                        <option value="cat"> Category </option>
                      </select>

                      <div style={{
                        height: '100%',
                        color: 'black',
                        margin: '10px 5px'
                      }}>
                        |
                      </div>

                      <SearchInput
                        placeholder="Product Name"
                        value={key}
                        setValue={setKey}
                        itemValue={val}
                      />

                      {/* <div style={{
                      height: '100%',
                      color: 'black',
                      margin: '10px 5px'
                    }}>
                      |
                    </div> */}

                      <SlMagnifier
                        style={{ marginTop: 'auto', marginBottom: 'auto', fontWeight: 800, width: '36px', height: '36px', color: 'rgb(69, 172, 179)', marginRight: '5px' }}
                        onClick={() => {
                          setShowFullNavbar(true);
                          if (key.length < 4) {
                            alert("search minimum 4 characters!")

                          } else {
                            localStorage.setItem("title", key);
                            window.location.href = "/search?product=" + key;
                          }
                        }}
                      />

                      {/* <button style={{ borderRadius: "1rem", padding: "10px" }} className="btn btn-search btn-primary" > Search </button> */}
                    </div>

                    {/* <div className="col-4" style={{ zIndex: 2, }} aria-haspopup="true" aria-expanded="false" >
                    {
                      val == "city" ?
                        <ReactSearchAutocomplete
                          items={listcities}
                          onSearch={handleOnSearch}
                          onSelect={handleOnSelect}
                          autoFocus
                        />
                        : val == "cat" ?
                          <>
                            <ReactSearchAutocomplete
                              style={"fo"}
                              items={allcats}
                              onSearch={handleOnSearchCat}
                              inputSearchString={inputSearch}
                              onSelect={handleOnSelectCat}
                              autoFocus

                            />



                          </>
                          : val == "pro" ? <ReactSearchAutocomplete
                            items={items}
                            onSearch={handleOnSearch_Pro}
                            onSelect={handleOnSelect_Pro}
                            autoFocus
                          /> : val == "company" ? (
                            <SearchInput
                              placeholder="Supplier Name"
                              value={key}
                              setValue={setKey}
                              itemValue={val}
                            />
                          ) : val == "supp" ? (
                            <SearchInput
                              placeholder="Supplier's"
                              value={key}
                              setValue={setKey}
                              itemValue={val}
                            />
                          ) : (
                            <SearchInput
                              placeholder="Product Name"
                              value={key}
                              setValue={setKey}
                              itemValue={val}
                            />
                          )
                    }
                  </div> */}
                    {/* <div className="col-2">

                    {
                      val == "cat" ? <>

                      </> :

                        val == "company" ?
                          <>
                            <button style={{ borderRadius: "1rem", padding: "10px" }} className="btn btn-search btn-primary" onClick={() => {
                              if (key.length < 4) {
                                alert("search minimum 4 characters!")

                              } else {
                                localStorage.setItem("title", key);
                                window.location.href = "/search?supplier=" + key;
                              }
                            }}> Search </button>
                          </> : val == "supp" ? <>

                            <button style={{ borderRadius: "1rem", padding: "10px" }} className="btn btn-search btn-primary" onClick={() => {
                              if (key.length < 4) {
                                alert("search minimum 4 characters!")

                              } else {
                                localStorage.setItem("title", key);
                                window.location.href = "/supplier?name=" + key;
                              }
                            }}> Find Now </button>

                          </>
                            :
                            <>
                              <button style={{ borderRadius: "1rem", padding: "10px" }} className="btn btn-search btn-primary" onClick={() => {
                                if (key.length < 4) {
                                  alert("search minimum 4 characters!")

                                } else {
                                  localStorage.setItem("title", key);
                                  window.location.href = "/search?product=" + key;
                                }
                              }}> Search </button></>
                    }



                  </div> */}
                  </div>



                </div>}






                {user.length == 0 ? (
                  <>
                    <div className={showFullNavbar ? "d-none" : "col-lg-4 col-7 d-flex justify-content-end align-items-center"}>
                      <div className="widgets-wrap d-flex">

                        <div className="widget-header mr-3">
                          <SlMagnifier
                            className={mediaWidth ? "d-block" : "d-none"}
                            style={{ marginTop: 'auto', marginBottom: 'auto', fontWeight: 800, width: '30px', height: '30px', color: '#45ACB3', marginRight: '5px' }}
                            onClick={() => {
                              setShowFullNavbar(true);
                              // const ele = document.getElementsByClassName('search-navbar-container');
                              // ele.style.display = 'block';
                            }}
                          />

                        </div>
                        {/* <div className="widgets-wrap float-md-right"> */}
                        <div className="widget-header mr-1">
                    
                            <button onClick={() => {
                              navigate("/login");
                            }} className="navbar-login-btn box-shad-btn"> Login </button>
                        </div>
                        <div className="widget-header mr-1">
                              <button onClick={() => {
                              navigate("/signup");
                            }} className="navbar-login-btn box-shad-btn">Signup</button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={showFullNavbar ? "d-none" : "col-lg-4 col-8 d-flex justify-content-end align-items-center gap-2"}>

                      <div className="widget-header mr-lg-3">
                        <div

                          className="text-center">
                          <SlMagnifier
                            className={mediaWidth ? "d-block search-home-icon" : "d-none"}
                            style={{ marginTop: 'auto', marginBottom: 'auto', fontWeight: 800, width: '30px', height: '24px', color: '#45ACB3', marginRight: '5px' }}
                            onClick={() => {
                              setShowFullNavbar(true);
                            }}
                          />


                        </div>
                      </div>
                      <div className="widget-header mr-lg-3">
                        <a href="/chat" className="widget-view">
                          <div
                            className="icon-area text-center">
                            {/* <i
                              style={{ color: "#45ACB3" }}
                              className="ti-email"
                            ></i> */}
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#45ACB3"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z" /></svg>
                          </div>
                          <small className="text d-none d-sm-block mt-2">
                            <strong className="text-dark"> Chat </strong>{" "}
                          </small>
                        </a>
                      </div>
                      <div className="widget-header mr-lg-3" style={{ cursor: "pointer" }}>
                        <div className="dropdown show d-flex">
                          {/* Bell Icon Trigger */}
                          <a
                            className="dropdown-toggle"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <span className="widget-view">
                              <div className="icon-area text-center">
                                {/* Notification Icon */}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="24px"
                                  viewBox="0 -960 960 960"
                                  width="24px"
                                  fill="#45ACB3"
                                >
                                  <path d="M160-200v-80h80v-280q0-83 50-147.5T420-792v-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v28q80 20 130 84.5T720-560v280h80v80H160Zm320-300Zm0 420q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM320-280h320v-280q0-66-47-113t-113-47q-66 0-113 47t-47 113v280Z" />
                                </svg>
                              </div>
                              <small className="text d-none d-sm-block mt-2">
                                <strong className="text-dark" style={{fontSize: "14px"}}>Notification</strong>
                              </small>
                            </span>
                          </a>

                          {/* Dropdown Menu */}
                          <div
                            className="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuLink"
                            style={{
                              position: "relative",
                              width: "380px",
                              height: "500px",
                              overflowY: "auto",
                            }}
                            onClick={(e) => {
                              // Prevent Bootstrap’s default behavior of closing the menu
                              e.stopPropagation();
                            }}
                          >
                            {/* If there are NO notifications */}
                            {notf.length === 0 ? (
                              <div className="p-3 text-center">
                                <h6 className="m-0">There are no notifications available</h6>
                              </div>
                            ) : (
                              <>
                                {/* Otherwise, show the list */}
                                {notf.map((item) => (
                                  <div
                                    className="dropdown-item"
                                    key={item.id}
                                    style={{ position: "relative" }}
                                  >
                                    {/* Title */}
                                    <h6>
                                      <div
                                        className="icon-area text-center"
                                        style={{ display: "inline-block", marginRight: "8px" }}
                                      >
                                        {/* Notification SVG Icon */}
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          height="24px"
                                          viewBox="0 -960 960 960"
                                          width="24px"
                                          fill="#45ACB3"
                                        >
                                          <path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z" />
                                        </svg>
                                      </div>
                                      {item.title}
                                    </h6>

                                    {/* Body */}
                                    <p style={{ marginBottom: "4px" }}>{item.body}</p>

                                    {/* Single-Delete (X) Icon */}
                                    <span
                                      onClick={() => handleRemoveNotification(item.id)}
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "10px",
                                        cursor: "pointer",
                                        fontSize: "20px",
                                        color: "red",
                                      }}
                                    >
                                      &times;
                                    </span>

                                    <span className="border-bottom"></span>
                                  </div>
                                ))}

                                {/* Sticky "Remove All" Footer (only shown if there are notifications) */}
                                <div className="remove-all-btn-container">
                                <button
                                  onClick={handleRemoveAllNotifications}
                                  className="btn btn-sm btn-danger remove-all-btn"
                                >
                                  Remove All Notifications
                                </button>
                              </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="widget-header mr-lg-3 dropdown">
      {/* Dropdown Toggle */}
      <a
        className="d-flex align-items-center widget-view dropdown-toggle"
        href="#"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div>
          <div className="icon-area text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#45ACB3"
            >
              <path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z" />
            </svg>
          </div>
          <small className="text d-none d-sm-block mt-2">
            <strong> {user[0].name} </strong>{" "}
            <i className="fa fa-caret-down ml-1"></i> {/* Down arrow */}
          </small>
        </div>
      </a>

      {/* Dropdown Menu - Right Aligned */}
      <ul className="dropdown-menu dropdown-menu-right">
        {/* Dashboard */}
        <li>
          <a href="/buyer" className="widget-header dropdown-item">
            <div className="d-flex align-items-center">
              <CiHome
                style={{ color: "#45ACB3", width: "16px", height: "16px" }}
              />
              <small className="fs-6 text-body-tertiary mx-2"> Dashboard </small>

            </div>
          </a>
        </li>

        {/* Logout */}
        <li>
          <a
            className="dropdown-item widget-header"
            href="#"
            style={{ cursor: "pointer", display: "flex" }}
            onClick={() => logout()}
          >
            <div className="d-flex align-items-center">
              <i
                className="ti-power-off mt-1"
                style={{ color: "#45ACB3", width: "16px", height: "16px" }}
              ></i>
              <small className="fs-6 text-body-tertiary mx-2"> Logout </small>
            </div>
          </a>
        </li>

        {/* Start Sell */}
        <li>
          <a href="/seller" className="widget-header dropdown-item">
            <div className="d-flex align-items-center">
              <i
                className="ti-wallet"
                style={{ color: "#45ACB3", width: "16px", height: "16px" }}
              ></i>
              <small className="fs-6 text-body-tertiary mx-2">
                Start Sell
              </small>
            </div>
          </a>
        </li>
      </ul>
    </div>

                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>

        {pageLocation && <div className="container-lg">
          <div className="row">
          <ul className="d-flex gap-2 overflow-scroll bottom-nav-container">
            {BuyerApps.map(item => (
              <a 
                key={item.url} 
                href={item.url} 
                className="a-nav-item" 
                style={{ display: 'inline-block' }} // Ensures `li` behaves properly inside `a`
              >
                <li
                  style={{ boxShadow: '0 5px 20px rgba(0, 0, 0, 0.11)', cursor: 'pointer' }}
                  className="nav-item bottom-nav-item p-2 border rounded-pill px-4 py-1 my-2"
                  whileTap={{
                    scale: 0,
                    borderRadius: "100%"
                  }}>
                  <span className="nav-link font-weight-bold">
                    {item.name}
                  </span>
                </li>
              </a>
            ))}
          </ul>
        </div>

        </div>}



        {/* <li
                  whileHover={{ scale: 1.5, }}
                  whileTap={{
                    scale: 0,

                    borderRadius: "100%"
                  }}
                  className="nav-item mx-1">
                  <a className="nav-link  font-weight-bold" href="/seller">
                    Seller
                  </a>
                </li>
                <li
                  whileHover={{ scale: 1.5, }}
                  whileTap={{
                    scale: 0,

                    borderRadius: "100%"
                  }}
                  className="nav-item mx-1" >
                  <a className="nav-link  font-weight-bold" href="/services/">
                    Services
                  </a>

                </li> */}
        {/*    
                <li 
                 whileHover={{ scale: 1.5, }}
                 whileTap={{
                   scale: 0,
                   
                   borderRadius: "100%"
                 }}
                className="nav-item mx-1" >
                  <a className="nav-link  font-weight-bold" href="/services/inventory.html">
                  Inventory 
                  </a>
                  
                </li>
                <li 
                 whileHover={{ scale: 1.5, }}
                 whileTap={{
                   scale: 0,
                   
                   borderRadius: "100%"
                 }}
                className="nav-item mx-1" >
                  <a className="nav-link  font-weight-bold" href="/services/office.html">
                  Office
                  </a>
                  
                </li>
                <li 
                 whileHover={{ scale: 1.5, }}
                 whileTap={{
                   scale: 0,
                   
                   borderRadius: "100%"
                 }}
                className="nav-item mx-1" >
                  <a className="nav-link  font-weight-bold" href="/services/ewallet.html">
                  E-Wallet
                  </a>
                  
                </li>
                <li 
                 whileHover={{ scale: 1.5, }}
                 whileTap={{
                   scale: 0,
                   
                   borderRadius: "100%"
                 }}
                className="nav-item mx-1" >
                  <a className="nav-link  font-weight-bold" href="/services/warehouse.html">
                  Warehouse
                  </a>
                  
                </li>
                <li 
                 whileHover={{ scale: 1.5, }}
                 whileTap={{
                   scale: 0,
                   
                   borderRadius: "100%"
                 }}
                className="nav-item mx-1" >
                  <a className="nav-link  font-weight-bold" href="/services/adon.html">
                  Advertise With Us
                  </a>
                  
                </li>
                <li 
                 whileHover={{ scale: 1.5, }}
                 whileTap={{
                   scale: 0,
                   
                   borderRadius: "100%"
                 }}
                className="nav-item mx-1" >
                  <a className="nav-link  font-weight-bold" href="/services/logistics.html">
                  Logistics
                  </a>
                  
                </li>
                <li
                 whileHover={{ scale: 1.5, }}
                 whileTap={{
                   scale: 0,
                   
                   borderRadius: "100%"
                 }}
                className="nav-item mx-1" >
                <a className="nav-link  font-weight-bold" href="/services/aboutus.html">
                About Us
                </a>
                </li> */}
        {/* <li
                  whileHover={{ scale: 1.5, }}
                  whileTap={{
                    scale: 0,

                    borderRadius: "100%"
                  }}
                  className="nav-item mx-1" >
                  <a className="nav-link  font-weight-bold" href="/wallet">
                    E-Wallet
                  </a>
                </li>
                <li
                  whileHover={{ scale: 1.5, }}
                  whileTap={{
                    scale: 0,

                    borderRadius: "100%"
                  }}
                  className="nav-item mx-1" >
                  <a className="nav-link  font-weight-bold" href="/blogs/">
                    Blogs
                  </a>
                </li> */}




        {/* <nav className="navbar navbar-main navbar-expand-lg border-bottom">
          <div className="container">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#main_nav"
              aria-controls="main_nav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="main_nav">
              

            </div>
          </div>
        </nav> */}
                <div class="modal fade" id="getQuoteModal" tabindex="-1" aria-labelledby="getQuoteModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" style={{ backgroundColor: "#4f97a1" }}>
        <h4 style={{ color: "white" }} id="getQuoteModalLabel">Get A Quote</h4>
        <button type="button" class="btn-close error-text" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <GetQuoteForm/>
      </div>
    </div>
  </div>
</div>

<button
        style={{
          bottom: '90px',
          zIndex: 1049,
          right: '30px',
          backgroundColor: '#45ACB3',
          border: '2px solid #45ACB3',
          color: 'white',
          padding: '10px 20px',
          fontSize: '16px',
          borderRadius: '5px',
          cursor: 'pointer',
          outline: 'none',
          transition: 'all 0.3s ease',
          boxShadow: '0 0 10px #45ACB3, 0 0 20px #45ACB3, 0 0 30px #45ACB3'
        }}
        className="btn quote-floating-btn position-fixed border rounded-pill" data-bs-toggle="modal" data-bs-target="#getQuoteModal">
        Get A Quote
      </button>
      </header>
    </div>
  );
}

export default Nav;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Nav from "../../../navbar";

function Buyleads() {
  const history = useNavigate();
  const [uid, setUid] = useState("");
  const [productList, setProductList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [deleteProductId, setDeleteProductId] = useState(null); // Track the product ID for deletion

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v !== null) {
      setUid(v[0].id);
      viewProduct(v[0].id);
    }
  }, []);

  const deleteproduct = async () => {
    const data = new FormData();
    data.append("uid", uid);
    data.append("id", deleteProductId);
    data.append("val", "delete");

    axios({
      method: "post",
      url: "/pro/inventory/submit_productinfo.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        viewProduct(uid);
        setDeleteProductId(null); // Clear the delete ID
        closeModal(); // Close the confirmation modal
      })
      .catch((error) => console.error("Error deleting product:", error));
  };

  const viewProduct = async (id) => {
    const data = new FormData();
    data.append("uid", id);
    data.append("val", "view");

    axios({
      method: "post",
      url: "/pro/inventory/submit_productinfo.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        const data = response.data;
        if (data[0]?.code !== "fail") {
          setProductList(data);
          localStorage.setItem("ProductListInventory", JSON.stringify(data));
        } else {
          setProductList([]);
          localStorage.setItem("ProductListInventory", JSON.stringify([]));
        }
      })
      .catch((error) => console.error("Error fetching products:", error));
  };

  const handleSearch = (data, searchText) => {
    if (!searchText) return data;
    return data.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  const openModal = (id) => {
    setDeleteProductId(id);
    const modal = new window.bootstrap.Modal(document.getElementById("deleteModal"));
    modal.show();
  };

  const closeModal = () => {
    const modal = window.bootstrap.Modal.getInstance(document.getElementById("deleteModal"));
    modal.hide();
  };

  const columns = [
    {
      name: "Sr. No",
      selector: (_, index) => index + 1,
      maxWidth: "50px",
      sortable: true,
    },
    {
      name: "Product Code",
      selector: (row) => row.p_code,
      sortable: true,
    },
    {
      name: "Product Name",
      selector: (row) => row.p_name,
      sortable: true,
    },
    {
      name: "Product Category",
      selector: (row) => row.p_category,
      sortable: true,
      hide: "md",
    },
    {
      name: "Total Units",
      selector: (row) => row.tunits,
      sortable: true,
      hide: "lg",
    },
    {
      name: "Total Amount",
      selector: (row) => row.amx,
      sortable: true,
      hide: "lg",
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button
            className="btn btn-sm btn-primary mr-1"
            onClick={() => {
              localStorage.setItem("ProductListInventoryID", row.id);
              history("/producteditin");
            }}
          >
            Edit
          </button>{" "}
          <button
            className="btn btn-sm btn-danger"
            onClick={() => openModal(row.id)}
          >
            Delete
          </button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "200px",
    },
  ];

  const ExpandableRow = ({ data }) => (
    <div style={{ padding: "10px" }}>
      <p>
        <strong>Product Sub Category:</strong> {data.p_sub_cat || "N/A"}
      </p>
      <p>
        <strong>Brand Name:</strong> {data.brand_name || "N/A"}
      </p>
      <p>
        <strong>Purchase Units:</strong> {data.purchase_units || "N/A"}
      </p>
      <p>
        <strong>Product Group:</strong> {data.product_group || "N/A"}
      </p>
      <p>
        <strong>Product Supplier:</strong> {data.p_supplier || "N/A"}
      </p>
      <p>
        <strong>Tax Code:</strong> {data.tax_code || "N/A"}
      </p>
    </div>
  );

  return (
    <div>
      <Nav />
      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <section id="main-content">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Inventory Product List</h4>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <DataTable
                      columns={columns}
                      data={handleSearch(productList, searchText)}
                      pagination
                      responsive
                      highlightOnHover
                      expandableRows
                      expandableRowsComponent={ExpandableRow}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">
                Confirm Delete
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this product?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={deleteproduct}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Buyleads;

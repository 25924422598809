import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import LoginImage from '../images/buyer-seller-signup.jpg';
import Navmain from '../main/header';
import Footer from '../main/footer';
import Error404 from "./Error404";

function Page404() {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Navmain />
      <main className="main flex-grow-1 d-flex align-items-center justify-content-center">
        <section className="bg-home9 w-100">
          <div className="container d-flex justify-content-center align-items-center">
            <div className="row w-100">
              <div className="col-12">
                <Error404 />
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Page404;

import React, { useState, useEffect } from "react";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref, set } from "firebase/database";
import { app, database } from "../firebase"; // Assuming you have set up firebase config in a separate file
import { redirectToLoginWithBackto } from "../pages/utils";

function ProductQuote({ product, user, uid, piduser, type = "product" }) {
  const navigate = useNavigate();
  const [sheet, setSheet] = useState([
    {
      id: 1,
      name: product?.name || "",
      quantity: "",
      piece: "",
      price: "",
      des: "",
      status: "normal",
    },
  ]);
  const [errors, setErrors] = useState({});
  const [mesg, setMesg] = useState("");
  const [alert2, setAlert2] = useState(false);
  const [chatid, setChatid] = useState(null); // Define the chatid state

  // Validate each field dynamically on input
  const validateField = (name, value) => {
    let errorMsg = "";

    if (name === "qty") {
      if (!value || isNaN(value) || !Number.isInteger(Number(value)) || Number(value) <= 0) {
        errorMsg = "Quantity must be a positive integer. ";
      }
    }

    if (name === "price") {
      if (!value || !/^\d+(\.\d{1,2})?$/.test(value)) {
        errorMsg = "Price must be a valid number with up to two decimal places. ";
      }
    }

    if (name === "des") {
      if (!value.trim()) {
        errorMsg = "Requirement detail is required. ";
      }
    }

    if (name === "piece") {
      if (!value) {
        errorMsg = "Please select a unit. ";
      }
    }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: errorMsg }));
  };

  // Validate the entire form before submission
  const validateForm = () => {
    let newErrors = {};

    if (!sheet[0].qty || isNaN(sheet[0].qty) || !Number.isInteger(Number(sheet[0].qty)) || Number(sheet[0].qty) <= 0) {
      newErrors.qty = "Quantity must be a positive integer. ";
    }

    if (!sheet[0].price || !/^\d+(\.\d{1,2})?$/.test(sheet[0].price)) {
      newErrors.price = "Price must be a valid number with up to two decimal places. ";
    }

    if (!sheet[0].des.trim()) {
      newErrors.des = "Requirement detail is required. ";
    }

    if (!sheet[0].piece) {
      newErrors.piece = "Please select a unit. ";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const SameUser = () => {
    if (uid === piduser) {
      alert("Please Select Another Product because this is your product. ");
      return;
    }

    if (!validateForm()) {
      return;
    }

    viewchat(uid, piduser);
  };

  const generateMsg = (product, sheet, type) => {
    const messageHTML = `
      <div style="border: 1px solid #ccc; border-radius: 10px; padding: 10px; max-width: 300px; font-family: Arial, sans-serif;">
        ${type === "product" ? `<div style="text-align: center; margin-bottom: 10px;">
                                  <img src="${product.img}" alt="Product Image" style="height: auto; width: 100%; max-width: 200px; border-radius: 8px;" />
                                </div>` : ""}
        <h3 style="margin: 0; font-size: 16px; color: #333;">${sheet[0].name}</h3>
        <p style="margin: 5px 0; font-size: 14px; color: #666;">
          <strong>Quantity:</strong> ${sheet[0].qty} ${sheet[0].piece}<br />
          <strong>Price:</strong> Rs ${sheet[0].price}<br />
          <strong>Details:</strong> ${sheet[0].des}
        </p>
      </div>
    `;
  
    return messageHTML;
  };

  const viewchat = async (u, p) => {
    const data = new FormData();
    data.append("one", u);
    data.append("two", p);

    try {
      const response = await axios.post("/mainsite/chatbox/chatbox.php", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const chatData = response.data;
      if (chatData[0].code === "empty") {
        //redirectToLoginWithBackto();
      }

      if (chatData[0].code === "pass") {
        setChatid(chatData[0].chatid);
        add_lead(chatData[0].chatid);
      }
    } catch (error) {
      console.error("Error while getting chat", error);
    }
  };

  const add_lead = async (cid) => {
    if (user.length > 0) {
      const data = new FormData();
      data.append("uid", user[0].id);
      data.append("sheet", JSON.stringify(sheet));
      data.append("cat", product?.cat || '');
      data.append("pid", product?.id || piduser);

      try {
        await axios.post("/mainsite/get_quote.php", data, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        chatinbox(cid);
      } catch (error) {
        console.error("Error while adding lead", error);
      }
    } else {
      alert("Please Login Now");
    }
  };

  const chatinbox = async (chatid) => {
    const data = new FormData();
    data.append("uid", uid);
    data.append("status", "in");
    data.append("cid", chatid);
    data.append("msg", mesg);
    data.append("type", "imageText");

    if (mesg === "") {
      alert("Message cannot be empty");
      return;
    }

    try {
      const response = await axios.post("/mainsite/chatbox/chat.php", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const chatData = response.data;
      saveToFirebaseDatabse(chatData);
      localStorage.setItem("piduser", chatid);
      navigate("/chat?cid=" + chatid);
    } catch (error) {
      console.error("Error while sending chat message", error);
    }
  };

  const saveToFirebaseDatabse = async (msg) => {
    const chatid = msg[0].cid;
    const id = msg[0].id;
    const send = msg[0].sendby;
    const mess = msg[0].mesg;
    const date = msg[0].date;
    const status = msg[0].status;
    const time = msg[0].time;
    const type = msg[0].type;
    const user_name = msg[0].user_name ?? '';

    const db = getDatabase(app);
    set(ref(db, '/chat/' + chatid + '/' + id), {
      id: id,
      chat_id: chatid,
      sent_by: send,
      message: mess,
      date: date,
      status: status,
      time: time,
      type: type,
      user_name: user_name,
    });
  };

  return (
    <section className="section-box shop-template mt-2" id="get-quote-form">
      <div className="pb-4" style={{ color: "lightgray", borderBottom: "2px solid #DDE4F0" }}>
        <h5 className="pt-3 text-left" style={{ color: "#1A78B4" }}>Get {type === "product" ? "Product" : "Service"} Quote</h5>


        <div className="form-group">
        <input className="form-control" placeholder="Name of Product or Service" type="text" disabled={type !== "business"} value={sheet[0].name}
    onChange={(e) => {
      setSheet([{ ...sheet[0], name: e.target.value }]);
    }}/>
        </div>

        <div className="form-group">
          <div className="input-group">
            <input
              className="form-control"
              placeholder="Quantity"
              type="number"
              value={sheet[0].qty}
              onChange={(e) => {
                setSheet([{ ...sheet[0], qty: e.target.value }]);
                validateField("qty", e.target.value);
              }}
            />
            <select
              value={sheet[0].piece}
              onChange={(e) => {
                setSheet([{ ...sheet[0], piece: e.target.value }]);
                validateField("piece", e.target.value);
              }}
              className="custom-select form-control"
            >
              <option value="">Select Unit</option>
              <option>Pieces</option>
              <option>Litres</option>
              <option>Tons</option>
              <option>Grams</option>
            </select>
          </div>
          {errors.qty && <small className="text-danger">{errors.qty}</small>}
          {errors.piece && <small className="text-danger">{errors.piece}</small>}
        </div>

        <div className="form-group">
          <input
            className="form-control"
            placeholder="Requirement Detail"
            type="text"
            value={sheet[0].des}
            onChange={(e) => {
              setSheet([{ ...sheet[0], des: e.target.value }]);
              setMesg(generateMsg(product, sheet, type));
              validateField("des", e.target.value);
            }}
          />
          {errors.des && <small className="text-danger">{errors.des}</small>}
        </div>

        <div className="form-group">
          <input
            className="form-control"
            placeholder="Price"
            type="text"
            value={sheet[0].price}
            onChange={(e) => {
              setSheet([{ ...sheet[0], price: e.target.value }]);
              setMesg(generateMsg(product, sheet, type));
              validateField("price", e.target.value);
            }}
          />
          {errors.price && <small className="text-danger">{errors.price}</small>}
        </div>

        <div className="text-left my-2">
          <button
            className="btn px-5 text-light border rounded-pill"
            style={{ backgroundColor: "#1A78B4" }}
            onClick={SameUser}
          >
            Get {type === "product" ? "Product" : "Service"} Quote
          </button>
        </div>

        {alert2 && (
          <SweetAlert
            success
            confirmBtnText="Thanks!"
            confirmBtnBsStyle="success"
            timeout={2000}
            title="Your Requirement Successfully Sent to the Seller"
            onConfirm={() => setAlert2(false)}
            onCancel={() => setAlert2(false)}
            focusCancelBtn
          >
            <br />
          </SweetAlert>
        )}
      </div>
    </section>
  );
}

export default ProductQuote;

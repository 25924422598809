import react from "react";
import { Link } from "react-router-dom";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
import Nav from "./navbar";
import Footer from "./footer";
import Whatsapp from "./whatsapp";
import Load from "./load"
import {Helmet} from "react-helmet";
import { useParams } from 'react-router-dom';

function Subcat() {

  const { name } = useParams();

  const [allcats, setAllcats] = useState([]);
  const [load, setLoad] = useState([]);
  
  const [title, setTitle] = useState([]);
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };
  useEffect(() => {
    const a =localStorage.getItem("title")
    
    getServercats();
  }, []);

  const getServercats = async () => {

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get("id");
    
     setTitle(params.get("s"));

    
      const data = new FormData();



      data.append("id", id);
      
  
      axios({
        method: "post",
        url: "/mainsite/suball.php",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((s) => {
          setAllcats(s.data);
        
        })
        .catch((s) => {
          // console.log("here catch =>>", s);
        });
      
    
     

  };

  const getServercatsUpdate = async (id) => {
    const data = new FormData();

    data.append("id", id);

    axios({
      method: "post",

      url: "/mainsite/suball.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setAllcats(s.data);
        // console.log(s.data);
      })
      .catch((s) => {
        // console.log("here catch", s);
      });
  };

  return (
    <div style={{backgroundColor:"#F4F5F8"}}>
      <Nav />
      <Whatsapp />
      <div className="container">
        <div className="container">
          <section className="padding-bottom-sm">
            <header className="section-heading heading-line">
              <h4 className="title-section text-uppercase">
                {localStorage.getItem("title")}
              
              </h4>
            </header>
            <div 
             
            className="row row-sm">
              {allcats.length > 1
                ? allcats.map((v, i) => (
                    <>
                      {
                        <>
                          <div   className="col-xl-2 col-lg-3 col-md-4 col-6">

                        
                          <div style={{height:"300px"}} className="card">
                          <a
                                href={"sub?id=" + v.id + "&s=" + v.title}
                                className="img-wrap"
                              >
                            <div    className="card-h-100 card-sm card-product-grid">
                                <img src={v.img} />
                              <div className="text-center info-wrap">
                                  <a style={{cursor:"pointer", color: "black"}}  className=" text-center">{v.title}</a>
                               
                              </div>
                            </div>
                            </a>
                            </div>
                          </div>
                        </>
                      }
                    </>
                  ))
                :   <div className="row row-sm">
                {allcats.length == 1
                  ? allcats[0].child.map((v, i) => (
                      <>
                        {
                          <>
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12" >
                       
                              <div >
                              <a href={"/product?id=" + v.id + "&s=" + v.title}>
                                <div style={{height: "300px"}} className="card card-sm card-product-grid">
                                  <span className="img-wrap">
                                    {/* <img src={v.img} /> */}
                                  </span>
                                  <figcaption className="info-wrap">
                                    <center>
                                      <span className="text-center">{v.title}</span>
                                    </center>
                                  </figcaption>
                                  <div className="text-center info-wrap">
                                    <Button
                                      variant="primary"
                                      className="my-2 mx-4"
                                    >
                                      Get Quote
                                    </Button>
                                  </div>
                                </div>
                              </a>
                              </div>
                            </div>
                          </>
                        }
                      </>
                    ))
                  : <Load/>}
              </div>}
            </div>       
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Subcat;


import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import { Robot } from 'react-bootstrap-icons';

function Mainpage() {
  const navigate = useNavigate();
  const [uid, setUid] = useState('');
  const [role, setRole] = useState('');
  const [directorList, setdirectorlist] = useState([]);

  const [name, setName] = useState('');
  const [designation, setdesignation] = useState('');
  const [description, setdescription] = useState('');
  const [alert2, setAlert2] = useState('');

  const [img, setImage] = useState('');
  const [img_y, setImage_y] = useState('');

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
      setRole(v[0].roll);
      viewDirector(v[0].id);
    }
  }, []);

  const viewDirector = async uid => {
    const data = new FormData();
    data.append('uid', uid);

    axios({
      method: 'post',
      url: '/pro/profile/profile_view_director.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('director list ', s.data);
        const a = s.data;
        setdirectorlist(a);
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  const CheckStatus = ()=>{
    if(role=="seller"){
    
    
    }else{
     //alert("buyer")
      updateStatus();
    }
  }
  //add data
  
  function isImageFile(file) {
    // Check if the file is defined and its type starts with "image/"
    return file && file.type.startsWith("image/");
  }
  const updateStatus = async()=>{
    const data = new FormData();
    data.append('uid', uid);
    data.append('role', "seller");
    axios({
      method: 'post',
      url: '/pro/profile/update_user_role.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    }).then(s =>{
      
      if (s.data[0].msg === 'pass') {
        localStorage.setItem('USER',JSON.stringify(s.data))
        
        navigate('/director');
      }
    }).catch(error =>{
      console.log("error occured",error.message);
    })
  }

  
  const addDirector = async () => {
    const data = new FormData();
    data.append('uid', uid);
    data.append('name', name);
    data.append('designation', designation);
    data.append('description', description);
    data.append('img', img);
    axios({
      method: 'post',
      url: '/pro/profile/profile_director.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        if (s.data[0].code === 'inserted') {
          
          setAlert2(!alert2);
          
          
        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  //.......data

  const text_type=(input)=>{
    // Regular expression to check if the string contains only alphabetic characters
  const regex = /^[A-Za-z\s]+$/;

  // Test the input against the regular expression
  return regex.test(input);

  }

  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <div className="card">
              <div className="card-title">
                <h4>New Director</h4>
              </div>

              <div className="row">
                {' '}
                <div className="col-6 form-group">
                  <label>Name</label>
                  <input
                    className="form-control"
                    type="text"
                    value={name}
                    maxLength={20}
                    onChange={v => {
                     
                      
                      if( text_type(v.target.value))
                        {
                          setName(v.target.value);
                        }else if(v.target.value=="")
                        {
                          setName(v.target.value);
                        }
                      
                    }}
                  />
                </div>
                <div className="col-6 form-group">
                  <label>Designation</label>
                  <input
                    className="form-control"
                    type="text"
                    value={designation}
                  maxLength={15}
                    onChange={v => {
                      
                      if( text_type(v.target.value))
                        {
                          setdesignation(v.target.value);
                        }else if(v.target.value=="")
                        {
                          setdesignation(v.target.value);
                        }
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6 form-group">
                  <label>Description</label>
                  <textarea
                  maxLength={100}
                    value={description}
                    onChange={v => {
                      
                      if( text_type(v.target.value))
                        {
                          setdescription(v.target.value);
                        }else if(v.target.value=="")
                        {
                          setdescription(v.target.value);
                        }
                    }}
                    className="form-control"
                    rows={4}></textarea>
                </div>

                <div className="col-6 form-group">
                  <label>Image</label>
                  <input
                    className="form-control"
                    onChange={v => {
                      setImage(v.target.files[0]);
                      if(v.target.files[0]=="")
                      {
                        setImage_y("")
                      }else{
                        setImage(v.target.files[0]);
                        setImage_y("true")

                      }
                      
                    }}
                    type="file"
                  />
                </div>
              </div>

              <div
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
                className="form-group">
                <button
                  className=" btn btn-primary px-5 mt-4"
                  onClick={() => {
                    if(name.length<2 || name.length > 50)
                                    {
                                      alert("Enter Proper Name. Name Length must be more than 3 and less than 50 characters")
                                    }else if(designation.length<2 || designation.length > 50){
                                      alert("Enter Proper Designation Name. Designation Length must be more than 3 and less than 50 characters")
                                    } else if(description.length<6){
                                      alert("Please enter a valid description that is longer than six characters.")
                                    } 
                                    else if(!isImageFile(img)){

                                      alert("Image not selected..")
                                      
                                    }
                                    else{
                                      addDirector();
                                    }
                    //
                   // setAlert(!alert);
                  }}>
                  Add Now
                </button>
                {alert2 ? (
                  <>
                    {' '}
                    <SweetAlert
                      success
                      confirmBtnText="Thanks !"
                      confirmBtnBsStyle="success"
                      timeout={2000}
                      title="Director Saved"
                      onConfirm={() => {
                        setAlert2(!alert2);
                        CheckStatus();
                        //navigate('/director');
                      }}
                      onCancel={() => {
                        setAlert2(!alert2);
                      }}
                      focusCancelBtn>
                      <br />
                    </SweetAlert>
                  </>
                ) : (
                  <></>
                )}
                {/* {alert2 ? (
                  <>
                    {' '}
                    <SweetAlert
                      warning
                      confirmBtnText="Validate !"
                      confirmBtnBsStyle="success"
                      timeout={5000}
                      title={title}
                      onConfirm={() => {
                        setAlert2(!alert);
                        //navigate('/director');
                      }}
                      onCancel={() => {
                        setAlert2(!alert);
                      }}
                      focusCancelBtn>
                      <br />
                    </SweetAlert>
                  </>
                ) : (
                  <></>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;

import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { SendWelcome, generateToken } from "./utils";
import '../styles/auth.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginImage from '../images/buyer-seller-otp.jpeg';
import Navmain from '../main/header';
import Footer from '../main/footer';

function Login() {
  const navigate = useNavigate();
  const [error, seterror] = useState("");
  const [name, setName] = useState("");
  const [token, setToken] = useState('');
  const [uid, setUid] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [img, setImg] = useState("");
  const [otp, setOtp] = useState("");

  const focusForm = () => {
    setTimeout(() => {
      const firstEmptyInput = [...Array(6)].findIndex((_, i) => !otp[i]);
      if (firstEmptyInput !== -1) {
        document.getElementById(`form${firstEmptyInput}`)?.focus();
      }
    }, 100);
  }

  useEffect(() => {
    const fetchToken = () => {
      generateToken()
        .then((currentToken) => {
          console.log('currentToken token available......' + currentToken);
          if (currentToken) {
            setToken(currentToken);
          } else {
            console.log('No registration token available.');
          }
        });
    };
    fetchToken();
    focusForm();
  }, []);

  const loginNow = (phone, pass) => {
    setIsLoading(true);
    const data = new FormData();
    data.append("email", phone);
    data.append("pass", pass);
    data.append("status", "login");
    data.append("did", "web");
    data.append("dtype", "web");
    data.append("token", token);

    axios({
      method: "post",
      url: "/pro/auth/login.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        setIsLoading(false);
        if (response.data[0].msg === "pass") {
          alert("Thank you! Otp Verified. Login Successful! Redirecting...");
          localStorage.setItem("USER", JSON.stringify(response.data));
          setUid(response.data[0].user);
          SendWelcome(response.data[0].user, navigate);
          navigate("/");
        } else {
          alert("Login failed! Please try again.");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Login Error:", error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (otp.length !== 6) {
      alert("OTP must be exactly 6 digits.");
      focusForm();
      // navigate("/login");
      return;
    }

    const data = new FormData();
    data.append("otp", otp);
    data.append("status", "2");
    setIsLoading(true); // Start loading
    axios({
      method: "post",
      url: "/pro/auth/verify_user.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setIsLoading(false);
        if (s.data[0].code == "pass") {
          const signupDetails = JSON.parse(localStorage.getItem("signupDetails"));
          if (signupDetails) {
            const { phone, pass } = signupDetails;
            loginNow(phone, pass);
          } else {
            alert("No signup details found. Please enter your login details manually.");
            navigate("/login");
          }
        } else if (s.data[0].code == "expire") {
          alert("Your OTP has expired. Please request a new one.");
          focusForm();
          seterror("name");
        } else {
          alert("Incorrect OTP. Please try again.");
          focusForm();
          navigate("/login");
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleInputChange = (e, index) => {
    const newValue = e.target.value;
    let newOtp = otp;
    newOtp = newOtp.substring(0, index) + newValue + newOtp.substring(index + 1);
    if (newValue && index !== 5) {
      document.getElementById(`form${index + 1}`).focus();
    }
    setOtp(newOtp);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      document.getElementById(`form${index - 1}`).focus();
    }
  };

  return (
<div>
    <Navmain />
    <main className="main">
      <section className="bg-home9 no-gutters">
      <div className="container d-flex justify-content-center align-items-center">
      <div className="row py-5 w-100">
      <div className="col-6 d-none d-lg-block p-2">
    <div className="p-4 border-0 rounded login-content"
      style={{
        width: '100%',
        height: '100%',
        overflow: 'hidden' // Prevents any overflow outside the container
      }}
    >
      <img 
        className="form-image"
        src={LoginImage} 
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover' // Ensures the image covers the entire div without distortion
        }}
      />
    </div>
</div>
        <div className="col-md-6 col-12">
          <div className="p-4  border-0 rounded login-content">
                <div style={{ opacity: 0.9 }} className="login-form">
                    <div className="mt-5">
                      <div className="form-group text-center py-5">
                        <h1 className="fs-4 mb-5">OTP Verification</h1>
                        <div className="d-flex justify-content-between px-2">
                          {[...Array(6)].map((_, i) => (
                            <input
                              key={i}
                              className="otp-items form-control text-center border border-dark"
                              maxLength="1"
                              style={{ width: '155px' }}
                              type="number"
                              value={otp[i] || ''}
                              onChange={(e) => handleInputChange(e, i)}
                              onKeyDown={(e) => handleKeyDown(e, i)}
                              id={`form${i}`}
                            />
                          ))}
                        </div>
                        <button
                          className="otp-btn"
                          onClick={handleSubmit}
                          type="submit"
                        >
                          Verify OTP
                        </button>
                      </div>
                    </div>
                  </div>
              </div >
            </div>
          </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Login;

import React, { useState, useEffect } from "react";
import axios from "axios";

const GetQuoteForm = () => {

    const [sheet, setSheet] = useState(
        [{
          id: 1,
          name: "",
          qty: "",
          quantity: "",
          piece: "Pieces",
          price: "",
          des: "",
          status: "normal",
          cat: ""
        }
        ]);
        const [uid, setUid] = useState();
        const [alert1, setAlert1] = useState();
        const [searchTerm, setSearchTerm] = useState("");

        const [error, seterror] = useState(false);
        const [cat, setCat] = useState("");
        const [main, setMainCat] = useState([]);


        const getMainCat = async () => {
            const data = new FormData();
            data.append('uid', 'ss');
        
            axios({
              method: 'post',
              url: '/pro/cat/maincat.php',
              data: data,
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(s => {
                console.log('Main cat list ', s.data);
                const a = s.data;
                setMainCat(a);
                console.log("maincat news", s.data)
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };

        const add_lead = async (sheet, uid, c) => {

            const data = new FormData();
        
            data.append("uid", uid);
            data.append("sheet", JSON.stringify(sheet));
            data.append("cat", c);
            data.append("img", "");
        
            axios({
              method: "post",
              url: "/pro/lead_add.php",
              data: data,
              headers: { "Content-Type": "multipart/form-data" },
            })
              .then((s) => {
        
                setAlert1(!alert1);
                
                // Navigate to the new page and force a reload
                window.location.href = "/product-buy-request";
              })
              .catch((s) => {
                // console.log("here catch =>>", s);
              });
        
          };

          const changeArayquote = (i, changes) => {
            const oldArr = [...sheet];
            const oldObj = oldArr[i];
            oldArr[i] = { ...oldObj, ...changes };
            setSheet(oldArr);
          
            // Re-evaluate validation only for the changed field
            const validationErrors = { ...error }; // Preserve existing errors
          
            Object.keys(changes).forEach((key) => {
              if (key === "name" && !changes.name) {
                validationErrors.name = "Error: Name of Product is required.";
              } else if (key === "name") {
                delete validationErrors.name;
              }
          
              if (key === "qty" && (!changes.qty || Number(changes.qty) < 1)) {
                validationErrors.qty = "Error: Quantity must be greater than 0.";
              } else if (key === "qty") {
                delete validationErrors.qty;
              }
          
              if (key === "piece" && !changes.piece) {
                validationErrors.piece = "Error: Please select a valid unit.";
              } else if (key === "piece") {
                delete validationErrors.piece;
              }
        
              if (key === "category" && (!changes.category || changes.category == 'Select Main Category')) {
                validationErrors.category = "Error: Category is required.";
              } else if (key === "category") {
                delete validationErrors.category;
              }
          
              if (key === "price" && (!changes.price || Number(changes.price) < 1)) {
                validationErrors.price = "Error: Approx Price must be greater than 0.";
              } else if (key === "price") {
                delete validationErrors.price;
              }
          
              if (key === "des" && !changes.des) {
                validationErrors.des = "Error: Requirement Description is required.";
              } else if (key === "des") {
                delete validationErrors.des;
              }
            });
          
            seterror(validationErrors);
          };

            useEffect(() => {
              getMainCat()
              // const aa = localStorage.getItem("USER");
              const aa = localStorage.getItem("USER");
              if (aa != null) {
                const as = JSON.parse(aa);
                setUid(as[0].id);
              }
            }, []);

            const filteredCategories = main.filter((category) =>
                category.title.toLowerCase().includes(searchTerm.toLowerCase())
              );

  return (
    <div className="mb-0 d-flex flex-column w-100 flex-grow-1">
      <h4 className="color-gray-900 text-center mb-2 mt-3 fs-3">Get Quote</h4>
      <div className="mt-2 px-3 d-flex flex-column flex-grow-1">
        <input
          style={{ borderColor: error?.name ? "red" : "transparent", color: "black" }}
          className={`form-control border rounded mb-2 ${error?.name ? "placeholder-red" : ""}`}
          placeholder={error?.name || "Name of Product"}
          type="text"
          value={sheet[0].name}
          onChange={(e) => changeArayquote(0, { name: e.target.value })}
        />

        <input
          type="number"
          style={{ borderColor: error?.qty ? "red" : "transparent", color: "black" }}
          className={`form-control border rounded mb-2 ${error?.qty ? "placeholder-red" : ""}`}
          placeholder={error?.qty || "Quantity"}
          value={sheet[0].qty}
          onChange={(e) => changeArayquote(0, { qty: e.target.value, quantity: e.target.value })}
        />

        <input
          type="number"
          style={{ borderColor: error?.price ? "red" : "transparent", color: "black" }}
          className={`form-control border rounded mb-2 ${error?.price ? "placeholder-red" : ""}`}
          placeholder={error?.price || "Approx Price Rs"}
          value={sheet[0].price}
          onChange={(e) => changeArayquote(0, { price: e.target.value })}
        />

        <select
          style={{ borderColor: error?.piece ? "red" : "transparent" }}
          value={sheet[0].piece}
          onChange={(e) => changeArayquote(0, { piece: e.target.value })}
          className="py-2 px-2 border rounded"
          aria-label="Select option"
        >
          <option>Units</option>
          <option>Pieces</option>
          <option>Grams</option>
          <option>Kilograms</option>
          <option>Tons</option>
          <option>Litres</option>
        </select>

        
        <div className="mt-2">
            <div className="dropdown">
                <button
                className="btn btn-secondary dropdown-toggle form-control border"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{
                    ...(error?.category && { borderColor: "#FA8072", color: "#FA8072" }), // Only apply styles when there's an error
                }}
                >
                {error?.category ? error?.category : cat ? main.find((v) => v.id === cat)?.title || "Select Main Category" : "Select Main Category"}
                </button>
                <ul
                className="dropdown-menu form-control border"
                aria-labelledby="dropdownMenuButton"
                style={{
                    maxHeight: filteredCategories.length > 0 ? `${Math.min(filteredCategories.length * 50 + 50, 300)}px` : "50px",
                    overflowY: "auto",
                    height: "auto",
                    width: "100%"
                }}
                >
                <li>
                    <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Search Category"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </li>
                {filteredCategories.map((v) => (
                    <li key={v.id}>
                    <button
                        className="dropdown-item"
                        onClick={() => {
                        setCat(v.id);
                        changeArayquote(0, { category: v.id });
                        }}
                    >
                        {v.title}
                    </button>
                    </li>
                ))}
                </ul>
            </div>
        </div>


        <textarea
          className={`form-control border rounded mt-2 flex-grow-1 ${error?.des ? "placeholder-red" : ""}`}
          style={{ borderColor: error?.des ? "red" : "transparent", color: "black" }}
          placeholder={error?.des || "Requirement Description"}
          maxLength={100}
          value={sheet[0].des}
          onChange={(e) => changeArayquote(0, { des: e.target.value })}
        ></textarea>
      </div>

      <div className="row">
        <div className="form-group text-center">
          <button
            onClick={() => {
              const validationErrors = {};

              if (!sheet[0].name) validationErrors.name = "Error: Name of Product is required.";
              if (!sheet[0].qty || Number(sheet[0].qty) < 1) validationErrors.qty = "Error: Quantity must be greater than 0.";
              if (!sheet[0].piece) validationErrors.piece = "Error: Please select a valid unit.";
              if (!cat || cat == "Select Main Category") validationErrors.category = "Error: Category is required.";
              if (!sheet[0].price || Number(sheet[0].price) < 1) validationErrors.price = "Error: Approx Price must be greater than 0.";
              if (!sheet[0].des) validationErrors.des = "Error: Requirement Description is required.";

              if (Object.keys(validationErrors).length > 0) {
                seterror(validationErrors);
              } else {
                seterror({});
                add_lead(sheet, uid, cat);
              }
            }}
            className="btn"
            style={{ backgroundColor: "#45ACB3", color: "white", marginTop: "20px", boxShadow: "3px 3px 10px 6px #ccc", border: "1px solid #45ACB3 !important" }}
          >
            Get Quote
          </button>
        </div>
      </div>
    </div>
  );
};

export default GetQuoteForm;

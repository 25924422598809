import React from 'react'
import axios from "axios";
import { useState, useEffect } from "react";
import '../styles/footer.css';
import AppStore from '../images/appstore.jpg';
import PlayStore from '../images/playstore.jpg';

function Footer() {
  const [metaData, setMetaData] = useState([]);


  const get_meta = async () => {
    console.log("meta_url", window.location.href)
    const data = new FormData();
    data.append("url","https://alahdeen.com"+window.location.pathname); // Directly use current URL

    try {
      const response = await axios.post("/pro/seo/get_meta.php", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setMetaData(response.data); // Set the fetched metadata
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching metadata:", error);
    }
  };
  useEffect(() => {
    get_meta(); // Fetch metadata on component mount
  }, []);
  return (
<>
  {/* {metaData.length>0 && metaData[0].code && (
    <div className="topbar mt-25 mb-25 p-0" style={{height:"10px"}}>
      <div className="container-topbar">
        <div className="d-none d-xl-block"></div>
      </div>
    </div>
  )} */}

  <div className='container'>
    {metaData.length > 0 && (
      <div dangerouslySetInnerHTML={{ __html: metaData[0].code }} />
    )}
  </div>


    {/* <div className="topbar mt-25 p-0" style={{height:"10px"}}>
      <div className="container-topbar">
        <div className="d-none d-xl-block"></div>
      </div>
    </div> */}
  
<>
    <section className="footer-container container-fluid">
      <div className='row d-flex justify-content-start p-5'>
        <div className='col-12 col-sm-6 col-lg-3'>
          <span className='fs-5 font-bold d-inline-block mb-4 footer-heading'>Contact</span>
          <p className='footer-text'><span className="font-bold">Address:</span> <span> 502 DHA PHASE 8, Karachi, Pakistan</span></p>
          <p className='footer-text'><span className='font-bold'>Phone:</span> <span> (+42) 123-456-789</span></p>
          <p className='footer-text'><span className='font-bold'>E-mail:</span> <span> contact@ecocm-market.com</span></p>

          <div className='d-flex mt-2' style={{ gap: '.4rem' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
              <g clip-path="url(#clip0_2_3177)">
                <path opacity="0.8" d="M32 16.9502C32 8.1127 24.8375 0.950195 16 0.950195C7.1625 0.950195 0 8.1127 0 16.9502C0 25.7877 7.1625 32.9502 16 32.9502C16.0938 32.9502 16.1875 32.9502 16.2812 32.9439V20.494H12.8438V16.4877H16.2812V13.5377C16.2812 10.1189 18.3688 8.25645 21.4188 8.25645C22.8813 8.25645 24.1375 8.3627 24.5 8.4127V11.9877H22.4C20.7437 11.9877 20.4188 12.7752 20.4188 13.9314V16.4814H24.3875L23.8687 20.4877H20.4188V32.3315C27.1063 30.4127 32 24.2565 32 16.9502Z" fill="#8C9EC5" />
              </g>
              <defs>
                <clipPath id="clip0_2_3177">
                  <rect width="32" height="32" fill="white" transform="translate(0 0.950195)" />
                </clipPath>
              </defs>
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
              <g clip-path="url(#clip0_2_3181)">
                <path opacity="0.8" d="M19.0625 16.9502C19.0625 18.6416 17.6914 20.0127 16 20.0127C14.3086 20.0127 12.9375 18.6416 12.9375 16.9502C12.9375 15.2588 14.3086 13.8877 16 13.8877C17.6914 13.8877 19.0625 15.2588 19.0625 16.9502Z" fill="#8C9EC5" />
                <path opacity="0.8" d="M23.1621 11.5315C23.0149 11.1326 22.78 10.7715 22.4749 10.4751C22.1785 10.1699 21.8176 9.93506 21.4185 9.78785C21.0947 9.66211 20.6084 9.51246 19.7126 9.47168C18.7437 9.42749 18.4531 9.41797 16 9.41797C13.5466 9.41797 13.2561 9.42725 12.2874 9.47144C11.3916 9.51246 10.905 9.66211 10.5815 9.78785C10.1824 9.93506 9.82129 10.1699 9.52515 10.4751C9.21997 10.7715 8.98511 11.1323 8.83765 11.5315C8.71191 11.8552 8.56226 12.3418 8.52148 13.2376C8.47729 14.2063 8.46777 14.4968 8.46777 16.9502C8.46777 19.4033 8.47729 19.6939 8.52148 20.6628C8.56226 21.5586 8.71191 22.0449 8.83765 22.3687C8.98511 22.7678 9.21973 23.1287 9.5249 23.4251C9.82129 23.7302 10.1821 23.9651 10.5813 24.1123C10.905 24.2383 11.3916 24.3879 12.2874 24.4287C13.2561 24.4729 13.5464 24.4822 15.9998 24.4822C18.4534 24.4822 18.7439 24.4729 19.7124 24.4287C20.6082 24.3879 21.0947 24.2383 21.4185 24.1123C22.2197 23.8032 22.853 23.1699 23.1621 22.3687C23.2878 22.0449 23.4375 21.5586 23.4785 20.6628C23.5227 19.6939 23.532 19.4033 23.532 16.9502C23.532 14.4968 23.5227 14.2063 23.4785 13.2376C23.4377 12.3418 23.2881 11.8552 23.1621 11.5315ZM16 21.6677C13.3943 21.6677 11.282 19.5557 11.282 16.95C11.282 14.3442 13.3943 12.2322 16 12.2322C18.6055 12.2322 20.7178 14.3442 20.7178 16.95C20.7178 19.5557 18.6055 21.6677 16 21.6677ZM20.9043 13.1482C20.2954 13.1482 19.8018 12.6545 19.8018 12.0457C19.8018 11.4368 20.2954 10.9431 20.9043 10.9431C21.5132 10.9431 22.0068 11.4368 22.0068 12.0457C22.0066 12.6545 21.5132 13.1482 20.9043 13.1482Z" fill="#8C9EC5" />
                <path opacity="0.8" d="M16 0.950195C7.16479 0.950195 0 8.11499 0 16.9502C0 25.7854 7.16479 32.9502 16 32.9502C24.8352 32.9502 32 25.7854 32 16.9502C32 8.11499 24.8352 0.950195 16 0.950195ZM25.1321 20.738C25.0876 21.7161 24.9321 22.3838 24.7051 22.9683C24.2278 24.2024 23.2522 25.178 22.0181 25.6553C21.4338 25.8823 20.7659 26.0376 19.7881 26.0823C18.8083 26.127 18.4954 26.1377 16.0002 26.1377C13.5049 26.1377 13.1921 26.127 12.2122 26.0823C11.2344 26.0376 10.5664 25.8823 9.98218 25.6553C9.3689 25.4246 8.81372 25.063 8.35474 24.5955C7.88745 24.1367 7.52588 23.5813 7.29517 22.9683C7.06812 22.384 6.9126 21.7161 6.86816 20.7383C6.823 19.7583 6.8125 19.4453 6.8125 16.9502C6.8125 14.4551 6.823 14.1421 6.86792 13.1624C6.91235 12.1843 7.06763 11.5166 7.29468 10.9321C7.52539 10.3191 7.88721 9.76368 8.35474 9.30494C8.81348 8.83741 9.3689 8.47583 9.98193 8.24512C10.5664 8.01807 11.2341 7.8628 12.2122 7.81812C13.1919 7.77344 13.5049 7.7627 16 7.7627C18.4951 7.7627 18.8081 7.77344 19.7878 7.81836C20.7659 7.86279 21.4336 8.01807 22.0181 8.24488C22.6311 8.47559 23.1865 8.83741 23.6455 9.30494C24.1128 9.76392 24.4746 10.3191 24.7051 10.9321C24.9324 11.5166 25.0876 12.1843 25.1323 13.1624C25.177 14.1421 25.1875 14.4551 25.1875 16.9502C25.1875 19.4453 25.177 19.7583 25.1321 20.738Z" fill="#8C9EC5" />
              </g>
              <defs>
                <clipPath id="clip0_2_3181">
                  <rect width="32" height="32" fill="white" transform="translate(0 0.950195)" />
                </clipPath>
              </defs>
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
              <g clip-path="url(#clip0_2_3187)">
                <path opacity="0.8" d="M16 0.950195C7.16479 0.950195 0 8.11499 0 16.9502C0 25.7854 7.16479 32.9502 16 32.9502C24.8352 32.9502 32 25.7854 32 16.9502C32 8.11499 24.8352 0.950195 16 0.950195ZM23.3054 13.4253C23.3125 13.5828 23.3159 13.741 23.3159 13.8999C23.3159 18.7527 19.6221 24.3486 12.8669 24.3489C10.793 24.3489 8.86304 23.741 7.23779 22.6992C7.52515 22.7332 7.81763 22.75 8.11377 22.75C9.83447 22.75 11.418 22.1631 12.675 21.178C11.0674 21.1482 9.71191 20.0864 9.24414 18.6272C9.46802 18.6702 9.69824 18.6936 9.93433 18.6936C10.2695 18.6936 10.5942 18.6484 10.9028 18.5642C9.22241 18.2278 7.95654 16.7427 7.95654 14.9644C7.95654 14.9478 7.95654 14.9329 7.95703 14.9175C8.4519 15.1926 9.01782 15.3582 9.62036 15.3767C8.63428 14.7188 7.98608 13.594 7.98608 12.3198C7.98608 11.647 8.16797 11.0166 8.4834 10.4739C10.2944 12.696 13.001 14.1575 16.0532 14.311C15.9902 14.042 15.9578 13.7617 15.9578 13.4736C15.9578 11.4463 17.6025 9.80152 19.6306 9.80152C20.687 9.80152 21.6411 10.248 22.3113 10.9619C23.1479 10.7969 23.9336 10.4912 24.6433 10.0706C24.3687 10.9277 23.7866 11.647 23.0283 12.1018C23.7712 12.0129 24.4792 11.8159 25.1372 11.5234C24.6458 12.26 24.0225 12.907 23.3054 13.4253Z" fill="#8C9EC5" />
              </g>
              <defs>
                <clipPath id="clip0_2_3187">
                  <rect width="32" height="32" fill="white" transform="translate(0 0.950195)" />
                </clipPath>
              </defs>
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
              <g clip-path="url(#clip0_2_3191)">
                <path opacity="0.8" d="M16 0.950195C7.16479 0.950195 0 8.11499 0 16.9502C0 25.7854 7.16479 32.9502 16 32.9502C24.8352 32.9502 32 25.7854 32 16.9502C32 8.11499 24.8352 0.950195 16 0.950195ZM11.3506 25.1377H7.45386V13.4143H11.3506V25.1377ZM9.40234 11.8135H9.37695C8.06934 11.8135 7.22363 10.9133 7.22363 9.78833C7.22363 8.63794 8.09521 7.7627 9.42822 7.7627C10.7612 7.7627 11.5815 8.63794 11.6069 9.78833C11.6069 10.9133 10.7612 11.8135 9.40234 11.8135ZM25.4014 25.1377H21.5051V18.866C21.5051 17.2898 20.9409 16.2148 19.531 16.2148C18.4546 16.2148 17.8135 16.9399 17.5317 17.6399C17.4287 17.8904 17.4036 18.2405 17.4036 18.5908V25.1377H13.5071C13.5071 25.1377 13.5581 14.5142 13.5071 13.4143H17.4036V15.0742C17.9214 14.2754 18.8479 13.1392 20.9153 13.1392C23.479 13.1392 25.4014 14.8147 25.4014 18.4155V25.1377Z" fill="#8C9EC5" />
              </g>
              <defs>
                <clipPath id="clip0_2_3191">
                  <rect width="32" height="32" fill="white" transform="translate(0 0.950195)" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-lg-3'>
          <span className='fs-5 font-bold d-inline-block mb-4 footer-heading'>Make Money with Us</span>
          <p className='footer-text'> <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
            <g clip-path="url(#clip0_2_3210)">
              <g opacity="0.6">
                <path d="M0.452148 2.00494L0.452149 6.15828V9.8983C0.452149 10.5383 1.22548 10.8583 1.67882 10.405L5.13215 6.95161C5.68549 6.39828 5.68549 5.49828 5.13215 4.94495L3.81882 3.63161L1.67882 1.49161C1.22548 1.04494 0.452148 1.36495 0.452148 2.00494Z" fill="#425A8B" />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_2_3210">
                <rect width="6" height="10" fill="white" transform="translate(0 0.950195)" />
              </clipPath>
            </defs>
          </svg> <span className='ml-2'> Our Team </span></p>
          <p className='footer-text'>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
              <g clip-path="url(#clip0_2_3210)">
                <g opacity="0.6">
                  <path d="M0.452148 2.00494L0.452149 6.15828V9.8983C0.452149 10.5383 1.22548 10.8583 1.67882 10.405L5.13215 6.95161C5.68549 6.39828 5.68549 5.49828 5.13215 4.94495L3.81882 3.63161L1.67882 1.49161C1.22548 1.04494 0.452148 1.36495 0.452148 2.00494Z" fill="#425A8B" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2_3210">
                  <rect width="6" height="10" fill="white" transform="translate(0 0.950195)" />
                </clipPath>
              </defs>
            </svg>
            <span className='ml-2'> Mission & Vision </span></p>
          <p className='footer-text'>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
              <g clip-path="url(#clip0_2_3210)">
                <g opacity="0.6">
                  <path d="M0.452148 2.00494L0.452149 6.15828V9.8983C0.452149 10.5383 1.22548 10.8583 1.67882 10.405L5.13215 6.95161C5.68549 6.39828 5.68549 5.49828 5.13215 4.94495L3.81882 3.63161L1.67882 1.49161C1.22548 1.04494 0.452148 1.36495 0.452148 2.00494Z" fill="#425A8B" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2_3210">
                  <rect width="6" height="10" fill="white" transform="translate(0 0.950195)" />
                </clipPath>
              </defs>
            </svg>
            <span className='ml-2'> Careers </span></p>
          {/* <p className='footer-text'>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
              <g clip-path="url(#clip0_2_3210)">
                <g opacity="0.6">
                  <path d="M0.452148 2.00494L0.452149 6.15828V9.8983C0.452149 10.5383 1.22548 10.8583 1.67882 10.405L5.13215 6.95161C5.68549 6.39828 5.68549 5.49828 5.13215 4.94495L3.81882 3.63161L1.67882 1.49161C1.22548 1.04494 0.452148 1.36495 0.452148 2.00494Z" fill="#425A8B" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2_3210">
                  <rect width="6" height="10" fill="white" transform="translate(0 0.950195)" />
                </clipPath>
              </defs>
            </svg>
            <span className='ml-2'> Press & Media </span></p>
          <p className='footer-text'>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
              <g clip-path="url(#clip0_2_3210)">
                <g opacity="0.6">
                  <path d="M0.452148 2.00494L0.452149 6.15828V9.8983C0.452149 10.5383 1.22548 10.8583 1.67882 10.405L5.13215 6.95161C5.68549 6.39828 5.68549 5.49828 5.13215 4.94495L3.81882 3.63161L1.67882 1.49161C1.22548 1.04494 0.452148 1.36495 0.452148 2.00494Z" fill="#425A8B" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2_3210">
                  <rect width="6" height="10" fill="white" transform="translate(0 0.950195)" />
                </clipPath>
              </defs>
            </svg>
            <span className='ml-2'> Advertising </span></p> 
          <p className='footer-text'>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
              <g clip-path="url(#clip0_2_3210)">
                <g opacity="0.6">
                  <path d="M0.452148 2.00494L0.452149 6.15828V9.8983C0.452149 10.5383 1.22548 10.8583 1.67882 10.405L5.13215 6.95161C5.68549 6.39828 5.68549 5.49828 5.13215 4.94495L3.81882 3.63161L1.67882 1.49161C1.22548 1.04494 0.452148 1.36495 0.452148 2.00494Z" fill="#425A8B" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2_3210">
                  <rect width="6" height="10" fill="white" transform="translate(0 0.950195)" />
                </clipPath>
              </defs>
            </svg>
            <span className='ml-2'> Testimonials </span></p>*/}

        </div>
        <div className='col-12 col-sm-6 col-lg-3'>
          <span className='fs-5 font-bold d-inline-block mb-4 footer-heading'>Company</span>

          <p className='footer-text'>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
              <g clip-path="url(#clip0_2_3210)">
                <g opacity="0.6">
                  <path d="M0.452148 2.00494L0.452149 6.15828V9.8983C0.452149 10.5383 1.22548 10.8583 1.67882 10.405L5.13215 6.95161C5.68549 6.39828 5.68549 5.49828 5.13215 4.94495L3.81882 3.63161L1.67882 1.49161C1.22548 1.04494 0.452148 1.36495 0.452148 2.00494Z" fill="#425A8B" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2_3210">
                  <rect width="6" height="10" fill="white" transform="translate(0 0.950195)" />
                </clipPath>
              </defs>
            </svg>
            <span className='ml-2'> Our Blog </span></p>
          <p className='footer-text'>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
              <g clip-path="url(#clip0_2_3210)">
                <g opacity="0.6">
                  <path d="M0.452148 2.00494L0.452149 6.15828V9.8983C0.452149 10.5383 1.22548 10.8583 1.67882 10.405L5.13215 6.95161C5.68549 6.39828 5.68549 5.49828 5.13215 4.94495L3.81882 3.63161L1.67882 1.49161C1.22548 1.04494 0.452148 1.36495 0.452148 2.00494Z" fill="#425A8B" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2_3210">
                  <rect width="6" height="10" fill="white" transform="translate(0 0.950195)" />
                </clipPath>
              </defs>
            </svg>
            <span className='ml-2'> Plans & Pricing </span></p>
          {/* <p className='footer-text'>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
              <g clip-path="url(#clip0_2_3210)">
                <g opacity="0.6">
                  <path d="M0.452148 2.00494L0.452149 6.15828V9.8983C0.452149 10.5383 1.22548 10.8583 1.67882 10.405L5.13215 6.95161C5.68549 6.39828 5.68549 5.49828 5.13215 4.94495L3.81882 3.63161L1.67882 1.49161C1.22548 1.04494 0.452148 1.36495 0.452148 2.00494Z" fill="#425A8B" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2_3210">
                  <rect width="6" height="10" fill="white" transform="translate(0 0.950195)" />
                </clipPath>
              </defs>
            </svg>
            <span className='ml-2'> Knowledge Base </span></p>
          <p className='footer-text'>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
              <g clip-path="url(#clip0_2_3210)">
                <g opacity="0.6">
                  <path d="M0.452148 2.00494L0.452149 6.15828V9.8983C0.452149 10.5383 1.22548 10.8583 1.67882 10.405L5.13215 6.95161C5.68549 6.39828 5.68549 5.49828 5.13215 4.94495L3.81882 3.63161L1.67882 1.49161C1.22548 1.04494 0.452148 1.36495 0.452148 2.00494Z" fill="#425A8B" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2_3210">
                  <rect width="6" height="10" fill="white" transform="translate(0 0.950195)" />
                </clipPath>
              </defs>
            </svg>
            <span className='ml-2'> Cookie Policy </span></p>
          <p className='footer-text'>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
              <g clip-path="url(#clip0_2_3210)">
                <g opacity="0.6">
                  <path d="M0.452148 2.00494L0.452149 6.15828V9.8983C0.452149 10.5383 1.22548 10.8583 1.67882 10.405L5.13215 6.95161C5.68549 6.39828 5.68549 5.49828 5.13215 4.94495L3.81882 3.63161L1.67882 1.49161C1.22548 1.04494 0.452148 1.36495 0.452148 2.00494Z" fill="#425A8B" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2_3210">
                  <rect width="6" height="10" fill="white" transform="translate(0 0.950195)" />
                </clipPath>
              </defs>
            </svg>
            <span className='ml-2'> Office Center </span></p> */}
          <p className='footer-text'>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
              <g clip-path="url(#clip0_2_3210)">
                <g opacity="0.6">
                  <path d="M0.452148 2.00494L0.452149 6.15828V9.8983C0.452149 10.5383 1.22548 10.8583 1.67882 10.405L5.13215 6.95161C5.68549 6.39828 5.68549 5.49828 5.13215 4.94495L3.81882 3.63161L1.67882 1.49161C1.22548 1.04494 0.452148 1.36495 0.452148 2.00494Z" fill="#425A8B" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2_3210">
                  <rect width="6" height="10" fill="white" transform="translate(0 0.950195)" />
                </clipPath>
              </defs>
            </svg>
            <span className='ml-2'> News & Events </span></p>
        </div>
        {/* <div className='col-12 col-sm-6 col-lg-3'>
          <span className='fs-5 font-bold d-inline-block mb-4 footer-heading'>My account</span>

          <p className='footer-text'>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
              <g clip-path="url(#clip0_2_3210)">
                <g opacity="0.6">
                  <path d="M0.452148 2.00494L0.452149 6.15828V9.8983C0.452149 10.5383 1.22548 10.8583 1.67882 10.405L5.13215 6.95161C5.68549 6.39828 5.68549 5.49828 5.13215 4.94495L3.81882 3.63161L1.67882 1.49161C1.22548 1.04494 0.452148 1.36495 0.452148 2.00494Z" fill="#425A8B" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2_3210">
                  <rect width="6" height="10" fill="white" transform="translate(0 0.950195)" />
                </clipPath>
              </defs>
            </svg>
            <span className='ml-2'> FAQs </span></p>
          <p className='footer-text'>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
              <g clip-path="url(#clip0_2_3210)">
                <g opacity="0.6">
                  <path d="M0.452148 2.00494L0.452149 6.15828V9.8983C0.452149 10.5383 1.22548 10.8583 1.67882 10.405L5.13215 6.95161C5.68549 6.39828 5.68549 5.49828 5.13215 4.94495L3.81882 3.63161L1.67882 1.49161C1.22548 1.04494 0.452148 1.36495 0.452148 2.00494Z" fill="#425A8B" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2_3210">
                  <rect width="6" height="10" fill="white" transform="translate(0 0.950195)" />
                </clipPath>
              </defs>
            </svg>
            <span className='ml-2'> Editor Help </span></p>
          <p className='footer-text'>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
              <g clip-path="url(#clip0_2_3210)">
                <g opacity="0.6">
                  <path d="M0.452148 2.00494L0.452149 6.15828V9.8983C0.452149 10.5383 1.22548 10.8583 1.67882 10.405L5.13215 6.95161C5.68549 6.39828 5.68549 5.49828 5.13215 4.94495L3.81882 3.63161L1.67882 1.49161C1.22548 1.04494 0.452148 1.36495 0.452148 2.00494Z" fill="#425A8B" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2_3210">
                  <rect width="6" height="10" fill="white" transform="translate(0 0.950195)" />
                </clipPath>
              </defs>
            </svg>
            <span className='ml-2'> Community </span></p>
          <p className='footer-text'>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
              <g clip-path="url(#clip0_2_3210)">
                <g opacity="0.6">
                  <path d="M0.452148 2.00494L0.452149 6.15828V9.8983C0.452149 10.5383 1.22548 10.8583 1.67882 10.405L5.13215 6.95161C5.68549 6.39828 5.68549 5.49828 5.13215 4.94495L3.81882 3.63161L1.67882 1.49161C1.22548 1.04494 0.452148 1.36495 0.452148 2.00494Z" fill="#425A8B" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2_3210">
                  <rect width="6" height="10" fill="white" transform="translate(0 0.950195)" />
                </clipPath>
              </defs>
            </svg>
            <span className='ml-2'> Live Chatting </span></p>
          <p className='footer-text'>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
              <g clip-path="url(#clip0_2_3210)">
                <g opacity="0.6">
                  <path d="M0.452148 2.00494L0.452149 6.15828V9.8983C0.452149 10.5383 1.22548 10.8583 1.67882 10.405L5.13215 6.95161C5.68549 6.39828 5.68549 5.49828 5.13215 4.94495L3.81882 3.63161L1.67882 1.49161C1.22548 1.04494 0.452148 1.36495 0.452148 2.00494Z" fill="#425A8B" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2_3210">
                  <rect width="6" height="10" fill="white" transform="translate(0 0.950195)" />
                </clipPath>
              </defs>
            </svg>
            <span className='ml-2'> Contact Us </span></p>
          <p className='footer-text'>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
              <g clip-path="url(#clip0_2_3210)">
                <g opacity="0.6">
                  <path d="M0.452148 2.00494L0.452149 6.15828V9.8983C0.452149 10.5383 1.22548 10.8583 1.67882 10.405L5.13215 6.95161C5.68549 6.39828 5.68549 5.49828 5.13215 4.94495L3.81882 3.63161L1.67882 1.49161C1.22548 1.04494 0.452148 1.36495 0.452148 2.00494Z" fill="#425A8B" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2_3210">
                  <rect width="6" height="10" fill="white" transform="translate(0 0.950195)" />
                </clipPath>
              </defs>
            </svg>
            <span className='ml-2'> Support Center </span></p>
        </div> */}
        <div className='col-12 col-sm-6 col-lg-3'>
          <span className='fs-5 font-bold d-inline-block mb-4 footer-heading'>App & Payment</span>

          <p className='footer-text'>Download our Apps and get extra 15% Discount on your first Order…!</p>

          <div className='d-flex'>
            <img src={AppStore} />
            <img src={PlayStore} />
          </div>
        </div>
      </div>
    </section>
    </>
    </>
  )
}

export default Footer;
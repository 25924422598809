import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useNavigate } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';

import Whatsapp from '../components/landingpage/whatsapp'
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, onValue, onValueChange } from "firebase/database";
import Header from "./header";
import Footer from "./footer";
import Breadcrumbs from "./breadcrumbs";
import ProductQuote from "./get_product_quote"; 


function Product_Detail() {

  const navigate = useNavigate();
  const [sheet, setSheet] = useState([
    {
      id: 1,
      name: "",
      quantity: "",
      piece: "",
      price: "",
      des: "",
      status: "normal",
    },
  ]);

  const [mesg, setMesg] = useState("");
  const [pid, setpid] = useState("");
  const [uid, setUid] = useState("");
  const [piduser, setpidUser] = useState("");
  const [catid, setcatid] = useState("");
  const [chatid, setChatid] = useState("");
  const [chatUser, setChatUser] = useState("");

  const [user, setUser] = useState([]);
  const [allcats, setAllcats] = useState([]);
  const [alert2, setAlert2] = useState(false);
  const [allUID, setAllUID] = useState([]);
  const [allSimilar, setAllSimilar] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [errors, setErrors] = useState({});


  const [profile, setProfile] = useState([]);
  const [company, setCompany] = useState([]);
  const [cname, setCname] = useState("");
  const [imgCompany, setImgCompany] = useState([]);
  const [logo, setImgLogo] = useState([]);
  
  const CompanyProfileView = async (id) => {

    const data = new FormData();

    data.append("uid", id);

    axios({
      method: "post",
      url: "/pro/profile/profile_view_company.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {

        const dd = JSON.parse(s.data[0].aray_pprofile);
        console.log("company Profile", dd);
        setProfile(dd);
        const d = JSON.parse(s.data[0].aray_com);
        setCompany(d);
        setCname(dd[0].companyname)

        // console.log("Personal Profile", dd);

      })
      .catch((s) => {

        console.log("here catch =>>", s);
      });
  };


  useEffect(() => {
    const aa = localStorage.getItem("USER");
    if (aa != null) {
      const as = JSON.parse(aa);

      // console.log("sss", as);
      setUser(as);

      setUid(as[0].id);
      CompanyProfileView(as[0].id);
      ViewImgCompany(as[0].id)
    } else {
      // console.log("Login Please");
    }

    getServercats();
  }, []);

  const getServercats = async () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get("id");
    setpid(id);

    const data = new FormData();
    data.append("id", id);

    axios({
      method: "post",
      url: "/mainsite/productbyid.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setAllcats(s.data);
        getServerUID(s.data[0].uid);
        setcatid(s.data[0].cat);
        changeArayquote(0, { name: s.data[0].name });
        getServerSimilar(s.data[0].cat);
        setpidUser(s.data[0].uid);
        // console.log("product", s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };
  const getServerUID = async (uid) => {
    const data = new FormData();

    data.append("uid", uid);

    axios({
      method: "post",
      url: "/mainsite/productbyuid.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setAllUID(s.data);
        setChatUser(s.data[0].uid);
        // console.log("Product By Uid", s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  const getServerSimilar = async (cat) => {
    const data = new FormData();
    data.append("cat", cat);

    axios({
      method: "post",
      url: "/mainsite/productbycat.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setAllSimilar(s.data);
        // console.log(" set All Similar ", s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  const ViewImgCompany = async (uid) => {
    const data = new FormData();
    data.append('uid', uid);
    data.append('val', "view");
    axios({
      method: 'post',
      url: 'pro/profile/manage_profile_images.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        setImgCompany(s.data)

        setImgLogo(s.data.find(item => item.type === "logo"));

        console.log("compny images",s.data)
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  const changeArayquote = (i, changes) => {
    const oldArr = [...sheet];
    const oldObj = oldArr[i];
    oldArr[i] = { ...oldObj, ...changes };
    setSheet(oldArr);
  };


  const [selectedImage, setSelectedImage] = useState(null);




  const openImage = (imageUrl) => {
    setSelectedImage(imageUrl); // Open the clicked image in full-screen
  };

  const closeImage = () => {
    setSelectedImage(null); // Close the full-screen view
  };

  return (
    <>
      <Header />
      <main className="main">

        {/* <div className="container">
          <div className="product-images">
            <img src="https://alahdeen.com/assets/images/banner/1.png" alt="Main Product" className="main-image" />
            Additional thumbnails can be placed here
          </div>
          
        </div> */}



{allcats.map((v, i) => (
        <div key={i}>
          <Breadcrumbs categoryId={v.cat} />
          <div className="container mb-4">
            <div className="row align-items-stretch">

              {/* Image Carousel Section with Thumbnails */}
              <div className="col-12 col-lg-6 d-flex">
                <div className="product-image-slider box-shadow flex-fill d-flex flex-column align-items-center"
                  style={{
                    borderRadius: "25px",
                    backgroundColor: "#fff",
                    minHeight: "100%",
                    padding: "20px",
                  }}
                >
                  <Carousel
                    className="w-100 h-100 py-5"
                    variant="dark"
                    style={{ borderRadius: "25px", maxHeight: "75%" }}
                    activeIndex={activeIndex}
                    onSelect={(selectedIndex) => setActiveIndex(selectedIndex)}
                  >
                    {[v.img, v.img_a, v.img_b].map((imgSrc, index) => (
                      <Carousel.Item key={index} style={{ borderRadius: "25px" }}>
                        <div className="d-flex align-items-center justify-content-center w-100"
                          style={{
                            width: "600px",
                            height: "600px",
                            margin: "0 auto",
                            backgroundColor: "#fff",
                            borderRadius: "25px",
                          }}
                        >
                          <img
                            src={imgSrc}
                            alt={`Slide ${index + 1}`}
                            className="d-block"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              objectFit: "contain",
                              cursor: "pointer",
                              borderRadius: "25px",
                            }}
                            onClick={() => openImage(imgSrc)}
                          />
                        </div>
                      </Carousel.Item>
                    ))}
                  </Carousel>

                  {/* Thumbnail Navigation */}
                  <div className="thumbnail-container d-flex justify-content-center my-3">
                    {[v.img, v.img_a, v.img_b].map((imgSrc, index) => (
                      <div key={index} className={`thumbnail-box mx-2 ${activeIndex === index ? 'active-thumbnail' : ''}`}
                        onClick={() => setActiveIndex(index)}
                        style={{
                          width: "60px",
                          height: "60px",
                          cursor: "pointer",
                          borderRadius: "10px",
                          border: activeIndex === index ? "2px solid #1A78B4" : "1px solid lightgray",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#fff",
                        }}
                      >
                        <img
                          src={imgSrc}
                          alt={`Thumbnail ${index + 1}`}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "contain",
                            borderRadius: "10px",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Product Details Section */}
              <div className="col-12 col-lg-6 d-flex">
                <div className="product-details box-shadow flex-fill"
                  style={{
                    padding: "44px 33px 32px 33px",
                    borderRadius: "25px",
                    minHeight: "100%",
                  }}
                >
                  <div className="d-flex justify-content-between align-items-end mt-3">
                    <h1 className="product-title fs-2 m-0" style={{ color: "#425A8B", fontSize: "28px", fontWeight: 700 }}>
                      {v.name}
                    </h1>
                  </div>
                  <div className="d-flex justify-content-between align-items-end my-3">
                    <h3 className="price fs-3 font-bold m-0" style={{ fontSize: "32px", color: "#010101" }}>
                      Price: <span style={{fontSize: "32px", color: "red"}}>Rs {v.price}</span><br />
                      {v.pro_price !== "" && <>Discount Price: <span style={{fontSize: "32px", color: "red"}}>Rs {v.pro_price}</span></>}
                    </h3>
                  </div>

                  <div className="py-4" style={{ color: "lightgray", borderTop: "2px solid #DDE4F0", borderBottom: "2px solid #DDE4F0" }}>
                    <ul className="list-dot" style={{ color: "#425A8B", fontSize: "14px", lineHeight: "18px" }}>
                      <li>Min Order Qty: {v.minimum_quantity}</li>
                      <li>SKU Code: {v.barcode}</li>
                      <li>Delivery Time: {v.delivery}</li>
                    </ul>
                    <h5 className="pt-3 text-left" style={{ color: "#1A78B4" }}>Descriptions</h5>
                    <p>{v.des}</p>
                  </div>

                  {/* Get Product Quote Form */}
                  <ProductQuote
                    product={v}
                    user={user}
                    uid={uid}
                    piduser={piduser}
                    navigate={navigate}
                  />

                  {/* About the Seller Section (WITH CIRCULAR IMAGE) */}
                  <h5 className="pt-3 text-left" style={{ color: "#1A78B4" }}>About the Seller</h5>
                  <div className="d-flex flex-wrap align-items-center justify-content-between mt-3">
            <div className="d-flex align-items-center">
                <div style={{ position: "relative", width: "90px", height: "90px" }}>
                    <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.25" y="0.25" width="89.5" height="89.5" rx="10" fill="white" stroke="#4f97a1" strokeWidth="2" />
                    </svg>
                    {logo && (
                        <div style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            width: "88px",
                            height: "88px",
                            borderRadius: "10px",
                            overflow: "hidden",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            transform: "translate(-50%, -50%)",
                            backgroundColor: "white",
                            padding: "10px"
                        }}>
                            <img src={logo.image} alt="Company Logo" style={{ width: "100%", height: "100%", objectFit: "contain", borderRadius: "10px" }} />
                        </div>
                    )}
                </div>
                <div className="seller-info ml-3">
                    <p style={{ fontSize: "14px", color: "#1D1D1D" }}>{cname && cname}</p>
                    <p style={{ fontSize: "14px", color: "#1D1D1D" }}>{profile[0] && profile[0].address}</p>
                </div>
            </div>
            <button className="mt-3 mt-sm-0 border rounded-pill" style={{ color: "whitesmoke", backgroundColor: "#1A78B4", padding: ".5rem 3rem" }} onClick={() => navigate(`/companydetail?business=${v.uid}`)}>Company Website</button>
        </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      ))}



        <div
          className="modal fade"
          id="ModalFiltersForm"
          tabIndex={-1}
          aria-hidden="true"
          style={{ display: "none" }}
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content apply-job-form">
              <div className="modal-header">
                <h5 className="modal-title color-gray-1000 filters-icon">
                  Addvance Fillters
                </h5>
                <button
                  className="btn-close"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body p-30">
                <div className="row">
                  <div className="col-w-1">
                    <h6 className="color-gray-900 mb-0">Brands</h6>
                    <ul className="list-checkbox">
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" defaultChecked="checked" />
                          <span className="text-small">Apple</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Samsung</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Baseus</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Remax</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Handtown</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Elecom</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Razer</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Auto Focus</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Nillkin</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Logitech</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">ChromeBook</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="col-w-1">
                    <h6 className="color-gray-900 mb-0">Special offers</h6>
                    <ul className="list-checkbox">
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">On sale</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" defaultChecked="checked" />
                          <span className="text-small">FREE shipping</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Big deals</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Shop Mall</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                    </ul>
                    <h6 className="color-gray-900 mb-0 mt-40">Ready to ship in</h6>
                    <ul className="list-checkbox">
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">1 business day</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" defaultChecked="checked" />
                          <span className="text-small">1–3 business days</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">in 1 week</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Shipping now</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="col-w-1">
                    <h6 className="color-gray-900 mb-0">Ordering options</h6>
                    <ul className="list-checkbox">
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Accepts gift cards</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Customizable</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" defaultChecked="checked" />
                          <span className="text-small">Can be gift-wrapped</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Installment 0%</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                    </ul>
                    <h6 className="color-gray-900 mb-0 mt-40">Rating</h6>
                    <ul className="list-checkbox">
                      <li className="mb-5">
                        <a href="#">
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                            (5 stars)
                          </span>
                        </a>
                      </li>
                      <li className="mb-5">
                        <a href="#">
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                            (4 stars)
                          </span>
                        </a>
                      </li>
                      <li className="mb-5">
                        <a href="#">
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                            (3 stars)
                          </span>
                        </a>
                      </li>
                      <li className="mb-5">
                        <a href="#">
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                            (2 stars)
                          </span>
                        </a>
                      </li>
                      <li className="mb-5">
                        <a href="#">
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                            (1 star)
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-w-2">
                    <h6 className="color-gray-900 mb-0">Material</h6>
                    <ul className="list-checkbox">
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Nylon (8)</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Tempered Glass (5)</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" defaultChecked="checked" />
                          <span className="text-small">
                            Liquid Silicone Rubber (5)
                          </span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Aluminium Alloy (3)</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                    </ul>
                    <h6 className="color-gray-900 mb-20 mt-40">Product tags</h6>
                    <div>
                      <a className="btn btn-border mr-5" href="#">
                        Games
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        Electronics
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        Video
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        Cellphone
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        Indoor
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        VGA Card
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        USB
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        Lightning
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        Camera
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-start pl-30">
                <a className="btn btn-buy w-auto" href="#">
                  Apply Fillter
                </a>
                <a className="btn font-sm-bold color-gray-500" href="#">
                  Reset Fillter
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="ModalQuickview"
          tabIndex={-1}
          aria-hidden="true"
          style={{ display: "none" }}
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content apply-job-form">
              <button
                className="btn-close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <div className="modal-body p-30">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="gallery-image">
                      <div className="galleries-2">
                        <div className="detail-gallery">
                          <div className="product-image-slider-2">
                            <figure className="border-radius-10">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-1.jpg"
                                alt="product image"
                              />
                            </figure>
                            <figure className="border-radius-10">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-2.jpg"
                                alt="product image"
                              />
                            </figure>
                            <figure className="border-radius-10">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-3.jpg"
                                alt="product image"
                              />
                            </figure>
                            <figure className="border-radius-10">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-4.jpg"
                                alt="product image"
                              />
                            </figure>
                            <figure className="border-radius-10">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-5.jpg"
                                alt="product image"
                              />
                            </figure>
                            <figure className="border-radius-10">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-6.jpg"
                                alt="product image"
                              />
                            </figure>
                            <figure className="border-radius-10">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-7.jpg"
                                alt="product image"
                              />
                            </figure>
                          </div>
                        </div>
                        <div className="slider-nav-thumbnails-2">
                          <div>
                            <div className="item-thumb">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-1.jpg"
                                alt="product image"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="item-thumb">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-2.jpg"
                                alt="product image"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="item-thumb">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-3.jpg"
                                alt="product image"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="item-thumb">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-4.jpg"
                                alt="product image"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="item-thumb">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-5.jpg"
                                alt="product image"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="item-thumb">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-6.jpg"
                                alt="product image"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="item-thumb">
                              <img
                                src="assetsss/imgs/page/product/img-gallery-7.jpg"
                                alt="product image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box-tags">
                      <div className="d-inline-block mr-25">
                        <span className="font-sm font-medium color-gray-900">
                          Category:
                        </span>
                        <a className="link" href="#">
                          Smartphones
                        </a>
                      </div>
                      <div className="d-inline-block">
                        <span className="font-sm font-medium color-gray-900">
                          Tags:
                        </span>
                        <a className="link" href="#">
                          Blue
                        </a>
                        ,
                        <a className="link" href="#">
                          Smartphone
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="product-info">
                      <h5 className="mb-15">
                        SAMSUNG Galaxy S22 Ultra, 8K Camera &amp; Video, Brightest
                        Display Screen, S Pen Pro
                      </h5>
                      <div className="info-by">
                        <span className="bytext color-gray-500 font-xs font-medium">
                          by
                        </span>
                        <a
                          className="byAUthor color-gray-900 font-xs font-medium"
                          href="shop-vendor-list.html"
                        >
                          {" "}
                          Ecom Tech
                        </a>
                        <div className="rating d-inline-block">
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <span className="font-xs color-gray-500 font-medium">
                            {" "}
                            (65 reviews)
                          </span>
                        </div>
                      </div>
                      <div className="border-bottom pt-10 mb-20" />
                      <div className="box-product-price">
                        <h3 className="color-brand-3 price-main d-inline-block mr-10">
                          $2856.3
                        </h3>
                        <span className="color-gray-500 price-line font-xl line-througt">
                          $3225.6
                        </span>
                      </div>
                      <div className="product-description mt-10 color-gray-900">
                        <ul className="list-dot">
                          <li>8k super steady video</li>
                          <li>Nightography plus portait mode</li>
                          <li>50mp photo resolution plus bright display</li>
                          <li>Adaptive color contrast</li>
                          <li>premium design &amp; craftmanship</li>
                          <li>Long lasting battery plus fast charging</li>
                        </ul>
                      </div>
                      <div className="box-product-color mt-10">
                        <p className="font-sm color-gray-900">
                          Color:
                          <span className="color-brand-2 nameColor">Pink Gold</span>
                        </p>
                        <ul className="list-colors">
                          <li className="disabled">
                            <img
                              src="assetsss/imgs/page/product/img-gallery-1.jpg"
                              alt="Ecom"
                              title="Pink"
                            />
                          </li>
                          <li>
                            <img
                              src="assetsss/imgs/page/product/img-gallery-2.jpg"
                              alt="Ecom"
                              title="Gold"
                            />
                          </li>
                          <li>
                            <img
                              src="assetsss/imgs/page/product/img-gallery-3.jpg"
                              alt="Ecom"
                              title="Pink Gold"
                            />
                          </li>
                          <li>
                            <img
                              src="assetsss/imgs/page/product/img-gallery-4.jpg"
                              alt="Ecom"
                              title="Silver"
                            />
                          </li>
                          <li className="active">
                            <img
                              src="assetsss/imgs/page/product/img-gallery-5.jpg"
                              alt="Ecom"
                              title="Pink Gold"
                            />
                          </li>
                          <li className="disabled">
                            <img
                              src="assetsss/imgs/page/product/img-gallery-6.jpg"
                              alt="Ecom"
                              title="Black"
                            />
                          </li>
                          <li className="disabled">
                            <img
                              src="assetsss/imgs/page/product/img-gallery-7.jpg"
                              alt="Ecom"
                              title="Red"
                            />
                          </li>
                        </ul>
                      </div>
                      <div className="box-product-style-size mt-10">
                        <div className="row">
                          <div className="col-lg-12 mb-10">
                            <p className="font-sm color-gray-900">
                              Style:
                              <span className="color-brand-2 nameStyle">S22</span>
                            </p>
                            <ul className="list-styles">
                              <li className="disabled" title="S22 Ultra">
                                S22 Ultra
                              </li>
                              <li className="active" title="S22">
                                S22
                              </li>
                              <li title="S22 + Standing Cover">
                                S22 + Standing Cover
                              </li>
                            </ul>
                          </div>
                          <div className="col-lg-12 mb-10">
                            <p className="font-sm color-gray-900">
                              Size:
                              <span className="color-brand-2 nameSize">512GB</span>
                            </p>
                            <ul className="list-sizes">
                              <li className="disabled" title="1GB">
                                1GB
                              </li>
                              <li className="active" title="512 GB">
                                512 GB
                              </li>
                              <li title="256 GB">256 GB</li>
                              <li title="128 GB">128 GB</li>
                              <li className="disabled" title="64GB">
                                64GB
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="buy-product mt-5">
                        <p className="font-sm mb-10">Quantity</p>
                        <div className="box-quantity">
                          <div className="input-quantity">
                            <input
                              className="font-xl color-brand-3"
                              type="text"
                              defaultValue={1}
                            />
                            <span className="minus-cart" />
                            <span className="plus-cart" />
                          </div>
                          <div className="button-buy">
                            <a className="btn btn-cart" href="shop-cart.html">
                              Add to cart
                            </a>
                            <a className="btn btn-buy" href="shop-checkout.html">
                              Buy now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      {/* Fullscreen Image Modal */}
      {selectedImage && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
          style={{ backgroundColor: 'rgba(0,0,0,0.8)', zIndex: 1050 }} // Increase z-index to 1050
          onClick={closeImage}
        >
          <img
            src={selectedImage}
            alt="fullscreen"
            className="img-fluid"
            style={{ maxHeight: '90%', maxWidth: '90%' }}
          />
          <button
            className="position-absolute top-0 end-0 m-3 btn btn-light"
            onClick={closeImage}
          >
            Close
          </button>
        </div>

      )}

    </>

  )
}

export default Product_Detail
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "react-bootstrap";
import Nav from "./navbar";
import Services from "./ourservices";
import Footer from "./footer";
import axios from "axios";
import Whatsapp from "./whatsapp";
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import { Helmet } from "react-helmet";
import Seller from '../../images/seller-dashboard-banner.png';
import Logo from '../../images/logo.png';
import '../../styles/header.css';
import { SiGooglemaps } from "react-icons/si";
import { FaPhone } from "react-icons/fa6";


function Homepage() {



  const navigate = useNavigate();
  const [allcats, setAllcats] = useState([]);
  const [user, setUser] = useState([]);
  const [alert1, setAlert1] = useState();
  const [uid, setUid] = useState();
  const [lead, setLead] = useState([]);
  const [promotion, setPromotion] = useState([]);
  const [searched, setSearched] = useState([]);
  const [trending, setTrending] = useState([]);
  const [trade, setTrade] = useState([]);
  const [city, setCity] = useState("Karachi");
  const [listcities, setCitiesList] = useState([]);

  const [main, setMainCat] = useState([]);
  const [error, seterror] = useState(false);
  const [cat, setCat] = useState("");


  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const getCities = async () => {
    const data = new FormData();

    axios({
      method: "post",
      url: "/pro/auth/cities_list.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setCitiesList(s.data)
      })
      .catch((s) => {

      });


  };


  useEffect(() => {
    getCities();
    getServercats()
    lead_view_all()
    Viewpromotion()
    ViewSearched()
    ViewTrending()
    Tradeshow()
    getMainCat()

    const aa = localStorage.getItem("USER");
    if (aa != null) {
      const as = JSON.parse(aa);
      setUid(as[0].id);
      setUser(as);
    }
  }, []);

  const getMainCat = async () => {
    const data = new FormData();
    data.append('uid', 'ss');

    axios({
      method: 'post',
      url: '/pro/cat/maincat.php',
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(s => {
        console.log('Main cat list ', s.data);
        const a = s.data;
        setMainCat(a);
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };


  const [sheet, setSheet] = useState(
    [{
      id: 1,
      name: "",
      qty: "",
      quantity: "",
      piece: "Pieces",
      price: "",
      des: "",
      status: "normal"
    }
    ]);

  const getServercats = async () => {
    const data = new FormData();

    data.append("uid", "");


    axios({
      method: "post",
      url: "/mainsite/maineight.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setAllcats(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  const Viewpromotion = async () => {
    const data = new FormData();




    axios({
      method: "post",
      url: "/pro/promotional_products.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {

        setPromotion(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };


  const ViewSearched = async () => {
    const data = new FormData();
    axios({
      method: "post",
      url: "/pro/mostsearch.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log('searched', s.data);
        setSearched(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };




  const ViewTrending = async () => {
    const data = new FormData();

    //data.append('array', JSON.stringify(profile));

    axios({
      method: "post",
      url: "/pro/trending.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log('trending', s.data);
        setTrending(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };


  const Tradeshow = async () => {
    const data = new FormData();

    data.append("val", "last");


    axios({
      method: "post",
      url: "/pro/tradeshow/manage_trade.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log('tradeshow', s.data);
        setTrade(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };


  const add_lead = async (sheet, uid, c) => {

    const data = new FormData();

    data.append("uid", uid);
    data.append("sheet", JSON.stringify(sheet));
    data.append("cat", c);
    data.append("img", "");

    axios({
      method: "post",
      url: "/pro/lead_add.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {

        setAlert1(!alert1);
        navigate('/product-buy-request')
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });

  };
  const lead_view_all = async id => {
    const data = new FormData();
    data.append('uid', id);

    axios({
      method: 'post',
      url: '/pro/lead_view_all.php',
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(s => {
        // console.log('lead list ', s.data);
        const a = s.data;
        setLead(a);
      })
      .catch(s => {
        // console.log('here lead_view_all ', s);
      });
  };
  const changeArayquote = (i, changes) => {
    const oldArr = [...sheet];
    const oldObj = oldArr[i];
    oldArr[i] = { ...oldObj, ...changes };
    setSheet(oldArr);
  };


  return (
    <div className="container-fluid">
      {/* Hero Banner Section */}
      <div className="row">
        <div className="col-12 d-flex justify-content-center align-items-center estore-backgrund-banner"
        style={{ height: '60vh', objectFit: 'cover',
          // background: 'url("../../images/seller-dashboard-banner.png")',
          backgroundSize: 'cover',
          backgroundPosition: 'center'

         }}
        >
          <h1 className="text-light">E Store</h1>
          {/* <img
            src={Seller}
            alt="Hero Banner"
            className="img-fluid w-100"
            s
          /> */}
          </div>
          <div className="col-12">
          {/* </div> */}
          {/* Company Logo and Details */}
          {/* <div
              className="position-absolute"
              style={{ top: '300px', left: '50px', transform: 'translateY(-10%)' }}
            > */}
          <div className="d-flex align-items-center px-3 w-100">
            <img
              src={Logo} // Replace with your logo path
              alt="Company Logo"
              className="rounded-circle"
              style={{ width: '100px', height: '100px', border: '5px solid white', marginTop: '-80px' }}
            />
            <div className="ms-3 d-flex justify-content-between w-100">
              <span className="text-muted">Fasfox Corporation</span>
              <div>
                <div className="d-flex gap-2">
                  <SiGooglemaps style={{ color: '#2C99A1' }} />
                  <span className="mb-0 text-dark">5711 W Campbell Ave undefined Karachi, Pakistan</span>
                </div>
                <div className="d-flex gap-2">
                  <FaPhone style={{ color: '#2C99A1' }} />
                  <span className="mb-0 d-block text-dark">(+92) - 302-025-2453</span>
                </div>
              </div>
              
            </div>
            {/* </div> */}
          </div>
        </div>


        {/* Sorting and Filters Section */}
        <div className="row mt-4">
          <div className="col-12">
            <div className="d-flex justify-content-end align-items-center gap-5">
              <p className="mb-0 mt-2">Showing 1–16 of 17 results</p>
              <div className="d-flex align-items-center">
                <div className="me-3">
                  <label className="form-label mb-0 me-2">Sort by:</label>
                  <select className="form-select form-select-sm">
                    <option>Latest products</option>
                    <option>Price: Low to High</option>
                    <option>Price: High to Low</option>
                  </select>
                </div>
                <div>
                  <label className="form-label mb-0 me-2">Show:</label>
                  <select className="form-select form-select-sm">
                    <option>30 items</option>
                    <option>50 items</option>
                    <option>100 items</option>
                  </select>
                </div>
                <div className="ms-3">
                  <button className="btn btn-light me-1">
                    <i className="bi bi-grid-3x3-gap"></i>
                  </button>
                  <button className="btn btn-light">
                    <i className="bi bi-list"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Products Section */}
        <div className="row mt-5">
          <div className="col-12">
            <h2 className="mb-4">Products</h2>
          </div>
          {[...Array(12)].map((_, index) => (
            <div className="col-md-3 col-sm-6 mb-4" key={index}>
              <div className="card h-100" style={{ borderRadius: '16px' }}>
                <img
                  src={Logo} // Replace with product image path
                  className="card-img-top"
                  alt="Product"
                  style={{ height: '200px', objectFit: 'cover', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}
                />
                <div className="card-body card-body-estore ">
                  <h5 className="card-title">Product Title</h5>
                  <p className="card-text">Rs. 2856.3</p>
                  {/* <button className="btn w-100" style={{ borderRadius: '16px', backgroundColor: 'rgb(69, 172, 179)', color: 'white' }}>Get Quote</button> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Add other sections like products and features below */}

    </div>
  );
}

export default Homepage;

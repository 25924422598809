import Nav from "./navbar";
import { useState, useEffect } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { PieChart, Pie, Tooltip } from 'recharts';
import '../styles/sellerdashboard.css';
import { GiMagicLamp } from "react-icons/gi";
import ReactApexChart from 'react-apexcharts';
import Khata from '../images/khata.png'
import Inventory from '../images/inventory.png'

const badgeStyle = {
  position: 'relative',
  display: 'inline-block',
  width: '20px', // Fixed size for the circle
  height: '20px',
  backgroundColor: '#1ABCFE',
  color: 'white',
  borderRadius: '50%',
  fontSize: '12px',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', // Optional shadow
};


function Mainpage() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('')
  const [allPackages, setAllPackages] = useState([])
  const [allviewMsg, setAllViewMsg] = useState([]);
  const [chat, setChat] = useState([]);
  const [chatid, setChatid] = useState("");
  const [lead, setLead] = useState([]);
  const [uiduser, setuiduser] = useState("");

  const [debit, setDebit] = useState('');
  const [datedebit, setDateDebit] = useState('');
  const [credit, setCredit] = useState('');
  const [creditdate, setCreditdate] = useState('');
  const [balance, setBalance] = useState('');
  //03039965869 
  const [date, setDate] = useState('');
  const [userType, setUserType] = useState('');
  const [darw, setDarw] = useState(true);

  // Data for the chart
  const series = [40, 35, 25]; // Sent, Delivered, Seen
  const options = {
    chart: {
      type: 'donut',
    },
    labels: ['Sent', 'Delivered', 'Seen'], // Labeling the segments
    responsive: [
      {
        breakpoint: 360, // Mobile screen breakpoint
        options: {
          chart: {
            width: '100%',
          },
          legend: {
            position: 'bottom', // Move the legend to the bottom on small screens
            offsetY: 0, // Adjust the legend's position vertically if needed
          },
          dataLabels: {
            enabled: true, // Ensure data labels are enabled on mobile
            style: {
              fontSize: '12px', // You can adjust the font size if the labels are too large
            },
          },
        },
      },
    ],
    legend: {
      position: 'right', // Position of the legend by default
    },
    dataLabels: {
      enabled: true, // Enable data labels
    },
    plotOptions: {
      pie: {
        donut: {
          size: '60%', // Size of the donut hole
        },
      },
    },
  };




  const [uid, setUid] = useState('');
  const [totalChatsData, setTotalChatsData] = useState([]);
  const [productBuyerRequests, setProductBuyerRequests] = useState([]);
  const [recentMessages, setRecentMessages] = useState([]);
  const user = JSON.parse(localStorage.getItem('USER'));

  const currentDate = new Date();

  // Manually format to YYYY-MM-DD
  const formattedDate = currentDate.toISOString().split('T')[0];
  useEffect(() => {

    if (user) {
      setUid(user[0].id);
      fetchTotalChats(user[0].id);
      fetchProductBuyerRequests(user[0].id);
      fetchRecentMessages(user[0].id);
    }
  }, []);

  const fetchTotalChats = async (id) => {
    try {
      const response = await axios.get('/mainsite/chatbox/piechart_total_chats.php', { params: { uid: id } });
      if (response.data[0].code === 'success') {
        const { sent, delivered, seen } = response.data[0];
        setTotalChatsData([
          { name: 'Sent', value: sent },
          { name: 'Delivered', value: delivered },
          { name: 'Seen', value: seen },
        ]);
      }
    } catch (error) {
      console.error('Error fetching total chats:', error);
    }
  };

  const fetchProductBuyerRequests = async (id) => {
    try {
      const formData = new FormData();
      formData.append('uid', id);
      const response = await axios.post('/pro/lead_view_all.php', formData);
      setProductBuyerRequests(response.data);
    } catch (error) {
      console.error('Error fetching product buyer requests:', error);
    }
  };

  const fetchRecentMessages = async (id) => {
    try {
      const response = await axios.get('/mainsite/chatbox/recent_messages.php', { params: { uid: id, limit: 10 } });
      setRecentMessages(response.data);
    } catch (error) {
      console.error('Error fetching recent messages:', error);
    }
  };

  const sampleLeadsData = [
    { name: 'Item 1', value: 400 },
    { name: 'Item 2', value: 300 },
    { name: 'Item 3', value: 300 },
  ];



  useEffect(() => {
    const data = new FormData();
    data.append("uid", user[0].id);
    axios.post('/pro/auth/getUserInfo.php', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => {
        // Verify the response structure
        const name1 = response?.data?.[0]?.user?.name;
    
        if (name1) {
          setUserName(name1); // Set the user name if it exists
        } else {
          console.error('User name not found in response:', response.data);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
        // Optionally, set an error state or display an error message to the user
      });
    

    // Make GET request with Axios
    axios.get('/pro/package_showcase.php')
      .then((response) => {
        // Handle success - store response data
        console.log('Response:', response.data);
        // Extracting only the 'title' from each object
        const titles = response.data.map(item => item.title);
        setAllPackages(titles);
        console.log(titles);
      })
      .catch((err) => {
        // Handle error
        console.error('Error:', err);
        //  setError(err);  Update state with the error
      });
    // const search = window.location.search;
    // const params = new URLSearchParams(search);

    // console.log(params.get('name'))
    // console.log(params.get('fname'))
    // console.log(params.get('age'))
    // console.log(params.get('address'))

    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
      // if (userType=="")
      const ti = v[0].roll;
      if (ti === "") {
        navigate('/profile');
      }
      else {

      }

      setUid(v[0].id);
      setUserType(v[0].roll)
      lead_view_all(v[0].id, v[0].cat);
      const uiduser = localStorage.getItem("chatuserid");
      // console.log(uiduser);
      setuiduser(uiduser);
      viewchat(v[0].id, uiduser);
      Viewchatbox(v[0].id);
      getBlance(v[0].id);
    }
  }, []);


  const checkvalidity = (lead_id) => {
    localStorage.setItem(
      'leadid',
      lead_id,
    );
    // navigate('/chat');
    navigate('/sendquote');
  }

  // Helper function to format time
  const formatTime = (time) => {
    if (isNaN(time) || time === undefined) return "0:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleLoadedMetadata = (event, sourceId) => {
    const audioElement = event.target;
    const duration = audioElement.duration; // Get the total duration of the audio
    const formattedDuration = formatTime(duration);
  
    // Set the duration as part of a custom attribute or update UI as needed
    audioElement.setAttribute("data-duration", formattedDuration);
  };
  
  const handleTimeUpdate = (event) => {
    const audioElement = event.target;
    const currentTime = audioElement.currentTime; // Get the current playback time
    const formattedCurrentTime = formatTime(currentTime);
  
    // Update the displayed current time dynamically
    audioElement.setAttribute("data-current-time", formattedCurrentTime);
  };

  // Function to convert URLs in the message to clickable links
  const makeLinksClickable = (message) => {
    // Ensure message is a string
    if (typeof message !== "string") {
      console.error("Invalid message type:", typeof message);
      return message; // Return the input as is if it's not a string
    }

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return message.replace(
      urlRegex,
      (url) =>
        `<a href="${url}" target="_blank" rel="noopener noreferrer" style="color: blue; text-decoration: underline;">${url}</a>`
    );
  };

  const lead_view_all = async (id, cat) => {
    const data = new FormData();
    data.append("uid", id);
    data.append("cat", cat);

    axios({
      method: "post",
      url: "/pro/lead_view_all.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("lead list ", s.data);
        const a = s.data;
        setLead(a);
      })
      .catch((s) => {
        // console.log("here lead_view_all ", s);
      });
  };

  const viewchat = async (id, i) => {
    const data = new FormData();
    data.append("one", id);
    data.append("two", i);

    axios({
      method: "post",
      url: "/pro/chatbox/chatbox.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log("chat list ", s.data);
        const a = s.data;
        if (a[0].code == "pass") {
          // setProductList(a);

          localStorage.setItem("ProductListInventory", JSON.stringify(s.data));
        }
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  const Viewchatbox = async (id) => {
    const data = new FormData();
    data.append("uid", id);

    axios({
      method: "post",
      url: "/pro/chatbox/view_inbox.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log("chatbox view ", s.data);
        const a = s.data;
        setChat(a);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };
  const viewinbox = async (chatid) => {
    const data = new FormData();
    data.append("status", "view");
    data.append("cid", chatid);

    axios({
      method: "post",
      url: "/pro/chatbox/chat.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log("chatbox view ", s.data);
        setAllViewMsg(s.data);
        // console.log(chatid);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };
  const getBlance = async id => {
    const data = new FormData();

    // localStorage.setItem("LOCAL_KEY", JSON.stringify(json));
    data.append('id', id);

    axios({
      method: 'post',
      url: '/pro/wallet_last.php',
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(s => {
        console.log(s.data);
        if (s.data[0].code === 'pass') {
          setBalance(s.data[0].blance);
          setDate(s.data[0].date);

          setDebit(s.data[0].debit);
          setDateDebit(s.data[0].datedebit);

          setCredit(s.data[0].credit);
          setCreditdate(s.data[0].creditdate);
        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };


  return (
    <div>
      <Nav setDarw={setDarw} darw={darw} />

      <div style={{ overflow: 'auto', display: 'flex', flexDirection: 'column', padding: '0px' }} className={darw ? ["header-protected-screen padding-dashboard-table", "mobile-table padding-dashboard-table"].join(' ') : ["header-protected-screen-full-width padding-dashboard-table", "mobile-table padding-dashboard-table"].join(' ')}>
        <div className="container-fluid pb-4">
          {/* <div className="row mb-4">
            <div className="col-12 text-center">
              {console.log('username', userName)}
              <h3>Welcome, {userName}</h3>
              <img src="/sample-image.jpg" alt="Sample" className="img-fluid" />
            </div>
          </div> */}
          <div className="row">
            <div className="banner-seller-dashboard pb-3">
              <span className="ml-2 text-light">Hi, Welcome {userName} </span><br />
              <p className="mt-2 ml-2 text-light">
                Welcome  to Dashboard, Here You Can  also give, <br />
                Feature info or message about the up page
              </p>
            </div>
          </div>


          {/* <h2 class="text-center mb-4">Packages</h2>
          <p class="text-center text-muted">What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
          <div class="row gy-3">
            <div class="col-6 col-sm-4 col-md-3">
              <div class="package-card d-flex align-items-center justify-content-between">
                <h6>Classic</h6>
                <div class="package-icon classic">
                  <GiMagicLamp />
                </div>
              </div>
            </div>
            <div class="col-6 col-sm-4 col-md-3">
              <div class="package-card d-flex align-items-center justify-content-between">
                <h6>Bronze</h6>
                <div class="package-icon bronze">
                  <GiMagicLamp />
                </div>
              </div>
            </div>
            <div class="col-6 col-sm-4 col-md-3">
              <div class="package-card d-flex align-items-center justify-content-between">
                <h6>Silver</h6>
                <div class="package-icon silver">
                  <GiMagicLamp />
                </div>
              </div>
            </div>
            <div class="col-6 col-sm-4 col-md-3">
              <div class="package-card d-flex align-items-center justify-content-between">
                <h6>Gold</h6>
                <div class="package-icon gold">
                  <GiMagicLamp />
                </div>
              </div>
            </div>
            <div class="col-6 col-sm-4 col-md-3">
              <div class="package-card d-flex align-items-center justify-content-between">
                <h6>Diamond</h6>
                <div class="package-icon diamond">
                  <GiMagicLamp />
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="row mb-4">
                <div className="col-8">
                    <h4>Packages Section</h4>
                </div>
            </div> */}

          {/* <div className="row mb-4"> */}
          {/* <div className="row gx-3 gy-2 mt-4"> */}

            {/* </div> */}

            {/* <div className="row mb-4"> */}
            <div className="row">
              <div className="col-12 col-xxl-8 mt-xxl-5 mt-4">
                <div className="row">
            <div className="col-12 col-sm-6 p-4 p-sm-2 p-xl-2">
              <div className="border rounded">
                <div className="pt-4 pb-4 px-5 d-flex justify-content-between align-items-center">
                  <span className="fs-5 fw-bolder" style={{ color: '#252525' }}>Total Leads</span>
                  <span style={{ color: '#AAA6B9', fontSize: '14px' }}>{formattedDate}</span>
                </div>
                <div className="seller-dashboard-carts-divider mb-5" />

                <ReactApexChart options={options} series={series} type="donut" height={350} />

              </div>
              {/* <PieChart width={300} height={300}>
                <Pie dataKey="value" data={sampleLeadsData} cx={150} cy={150} outerRadius={80} fill="#8884d8" label />
                <Tooltip />
              </PieChart> */}
            </div>
            
            <div className="col-12 col-sm-6 p-4 p-sm-2 p-xl-2">
              <div className="border rounded">
                <div className="pt-4 pb-4 px-5 d-flex justify-content-between align-items-center">
                  <span className="fs-5 fw-bolder" style={{ color: '#252525' }}>Total Chats</span>
                  <span style={{ color: '#AAA6B9', fontSize: '14px' }}>{formattedDate}</span>
                </div>
                <div className="seller-dashboard-carts-divider mb-5" />
                <ReactApexChart options={options} series={series} type="donut" height={350} />
              </div>
              {/* <PieChart width={300} height={300}>
                <Pie dataKey="value" data={totalChatsData} cx={150} cy={150} outerRadius={80} fill="#82ca9d" label />
                <Tooltip />
              </PieChart> */}
            </div>
<div className="col-12">
            <div className="row">
            <div className="col-12 col-sm-6">
              {/* <h5>Khatha</h5> */}
              <img src={Khata} alt="Khatha" className="img-fluid" />
            </div>
            <div className="col-12 col-sm-6">
              {/* <h5>Inventory</h5> */}
              <img src={Inventory} alt="Inventory" className="img-fluid" />
            </div>
            </div>
            </div>

            <div className="col-12">
              <h5 className="my-5">Product Buyer Requests</h5>
              <div className="d-flex overflow-auto">
                {productBuyerRequests.map((request, index) => (
                  <div key={index} className="card me-3 seller-product-buyer-card" style={{ minWidth: '320px', borderRadius: '16px' }}> 
                    <div className="card-body seller-product-buyer-card">
                      <div className="d-flex seller-product-buyer-card-header px-4 py-3">
                        <span>Date {request.time.split(' ')[0]}</span>
                        <div className="d-flex">Status <span style={{ color: '#BAF6B8' }}>{JSON.parse(request.sheet)[0].status}</span></div>
                      </div>
                      <div className="px-3">
                        <div className="seller-dashboard-card-divider pb-3 pt-4 px-2">
                          <span className="fs-6 text-dark mb-1 dashboard-seller-body-heading">Product Name</span>
                          <p className="text-dark dashboard-seller-body-para">{JSON.parse(request.sheet)[0].name}</p>
                        </div>
                        <div className="seller-dashboard-card-divider pb-3 pt-4 d-flex justify-content-between">
                          <div className="d-flex flex-column">
                            <span className="text-dark dashboard-seller-body-heading">Quantity</span>
                            <span className="dashboard-seller-body-para">{JSON.parse(request.sheet)[0].quantity}</span>
                          </div>
                          <div className="d-flex flex-column">
                            <span className="text-dark dashboard-seller-body-heading">Unit</span>
                            <span className="text-dark dashboard-seller-body-para">{JSON.parse(request.sheet)[0].piece}</span>
                          </div>
                        </div>
                        <div className="seller-dashboard-card-divider pb-3 pt-4 d-flex justify-content-between">
                          <div className="d-flex flex-column">
                            <span className="fs-6 text-dark dashboard-seller-body-heading">Approx. Price</span>
                            <span className="fs-6 text-dark dashboard-seller-body-para">{JSON.parse(request.sheet)[0].price}</span>
                          </div>
                          <div className="d-flex flex-column">
                            <span className="fs-6 text-dark dashboard-seller-body-heading">Category</span>
                            <span className="fs-6 text-dark dashboard-seller-body-para">{JSON.parse(request.sheet)[0].cat}</span>
                          </div>
                        </div>
                        <div className="py-3 pt-2">
                          <span className="fs-6 text-dark dashboard-seller-body-heading">Description</span>
                          <p className="text-dark dashboard-seller-body-para">{JSON.parse(request.sheet)[0].des}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>


            </div>
            </div>

            <div className="col-12 col-xxl-4 mt-xxl-5 mt-4 order-xxl-0 order-last">
              <div className="border rounded">
            <div className="pt-4 pb-4 px-5 d-flex justify-content-between align-items-center">
                  <span className="fs-5 fw-bolder" style={{ color: '#252525' }}>Recent Messages</span>
                </div>
            {recentMessages.map((message, index) => (
            <div
              key={index}
              className="card"
              style={{ backgroundColor: '#F0F0F0', padding: '1px', margin: '7px', borderRadius: '5px' }}
            >
              <div className="card-body d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column justify-content-between">
                  <h6
                    style={{ color: '#150B3D', fontSize: '16px', fontWeight: 'bold' }}
                  >
                    {message.sender_name}
                  </h6>
                  {message.type === "image" ? (
                    <a
                      download={message.message}
                      href={message.message}
                      target="_blank"
                      rel="noopener noreferrer"
                      title="ImageName"
                    >
                      <img src={message.message} height="auto" width="200px" />
                    </a>
                  ) : message.type === "file" ? (
                    <a
                      download={message.message}
                      href={message.message}
                      target="_blank"
                      rel="noopener noreferrer"
                      title="FileName"
                    >
                      <p style={{ color: "blue" }} className="mb-0">
                        {message.message.split("/").pop()} {/* Extracts file name */}
                      </p>
                    </a>
                  ) : message.type === "audio" ? (
                    <>
                      <audio
                        controls
                        src={message.message}
                        onLoadedMetadata={(e) => handleLoadedMetadata(e, message.id)}
                        onTimeUpdate={(e) => handleTimeUpdate(e)}
                        data-duration="0:00"
                        data-current-time="0:00"
                      >
                        Your browser does not support the audio element.
                      </audio>
                    </>
                  ) : message.type === "imageText" ? (
                    <p
                      style={{ color: "black" }}
                      className="mb-0"
                      dangerouslySetInnerHTML={{ __html: message.message }}
                    ></p>
                  ) : (
                    <p
                      style={{ color: "black" }}
                      className="mb-0"
                      dangerouslySetInnerHTML={{ __html: makeLinksClickable(message.message) }}
                    ></p>
                  )}
                </div>
                <div className="d-flex flex-column align-items-center">
                  <small style={{ color: "#AAA6B9" }}>
                    {message?.date?.split(" ")[0] || ""}
                  </small>
                </div>
              </div>
            </div>
          ))}
          </div>

          </div>
            
            
          {/* </div> */}

          {/* <div className=" mb-4 mt-5 border rounded"> */}

          {/* </div> */}

          {/* <div className="row mb-4"> */}
          
          {/* </div> */}
        </div>
      </div>
      </div>
      {/* <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <div className="row">
              {
                allPackages.map(item => (
              <div className="col">
                {item}
              </div>
                ))
              }
            </div>
            {userType == "" ? <>
            </> : <>
              <section id="main-content">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-title">
                      <h3>Dashboard</h3>
                      <br />
                      <h4> E-Wallet ID : {uid}</h4>
                      <br />
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="card bg-primary">
                            <div className="stat-widget-six">
                              <div className="stat-icon">
                                <i className="ti-stats-up"></i>
                              </div>
                              <div className="stat-content">
                                <div className="text-left dib">
                                  <div className="stat-heading">Available Balance</div>
                                  <div className="stat-text">{new Intl.NumberFormat('en-pk').format(balance)}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="card bg-danger">
                            <div className="stat-widget-six">
                              <div className="stat-icon">
                                <i className="ti-bolt-alt"></i>
                              </div>
                              <div className="stat-content">
                                <div className="text-left dib">
                                  <div className="stat-heading">Last Debit</div>
                                  <div className="stat-text">{new Intl.NumberFormat('en-pk').format(debit)}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="card bg-success">
                            <div className="stat-widget-six">
                              <div className="stat-icon">
                                <i className="ti-stats-up"></i>
                              </div>
                              <div className="stat-content">
                                <div className="text-left dib">
                                  <div className="stat-heading">Last Credit </div>
                                  <div className="stat-text">{new Intl.NumberFormat('en-pk').format(credit)}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <Link to="/topup">
                            <div className="card bg-success">
                              <div className="stat-widget-six">
                                <div className="stat-icon">
                                  <i className="ti ti-plus"></i>
                                </div>
                                <div className="stat-content">
                                  <div className="text-left dib">
                                    <div className="stat-heading">Add Money</div>
                                    <div className="stat-text"><br /></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>

                      </div>
                      <br />
                      <br />
                    </div>
                    <section id="main-content">
                      <div className="row">
                        <div

                          className="card col-lg-12 col-sm-12 col-md-12"
                        >
                          <center>
                            <h4 className="my-2">Latest Buy Requests</h4>
                          </center>{" "}

                          <div style={{ height: "630px", overflowY: "auto" }} className="card-body">
                            <div className="table-responsive">
                              <table className="table table-hover ">
                                <thead>
                                  <tr>
                                    <th>#</th>

                                    <th>Name</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Unit</th>
                                    <th>Description</th>
                                    <th>Time</th>


                                    <th>Controls</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {lead.length === 0 ? (
                                    <></>
                                  ) : (
                                    <>
                                      {lead.map((v, i) => (

                                        <tr>
                                          <td>{v.id}</td>
                                          <td>{JSON.parse(v.sheet)[0].name} </td>
                                          <td>{JSON.parse(v.sheet)[0].qty} </td>
                                          <td>{JSON.parse(v.sheet)[0].price} </td>
                                          <td>{JSON.parse(v.sheet)[0].piece} </td>
                                          <td>{JSON.parse(v.sheet)[0].des} </td>
                                          <td>{v.time} </td>

                                          <td>
                                            <button
                                              style={{ borderRadius: '14px' }}
                                              className="btn-primary"
                                              onClick={() => {
                                                checkvalidity(v.lead_id)

                                              }}>
                                              Send Quote
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </section></>}

          </div>
        </div>
      </div> */}
    </div>
  );
}
export default Mainpage;

import React, { useEffect, useState } from "react";
import axios from "axios";

const Breadcrumbs = ({ categoryId, slug = null }) => {
  const [categories, setCategories] = useState([]);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const fetchBreadcrumbs = async () => {
      try {
        const response = await axios.get(
          `/mainsite/get_parent_categories.php?id=${categoryId}&slug=${slug}`
        );
        if (response.data && response.data.categories) {
          setCategories(response.data.categories);
        }

        // Check for product details in URL
        const urlParams = new URLSearchParams(window.location.search);
        const productId = urlParams.get("id");
        const productName = urlParams.get("s");
        if (productId && productName) {
          setProduct({ id: productId, name: productName });
        }
      } catch (error) {
        console.error("Error fetching breadcrumbs:", error);
      }
    };

    fetchBreadcrumbs();
  }, [categoryId, slug]);

  return (
    <div className="section-box py-3 d-none d-md-block">
      <nav aria-label="breadcrumb">
        <div className="container">
          <ol
            className="breadcrumb bg-white rounded px-2 py-3"
            style={{ "--bs-breadcrumb-divider": '">"' }} // Custom separator
          >
            <li
              className={`breadcrumb-item text-truncate font-weight-bold`}
              style={{ maxWidth: "200px" }}
            >
              <a style={{ color: "#1a9cb7" }} href="/">
                Home
              </a>
            </li>
            {categories.map((category) => {
              const isActiveCategory = window.location.pathname.includes(
                `/sub/${category.bread_crumb}`
              );

              // Determine classes and styles dynamically
              const itemClass = `breadcrumb-item font-weight-bold ${
                isActiveCategory ? "text-muted" : "text-truncate text-muted"
              }`;
              const itemStyle = isActiveCategory ? {} : { maxWidth: "200px" };

              return (
                <li key={category.id} className={itemClass} style={itemStyle}>
                  {isActiveCategory ? (
                    <p>{category.title}</p>
                  ) : (
                    <a
                      style={{ color: "#1a9cb7" }}
                      href={`/sub/${category.bread_crumb}`}
                    >
                      {category.title}
                    </a>
                  )}
                </li>
              );
            })}
            {product && (
              <li className="breadcrumb-item font-weight-bold text-muted">
                {product.name}
              </li>
            )}
          </ol>
        </div>
      </nav>
    </div>
  );
};

export default Breadcrumbs;

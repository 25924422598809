import Nav from '../../../navbar';
import { useState, useEffect } from 'react';
import axios from 'axios';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  PlusCircleFill,
  PlusCircleDotted,
  Trash,
  Trash3,
} from 'react-bootstrap-icons';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


function Matteradditionaldetails() {
  const navigate = useNavigate();
  const [alert2, setAlert2] = useState(false);
  const [darw, setDarw] = useState(true);
  const [profile, setAllProfile] = useState([
    {
      description: '',
      companyid: '',
      impexpno: '',
      companybranch: '',
      bank: '',
      taxno: '',
      ntnno: '',
      productionunits: '',
      mainHeadings: '',
      mainParagraphs: '',
      affiliatemembership: '',
      compbranch: '',
      ownercnic: '',
      infrastructuresize: '',
      arnno: '',
      fromday: '',
      today: '',
      fromtime: '',
      totime: '',
      states: '',
      includecities: '',
      excludecities: '',
      tradetocities: '',
      tradefromcities: '',
      modeofpay: '',
      shipmentmode: '',
    },
  ]);

  const [uid, setUid] = useState('');
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
      getProfile(v[0].id);
    }
  }, []);
  const changeAray = (i, changes) => {
    const oldArr = [...profile];
    const oldObj = oldArr[i];
    oldArr[i] = { ...oldObj, ...changes };
    setAllProfile(oldArr);
  };


  const updateStatus = async () => {
    const data = new FormData();
    data.append('uid', uid);
    data.append('role', "seller");
    axios({
      method: 'post',
      url: '/pro/profile/update_user_role.php',
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' },
    }).then(s => {

      if (s.data[0].msg === 'pass') {
        localStorage.setItem('USER', JSON.stringify(s.data))

        navigate('/seller');
      }
    }).catch(error => {
      console.log("error occured", error.message);
    })
  }

  const checkTextInput = (data) => {
    console.log("===========================");
    console.log(profile[0]);
    const imexp = JSON.stringify(data[0].impexpno);
    const intaxno = JSON.stringify(data[0].taxno);
    const ntno = JSON.stringify(data[0].ntnno);
    const productionunit = JSON.stringify(data[0].productionunits);
    const mainHeading = JSON.stringify(data[0].mainHeadings);
    const mainParagraph = JSON.stringify(data[0].mainParagraphs);



    // if (imexp.length < 4 || imexp.length >= 80 ) {
    //   alert("Enter Valid Import Exprt No. Between 4 to 80 characters.")
    //   return;
    // }
    // if (intaxno.length < 4 || intaxno.length >= 80) {
    //   alert('Enter Valid Income Tax No. Between 4 to 80 characters.')
    //   return;
    // }

    // if (ntno.length < 4  || ntno.length >= 80) {
    //   alert('Enter Valid NTN No.Between 4 to 80 characters.');
    //   return;
    // }
    if (productionunit.length < 3 || productionunit.length >= 15) {
      alert('Enter Valid Production Units. Between 1 to 15 characters.');
      return;
    }
    updateProfile();
  }
  const getProfile = async uid => {
    const data = new FormData();
    data.append('uid', uid);
    data.append('array', JSON.stringify(profile));

    axios({
      method: 'post',
      url: '/pro/profile/profile_view_company.php',
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(s => {
        console.log("company profile ", s.data)
        if (s.data[0].code == 'old') {
          setAllProfile(JSON.parse(s.data[0].aray_com));
          //setAlert2(true)
        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  const updateProfile = async () => {
    const data = new FormData();
    data.append('uid', uid);
    data.append('array', JSON.stringify(profile));
    axios({
      method: 'post',
      url: '/pro/profile/profile_company.php',
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(s => {
        console.log(s.data)
        if (s.data[0].code == 'old') {
          // setAllProfile(JSON.parse(s.data[0].aray));

          getProfile(uid)
          setAlert2(true)
          updateStatus(uid)

        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  const text_type = (input) => {
    // Regular expression to check if the string contains only alphabetic characters
    const regex = /^[A-Za-z\s]+$/;

    // Test the input against the regular expression
    return regex.test(input);

  }
  return (
    <div>
      <Nav setDarw={setDarw} darw={darw} />
      <div className={darw ? ["header-protected-screen padding-dashboard-table", "mobile-table padding-dashboard-table"].join(' ') : ["header-protected-screen-full-width padding-dashboard-table", "mobile-table padding-dashboard-table"].join(' ')}>
        <div className="container-fluid">
          <div className="row">
            <div className="container-fluid">

              <section id="main-content">



                <div className="col-md-12">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-title">
                        <h3 style={{color:"#555"}} >
                          <b>Business Profile</b>
                        </h3>
                      </div>

                      <div className="row">
                        <label style={{ color: "rgb(253, 150, 54)" }}>Working Days</label>
                        <div
                          style={{ marginBottom: '20px' }}
                          className="col-md-5 form-group">
                          <label>From (Optional)</label>
                          <select
                            value={profile[0].fromday}
                            onChange={v => {
                              changeAray(0, { fromday: v.target.value });
                            }}
                            className="form-select border rounded border-dark">
                            <option>Monday</option>
                            <option>Tuesday</option>
                            <option>Wednesday</option>
                            <option>Thursday</option>
                            <option>Friday</option>
                            <option>Saturday</option>
                            <option>Sunday</option>
                          </select>
                        </div>
                        <div
                          // style={{ marginBottom: '20px' }}  style={{ marginTop: '38px' }}
                          className="col-md-2 form-group mt-2  mt-sm-5 mb-1">
                          <h5 className="text-center" >
                            To
                          </h5>
                        </div>
                        <div
                          style={{ marginBottom: '20px' }}
                          className="col-md-5 form-group">
                          <label>To (Optional)</label>
                          <select
                            value={profile[0].today}
                            onChange={v => {
                              changeAray(0, { today: v.target.value });
                            }}
                            name="selector1"
                            id="selector1"
                            className="form-select border rounded border-dark">

                            <option>Monday</option>
                            <option>Tuesday</option>
                            <option>Wednesday</option>
                            <option>Thursday</option>
                            <option>Friday</option>
                            <option>Saturday</option>
                            <option>Sunday</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          style={{ marginBottom: '20px' }}
                          className="col-md-5 form-group">
                          <input type="time"
                            value={profile[0].fromtime}
                            onChange={v => {
                              changeAray(0, { fromtime: v.target.value });
                            }}
                            name="selector1"
                            id="selector1"
                            className="form-control border rounded border-dark" />


                        </div>
                        <div
                          style={{ marginBottom: '20px' }}
                          className="col-md-2 form-group">
                          <h5 className="text-center" style={{ marginTop: '5px' }}>
                            To
                          </h5>
                        </div>
                        <div
                          style={{ marginBottom: '20px' }}
                          className="col-md-5 form-group">

                          <input type="time"
                            value={profile[0].totime}
                            onChange={v => {
                              changeAray(0, { totime: v.target.value });
                            }}
                            name="selector1"
                            id="selector1"
                            className="form-control border rounded border-dark" />


                        </div>
                      </div>

                      <div className="row">
                        <div style={{ marginBottom: '20px' }} className="col-sm-4">
                          <label>No. Of Production Units <span className='text-danger font-bold'>*</span></label>
                          <input
                            type="text"
                            className="form-control border rounded border-dark"
                            placeholder="Enter No. of Production Units"
                            maxLength={2} 
                            value={profile[0].productionunits}
                            onChange={v => {

                              if (!text_type(v.target.value)) {
                                changeAray(0, { productionunits: v.target.value });
                              } else if (v.target.value == "") {
                                changeAray(0, { productionunits: v.target.value });
                              }

                            }}
                          />
                        </div>

                        <div style={{ marginBottom: '20px' }} className="col-sm-4">
                          <label>Estore Main Heading <span className='text-danger font-bold'>*</span></label>
                          <input
                            type="text"
                            className="form-control border rounded border-dark"
                            placeholder="Enter No. of Production Units"
                            maxLength={50} 
                            value={profile[0].mainHeadings}
                            onChange={v => {
                                changeAray(0, { mainHeadings: v.target.value });
                            }}
                          />
                        </div>

                        <div style={{ marginBottom: '20px' }} className="col-sm-4">
                          <label>Estore Main Tag Line <span className='text-danger font-bold'>*</span></label>
                          <input
                            type="text"
                            className="form-control border rounded border-dark"
                            placeholder="Enter No. of Production Units"
                            maxLength={100} 
                            value={profile[0].mainParagraphs}
                            onChange={v => {
                                changeAray(0, { mainParagraphs: v.target.value });
                            }}
                          />
                        </div>

                        <div
  style={{ marginBottom: '20px' }}
  className="col-md-12 form-group">
  <label>About us</label>
  <textarea
    maxLength={200}
    rows={5}
    type="text"
    className="form-control border rounded border-dark"
    placeholder="Write 50 - 200 Letters About Company"
    value={profile[0]?.description || ""} // Use value instead of children
    onChange={v => {
      changeAray(0, { description: v.target.value });
    }}
  />
</div>

                      </div>



                      <div
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center',
                        }}
                        className="form-group">
                        <button
                          className="px-5 py-2"
                          style={{
                          // bottom: '90px',
                          // zIndex: 1200,
                          // right: '30px',
                          borderRadius: '30px',
                          backgroundColor: '#2C99A1',
                          border: '2px solid #2C99A1',
                          color: 'white',
                          // padding: '10px 20px',
                          // fontSize: '16px',
                          // borderRadius: '5px',
                          cursor: 'pointer',
                          outline: 'none',
                          boxShadow: 'rgb(204, 204, 204) 0px 0px 17px 6px',
                          fontSize: '16px',
                          fontWeight: 500
                          // transition: 'all 0.3s ease',
                          // boxShadow: '0 0 10px #45ACB3, 0 0 20px #45ACB3, 0 0 30px #45ACB3'
                        }}
                          onClick={() => {
                            checkTextInput(profile)
                          }}>
                          Save Business Profile
                        </button>
                        {alert2 ? (
                          <>
                            {' '}
                            <SweetAlert
                              success
                              confirmBtnText="Thanks !"
                              confirmBtnBsStyle="success"
                              timeout={2000}
                              title="Company Profile Saved"
                              onConfirm={() => {
                                setAlert2(!alert2);
                                navigate('/seller');
                              }}
                              onCancel={() => {
                                setAlert2(!alert2);
                                navigate('/seller');
                              }}
                              focusCancelBtn>
                              <br />
                            </SweetAlert>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Matteradditionaldetails;

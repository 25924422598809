import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Nav from "./navbar";
import axios from "axios";

function FacebookIntegrationForm() {
  const [pageName, setPageName] = useState("");
  const [pageId, setPageId] = useState("");
  const [pageLink, setPageLink] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [uid, setUid] = useState("");
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [isFBInitialized, setIsFBInitialized] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("USER"));
    if (user) {
      setUid(user[0].id);
    }

    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    if (id) {
      setEditMode(true);
      fetchPageDetails(id);
    }

    console.log("Initializing Facebook SDK...");
    window.FB.init({
      appId: "2816878121820045",
      cookie: true,
      xfbml: true,
      version: "v21.0",
    });
    setIsFBInitialized(true);

    (function (d, s, id) {
      let fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        console.log("Facebook SDK script already loaded.");
        return;
      }
      let js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      js.onload = () => console.log("Facebook SDK script loaded.");
      js.onerror = () => console.error("Failed to load Facebook SDK script.");
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, [location]);

  const fetchPageDetails = async (id) => {
    try {
      const response = await axios.post("/pro/facebook/getPageDetails.php", { id }, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.data.success) {
        const { page_name, page_id, page_link, access_token } = response.data.page;
        setPageName(page_name);
        setPageId(page_id);
        setPageLink(page_link === null ? '' : page_link);
        setAccessToken(access_token);
      } else {
        console.log("Failed to fetch page details: " + response.data.message);
      }
    } catch (error) {
      console.log("Error fetching page details:", error);
    }
  };

  const handleFacebookLogin = () => {
    if (!isFBInitialized) {
      alert("Facebook SDK is not initialized yet. Please wait.");
      return;
    }

    window.FB.login(
      function (response) {
        if (response.authResponse) {
          const userAccessToken = response.authResponse.accessToken;
  
          // Send the short-lived user token to the server
          axios
            .post("/pro/facebook/savePages.php", {
              user_id: uid,
              access_token: userAccessToken, // Only short-lived token is sent
            })
            .then((res) => {
              if (res.data.success) {
                alert("Pages connected successfully!");
                navigate("/facebookListing");
              } else {
                alert("Failed to connect pages: " + res.data.message);
              }
            })
            .catch((err) => {
              console.error("Error connecting pages:", err);
              alert("An error occurred while connecting pages.");
            });
        } else {
          alert("Facebook login failed or permissions not granted.");
        }
      },
      {
        scope: "pages_show_list,business_management,pages_read_engagement,pages_manage_posts,pages_manage_metadata",
      }
    );
  };  

  const validateForm = () => {
    const errors = {};
    if (!pageName.trim()) errors.pageName = "Page Name is required.";
    if (!pageId.trim()) errors.pageId = "Page ID is required.";
    if (!accessToken.trim()) errors.accessToken = "Access Token is required.";
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      alert("Please fill in all required fields.");
      return;
    }

    const formData = {
      user_id: uid,
      page_name: pageName,
      page_id: pageId,
      page_link: pageLink,
      access_token: accessToken,
    };

    if (editMode) {
      formData.id = new URLSearchParams(location.search).get("id");
    }

    setLoading(true);
    try {
      const response = await axios.post(
        editMode ? "/pro/facebook/updatePage.php" : "/pro/facebook/connect.php",
        formData, {
            headers: { "Content-Type": "multipart/form-data" },
          }
      );

      if (response.data.success) {
        alert(editMode ? "Page updated successfully!" : "Page connected successfully!");
        navigate("/facebookListing");
      } else {
        alert("Failed to save page: " + response.data.message);
      }
    } catch (error) {
      alert("Error saving page.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Nav />
      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <section id="main-content">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h3>{editMode ? "Update All Facebook Pages Via Facebook Login" : "Connect All Facebook Pages Via Facebook Login"}</h3>
                  </div>
                  <div className="card-body p-0">
                    <div className="basic-elements">
                      <button
                        onClick={handleFacebookLogin}
                        className="btn btn-secondary"
                        disabled={!isFBInitialized}
                      >
                        {isFBInitialized ? "Connect All Pages" : "Loading Facebook..."}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h3>{editMode ? "Edit Facebook Page Details" : "Connect Facebook Page Manually"}</h3>
                  </div>
                  <div className="card-body p-0">
                    <div className="basic-elements">
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <label>Facebook Page Name <span className="text-danger">*</span></label>
                          <input
                            className="form-control"
                            type="text"
                            value={pageName}
                            onChange={(e) => setPageName(e.target.value)}
                            placeholder="Enter Facebook Page Name"
                          />
                          {error.pageName && <small className="text-danger">{error.pageName}</small>}
                        </div>
                        <div className="col-md-6 form-group">
                          <label>Facebook Page ID <span className="text-danger">*</span></label>
                          <input
                            className="form-control"
                            type="text"
                            value={pageId}
                            onChange={(e) => setPageId(e.target.value)}
                            placeholder="Enter Facebook Page ID"
                            readOnly={editMode && pageId != '' ? "readonly" : ""}
                          />
                          {error.pageId && <small className="text-danger">{error.pageId}</small>}
                        </div>
                        <div className="col-md-6 form-group">
                          <label>Facebook Page Link</label>
                          <input
                            className="form-control"
                            type="text"
                            value={pageLink}
                            onChange={(e) => setPageLink(e.target.value)}
                            placeholder="Enter Facebook Page Link"
                          />
                          {error.pageLink && <small className="text-danger">{error.pageLink}</small>}
                        </div>
                        <div className="col-md-6 form-group">
                          <label>Access Token <span className="text-danger">*</span></label>
                          <input
                            className="form-control"
                            type="text"
                            value={accessToken}
                            onChange={(e) => setAccessToken(e.target.value)}
                            placeholder="Enter Access Token"
                          />
                          {error.accessToken && <small className="text-danger">{error.accessToken}</small>}
                        </div>
                      </div>
                      <button
                        onClick={handleSubmit}
                        className="btn btn-primary"
                        disabled={loading}
                      >
                        {loading ? "Saving..." : editMode ? "Update Page" : "Connect Page"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default FacebookIntegrationForm;

import Nav from "../../../navbar";
import { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  PlusCircleFill,
  PlusCircleDotted,
  Trash,
  Trash3,
} from "react-bootstrap-icons";

function Mattersheetdetails() {

  const navigate = useNavigate();


  const [alert2, setAlert2] = useState("");
  const [cities, setCities] = useState([]);
  const [currentcity, setCurrentcity] = useState('');
  const [cityindex, setCityindex] = useState('');
  const [username, setUserName] = useState("");
  const [useremail, setUserEmail] = useState("");
  const [userphone, setUserPhone] = useState("");
  const [usercompany, setUserCompany] = useState("");
  const [cityIndex, setCityIndex] = useState("");
  const [darw, setDarw] = useState(true);
  const [year, setyear] = useState([
    2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013,
    2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001,
    2000, 1999, 1998, 1997, 1996, 1995, 1994, 1993, 1992, 1991, 1990, 1989,
    1988, 1987, 1986, 1985, 1984, 1983, 1982, 1981, 1980, 1979, 1978, 1977,
    1976, 1975, 1974, 1973, 1972, 1971, 1970, 1969, 1968, 1967, 1966, 1965,
    1964, 1963, 1962, 1961, 1960, 1959, 1958, 1957, 1956, 1955, 1954, 1953,
    1952, 1951, 1950
  ])
  const [profile, setAllProfile] = useState([
    {
      name: "",
      designation: "",
      email: "",
      phone: "",
      companyname: "",
      city: "Karachi",
      address: "",
      code: "",
      website: "",
      companyphone: "",
      year: "",
      employees: "",
    }
  ]);
  const [uid, setUid] = useState("");
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));

    if (v === null) {
    } else {
      setUid(v[0].id);
      getProfile(v[0].id);
      setUserName(v[0].name);
      setUserEmail(v[0].email);
      setUserPhone(v[0].phone);
      setUserCompany(v[0].cname);
      changeAray(0, { name: v[0].name });
      changeAray(0, { email: v[0].email });
      changeAray(0, { phone: v[0].phone });
      changeAray(0, { companyname: v[0].cname });
      getCities();
    }
  }, []);
  const getCities = async () => {
    const data = new FormData();

    axios({
      method: "post",
      url: "/pro/auth/cities_list.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("cities list", s.data)
        setCities(s.data)

      })
      .catch((s) => {

      });


  };
  const changeAray = (i, changes) => {
    const oldArr = [...profile];
    const oldObj = oldArr[i];
    oldArr[i] = { ...oldObj, ...changes };
    setAllProfile(oldArr);
  };

  const getProfile = async (uid) => {
    const data = new FormData();
    data.append("uid", uid);
    data.append("array", JSON.stringify(profile));

    axios({
      method: "post",
      url: "pro/profile/profile_view_personal.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("profile page", s.data);
        if (s.data[0].code == "old") {
          setAllProfile(JSON.parse(s.data[0].aray));
          console.log(JSON.parse(s.data[0].aray)[0].city)
          setCityIndex(JSON.parse(s.data[0].aray)[0].city)
          console.log("hi", (s.data[0].aray)[0].city)
          //changeAray(0, { city: JSON.parse(s.data[0].aray)[0].city });
        }
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };
  const updateProfile = async () => {

    const data = new FormData();
    data.append("uid", uid);
    data.append("array", JSON.stringify(profile));

    axios({
      method: "post",
      url: "/pro/profile/profile_personal.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log(s.data)
        if (s.data[0].code === "update") {
          setAllProfile(JSON.parse(s.data[0].aray));
          setAlert2(true)
        }
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  const text_type = (input) => {
    // Regular expression to check if the string contains only alphabetic characters
    const regex = /^[A-Za-z\s]+$/;

    // Test the input against the regular expression
    return regex.test(input);

  }

  const ValidateEmail = (inputText) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (inputText.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  }


  return (<div>
    <Nav setDarw={setDarw} darw={darw} />
    <div 
      //  style={{padding: '1rem 3rem'}}
      className={darw ? ["header-protected-screen padding-dashboard-table", "mobile-table padding-dashboard-table"].join(' ') : ["header-protected-screen-full-width padding-dashboard-table", "mobile-table padding-dashboard-table"].join(' ')}>
      {/* <div className="content-wrap"> */}
      {/* <div className="main"> */}
      <div className="container-fluid">
        <div className="row">


          {/* <!-- /# column --> */}
          {/* <div className="card">
              <div className="card-title">
                <div className="row">
                  <h2>Personal Information</h2>
                </div>
              </div>
            </div> */}
          {/* <!-- /# row --> */}
          <section id="main-content">
              {/* <div className="col-12 col-lg-12"> */}
              <div className="card">
                  <div className="card-title">
                    <h3 style={{color: '#555'}}>
                      <b>Personal Information </b>
                    </h3>
                  </div>

                  <div className="row">
                    <div
                      style={{ marginBottom: "20px" }}
                      className="col-md-6 form-group"
                    >
                      <label>Name </label> {/* {username} */}
                      <input
                        type="text"
                        maxLength={30}
                        color="red"
                        placeholder="Name"
                        className="form-control border rounded border-dark text-dark"
                        // value={profile[0].name }
                        value={username}
                        disabled
                      // onChange={(v) => {

                      //   if( text_type(v.target.value))
                      //     {
                      //       changeAray(0, { name: v.target.value });
                      //     }else if(v.target.value=="")
                      //       {
                      //         changeAray(0, { name: v.target.value });
                      //       }


                      //   // changeAray(0, { name: v.target.value });
                      // }}
                      //disabled
                      />
                    </div>

                    {/* <div style={{ marginBottom: "20px" }} className="col-md-6">
                        <label>Designation (Optional)</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Your Designation"
                          value={profile[0].designation}
                          maxLength={30}
                          onChange={(v) => {
                            if( text_type(v.target.value))
                              {
                                changeAray(0, { designation: v.target.value });
                              }else if(v.target.value=="")
                                {
                                  changeAray(0, { designation: v.target.value });
                                }
                            // changeAray(0, { designation: v.target.value });
                          }}
                        />
                      </div> */}
                       <div
                      style={{ marginBottom: "20px" }}
                      className="col-md-6 grid_box1"
                    >
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control border rounded border-dark text-dark"
                        placeholder="Enter your Email"
                        disabled
                        value={useremail}
                      //   value={profile[0].email}
                      // onChange={(v) => {
                      //   changeAray(0, { email: v.target.value });
                      // }}
                      //disabled
                      />
                    </div>
                  </div>

                  <div className="row">
                   

                    <div style={{ marginBottom: "20px" }} className="col-md-6">
                      <label>Contact Number</label>
                      <input
                        type="text"
                        maxLength={11}
                        className="form-control border rounded border-dark text-dark"
                        placeholder="Enter Contact Number"
                        value={userphone}
                        disabled
                        // value={profile[0].phone}
                        onChange={(v) => {
                          if (!text_type(v.target.value)) {
                            changeAray(0, { phone: v.target.value });
                          } else if (v.target.value == "") {
                            changeAray(0, { phone: v.target.value });
                          }




                        }}
                      //disabled
                      />
                      </div>
                    </div>
                  </div>
              {/* </div> */}
          </section>
          <section id="main-content">
                <div className="card">
                  <div className="card-title">
                    <h3 style={{color: '#555'}}>
                      <b>Company Information</b>
                    </h3>
                  </div>

                  <div className="row">
                    <div
                      style={{ marginBottom: "20px" }}
                      className="col-md-6 grid_box1"
                    >
                      <label>Company Name </label>
                      <input
                        disabled
                        type="text"
                        className="form-control border rounded border-dark  text-dark"
                        maxLength={30}
                        placeholder="Enter your Company Name"
                        value={usercompany}
                        //value={profile[0].companyname}
                        onChange={(v) => {


                          if (text_type(v.target.value)) {
                            changeAray(0, { companyname: v.target.value });
                          } else if (v.target.value == "") {
                            changeAray(0, { companyname: v.target.value });
                          }
                        }}
                      //disabled
                      />
                    </div>

                    <div style={{ marginBottom: "20px" }} className="col-sm-6">
                      <label>City   </label>
                      <select
                        value={cityIndex}
                        onChange={(v) => {
                          changeAray(0, { city: v.target.value });
                          setCityIndex(v.target.value);
                        }}
                        name="selector1"
                        id="selector1"
                        className="form-select border rounded border-dark"
                      >
                        <option>Select City</option>
                        {
                          cities.map((v, i) => (
                            <>
                              <option value={i} >{v.city}</option>
                            </>
                          ))
                        }
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    <div style={{ marginBottom: "20px" }} className="col-sm-6">
                      <label>Address *</label>
                      <input
                        type="text"
                        className="form-control border rounded border-dark"
                        maxLength={100}

                        placeholder="Enter Your Address"
                        value={profile[0].address}
                        onChange={(v) => {


                          changeAray(0, { address: v.target.value });

                        }}
                      />
                    </div>
                    <div
                      style={{ marginBottom: "20px" }}
                      className="col-md-6 grid_box1"
                    >
                      <label>Postal / Zip Code  (Optional) </label>
                      <input
                        type="text"
                        className="form-control border rounded border-dark"
                        maxLength={10}
                        placeholder="Postal / Zip Code"
                        value={profile[0].code}
                        onChange={(v) => {

                          if (!text_type(v.target.value)) {
                            changeAray(0, { code: v.target.value });
                          } else if (v.target.value == "") {
                            changeAray(0, { code: v.target.value });
                          }

                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div style={{ marginBottom: "20px" }} className="col-md-6">
                      <label>Website URL  (Optional)</label>
                      <input
                        type="text"
                        className="form-control border rounded border-dark"
                        placeholder=" Website"
                        maxLength={180}
                        value={profile[0].website}
                        onChange={(v) => {
                          changeAray(0, { website: v.target.value });
                        }}
                      />
                    </div>
                    <div style={{ marginBottom: "20px" }} className="col-md-6">
                      <label>Company Contact No</label>
                      <input
                        type="text"
                        className="form-control border rounded border-dark"
                        maxLength={11}
                        placeholder="Enter Contact Number"
                        value={profile[0].companyphone}
                        onChange={(v) => {

                          //changeAray(0, { companyphone: v.target.value });
                          if (!text_type(v.target.value)) {
                            changeAray(0, { companyphone: v.target.value });
                          } else if (v.target.value == "") {
                            changeAray(0, { companyphone: v.target.value });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      style={{ marginBottom: "20px" }}
                      className="col-md-6 grid_box1"
                    >
                      <label>Year of Establishment *</label>

                      <select onChange={(v) => {
                        changeAray(0, { year: v.target.value });
                      }} value={profile[0].year} className="form-select border rounded border-dark">
                        <option></option>
                        {
                          year.map((v) => (
                            <option>
                              {v}
                            </option>
                          ))

                        }
                      </select>
                      {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Year of Establishment"
                          value={profile[0].year}
                          onChange={(v) => {
                            changeAray(0, { year: v.target.value });
                          }}
                        /> */}
                    </div>
                    <div
                      style={{ marginBottom: "20px" }}
                      className="col-md-6 grid_box1"
                    >
                      <label>No. Of Employees *</label>

                      <select onChange={(v) => {
                        changeAray(0, { employees: v.target.value });
                       
                      }} value={profile[0].employees} className="form-select  border rounded border-dark">

                        <option></option>
                        <option>1-10</option>
                        <option>20-50</option>
                        <option>50-100</option>
                        <option>100+</option>



                      </select>
                      {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Year of Establishment"
                          value={profile[0].year}
                          onChange={(v) => {
                            changeAray(0, { year: v.target.value });
                          }}
                        /> */}
                    </div>

                  </div>
                </div>
          </section>

          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
            className="form-group"
          >
            <button
              className="px-5 py-1 mt-5"
              style={{
                // bottom: '90px',
                // zIndex: 1200,
                // right: '30px',
                borderRadius: '30px',
                backgroundColor: '#2C99A1',
                border: '2px solid #2C99A1',
                color: 'white',
                // padding: '10px 20px',
                // fontSize: '16px',
                // borderRadius: '5px',
                cursor: 'pointer',
                outline: 'none',
                boxShadow: 'rgb(204, 204, 204) 0px 0px 17px 6px',
                fontSize: '16px',
                fontWeight: 500
                // transition: 'all 0.3s ease',
                // boxShadow: '0 0 10px #45ACB3, 0 0 20px #45ACB3, 0 0 30px #45ACB3'
              }}
              onClick={() => {

                if (profile[0].city == "") {
                  alert("Enter City")

                } else if (profile[0].address == "") {
                  alert("Enter Address")

                } else if (profile[0].year == "") {
                  alert("Select Year")

                } else if (profile[0].employees == "") {
                  alert("Enter Numbers Of Employee ")

                } else {
                  updateProfile()
                }




              }}
            >
              Save
            </button>
            {alert2 ? (
              <>
                {" "}
                <SweetAlert
                  success
                  confirmBtnText="Thanks !"
                  confirmBtnBsStyle="success"
                  timeout={2000}
                  title="Profile Saved"
                  onConfirm={() => {
                    setAlert2(!alert2);
                    navigate('/additional')

                  }}

                  focusCancelBtn
                >
                  <br />
                </SweetAlert>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  </div>
  );
}
export default Mattersheetdetails;

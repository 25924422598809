
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import Load from "../components/landingpage/load";
import '../styles/header.css';

import { useParams } from 'react-router-dom';
import Header from "./header";
import Footer from "./footer";
import Breadcrumbs from "./breadcrumbs";
import Whatsapp from '../components/landingpage/whatsapp';
import { FaTshirt } from "react-icons/fa";
import { MdFastfood } from "react-icons/md";
import { MdElectricBolt } from "react-icons/md";
import { FaBuilding } from "react-icons/fa6";
import { MdOutlineComputer } from "react-icons/md";
import { MdLocalPharmacy } from "react-icons/md";
import { FaIndustry } from "react-icons/fa6";
import { FaHospitalAlt } from "react-icons/fa";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import Error404 from "../pages/Error404";

function Subcat() {

  const { slug } = useParams();
  const navigate = useNavigate();
  const [allcats, setAllcats] = useState([]);
  const [load, setLoad] = useState([]);

  const [title, setTitle] = useState([]);

  const SideBarCategories = [
    {
      id: "a3d2fa1b-1c5b-4f3e-bf75-193f698a380d",
      name: "Apparel & Garments",
      icon: "FaTshirt",
      link: "/sub/apparel-garments"
    },
    {
      id: "b2cfe557-1fdb-4e16-8f85-b9cd6cc4b0f3",
      name: "Food & Beverages",
      icon: "MdFastfood",
      link: "/sub/food-beverages"
    },
    {
      id: "d51969f3-5638-47f9-a0a9-b1fd8e17a7b2",
      name: "Electronics & Electrical",
      icon: "MdElectricBolt",
      link: "/sub/electronics-electrical"
    },
    {
      id: "c2ae7730-0b4f-42a3-bf60-b27d0152903b",
      name: "Building Construction",
      icon: "FaBuilding",
      link: "/sub/building-construction"
    },
    {
      id: "fe879c9a-02a7-4bc0-9c78-2bfbcc4b5e74",
      name: "Computer & IT Solutions",
      icon: "MdOutlineComputer",
      link: "/sub/computer-it-solutions"
    },
    {
      id: "f703e174-0fe7-4e52-9f98-3e7e436bba34",
      name: "Drugs & Pharmaceuticals",
      icon: "MdLocalPharmacy",
      link: "/sub/drugs-pharmaceuticals"
    },
    {
      id: "e57d2e93-b178-45f4-bbb2-e69ab5151c34",
      name: "Industrial Plants & Machinery",
      icon: "FaIndustry",
      link: "/sub/industrial-plants-machinery"
    },
    {
      id: "bb89a3c8-61b9-4cd0-b5b7-0dbce491dd25",
      name: "Hospital & Diagnostics",
      icon: "FaHospitalAlt",
      link: "/sub/hospital-diagnostics"
    }
  ];

  const iconMap = {
    FaTshirt: <FaTshirt color='#d1d5db' />,
    MdFastfood: <MdFastfood color='#d1d5db' />,
    MdElectricBolt: <MdElectricBolt color='#d1d5db' />,
    FaBuilding: <FaBuilding color='#d1d5db' />,
    MdOutlineComputer: <MdOutlineComputer color='#d1d5db' />,
    MdLocalPharmacy: <MdLocalPharmacy color='#d1d5db' />,
    FaIndustry: <FaIndustry color='#d1d5db' />,
    FaHospitalAlt: <FaHospitalAlt color='#d1d5db' />
  };
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };
  useEffect(() => {
    const a = localStorage.getItem("title")

    getServercats();
  }, []);

  const getServercats = async () => {

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get("id");

    setTitle(params.get("s"));


    const data = new FormData();
    data.append("slug", slug);

    axios({
      method: "post",
      url: "/mainsite/all_sub_by_name.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setAllcats(s.data);
        console.log("dat_sub_cat", s.data);


      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });




  };

  const getServercatsUpdate = async (id) => {
    const data = new FormData();

    data.append("id", id);

    axios({
      method: "post",

      url: "/mainsite/suball.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setAllcats(s.data);
        // console.log(s.data);
      })
      .catch((s) => {
        // console.log("here catch", s);
      });
  };
  return (
    <>
      <Header />
      <Whatsapp />
      <main className="main">
        <Breadcrumbs categoryId='' slug={slug} />
        {/* <div className="section-box">
    <div className="breadcrumbs-div">
      <div className="container">
        <ul className="breadcrumb">
          <li>
            <a className="font-xs color-gray-1000" href="index.html">
              Home
            </a>
          </li>
          <li>
            <a className="font-xs color-gray-500" href="shop-grid.html">
              Electronics
            </a>
          </li>
          <li>
            <a className="font-xs color-gray-500" href="shop-grid.html">
              Cell phone
            </a>
          </li>
          <li>
            <a className="font-xs color-graProducts Filtey-500" href="shop-grid.html">
              Accessories
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div> */}

        <div className="section-box shop-template">
          <div className="container">
            <div className="row">
            <div className="col-lg-3 mb-3"> {/*sidebar-border*/}
                <div className="mb-0 order-last order-sm-first" style={{ boxShadow: '0px 0px 17px 6px #ccc', borderRadius: '16px', backgroundColor: '#fff', height: 'max-content' , padding: '20px 8px 8px 8px'}}>
                  <div className="sidebar-head text-center mt-2" style={{ borderRadius: '16px', background: '#45ACB3' }}>
                    <h6 className="color-gray-900 sidebar-head-title py-3 fs-6 text-light font-bold">Product Categories</h6>
                  </div>
                  <div className="sidebar-content mt-5" style={{ backgroundColor: '#fff', cursor: 'pointer', borderRadius: '16px', marginBottom: '16px' }}>
                    {SideBarCategories.map(category => (
                      <span key={category.id} className='d-flex ml-2 mt-2' style={
                        {
                          // fontSize: '18px',
                          lineHeight: '36px',
                          color: '#020617',
                          display: 'inline-block',
                          color: '#555'
                        }
                      }>
                        {iconMap[category.icon] && <span className='mr-3'>{iconMap[category.icon]}</span>}
                        <span
                          onClick={() => {
                            localStorage.setItem("title", category.name);
                            window.location.assign(category.link);
                          }}
                            style={{
                              fontSize: '14px',
                              lineHeight: '32px',
                              color: '#020617',
                              display: 'inline-block'
                            }}
                          className='sidebar-font-size'
                        >
                          {category.name}
                        </span>
                      </span>
                    ))}
                    <Link to='/main'>
                    <span className='d-inline-block ml-4 mt-3 mb-0 fw-normal' style={{ color: '#FD9636', fontSize: '16px' }}>View All Categories</span>
        </Link> 
                  </div>
                </div>
              </div>
              <div className="col-lg-9 mb-3">
                {/* <div className="banner-ads-top mb-30">
                  <a href="shop-single-product-3.html">
                    <img src="/assetsss/imgs/page/shop/banner.png" alt="Ecom" />
                  </a>
                </div> */}

                {/* {true && <div className="container-lg">
          <div className="row"> */}
                {/* <div className="d-block d-lg-none">
                  <h2 className="text-dark fs-6 mb-3">Product Categories</h2>

                
                <ul className="d-flex gap-2 overflow-scroll bottom-nav-container container-sidebar-mobile-homepage">

                  {SideBarCategories.map((item, i) => (
                    <li
                      style={{ boxShadow: '0 5px 20px rgba(0, 0, 0, 0.11)' }}
                      className="px-3 py-4 card-slider-items-mobile nav-item bottom-nav-item border rounded my-2"
                      // whileHover={{ scale: 1.5}}
                      whileTap={{
                        scale: 0,

                        borderRadius: "100%"
                      }}
                    >
                      <a className="sidebar-mobile-widget" href="/buyer">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
                </div> */}
                {/* </div>
        </div>} */}
                {/* <div className="box-filters mt-0 pb-5 border-bottom">
            <div className="row">
              <div className="col-xl-2 col-lg-3 mb-10 text-lg-start text-center">
                <a
                  className="btn btn-filter font-sm color-brand-3 font-medium"
                  href="#ModalFiltersForm"
                  data-bs-toggle="modal"
                >
                  All Fillters
                </a>
              </div>
              <div className="col-xl-10 col-lg-9 mb-10 text-lg-end text-center">
                <span className="font-sm color-gray-900 font-medium border-1-right span">
                  Showing 1–16 of 17 results
                </span>
                <div className="d-inline-block">
                  <span className="font-sm color-gray-500 font-medium">
                    Sort by:
                  </span>
                  <div className="dropdown dropdown-sort border-1-right">
                    <button
                      className="btn dropdown-toggle font-sm color-gray-900 font-medium"
                      id="dropdownSort"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Latest products
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-light"
                      aria-labelledby="dropdownSort"
                      style={{ margin: 0 }}
                    >
                      <li>
                        <a className="dropdown-item active" href="#">
                          Latest products
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Oldest products
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Comments products
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="d-inline-block">
                  <span className="font-sm color-gray-500 font-medium">
                    Show
                  </span>
                  <div className="dropdown dropdown-sort border-1-right">
                    <button
                      className="btn dropdown-toggle font-sm color-gray-900 font-medium"
                      id="dropdownSort2"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-display="static"
                    >
                      <span>30 items</span>
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-light"
                      aria-labelledby="dropdownSort2"
                    >
                      <li>
                        <a className="dropdown-item active" href="#">
                          30 items
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          50 items
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          100 items
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="d-inline-block">
                  <a
                    className="view-type-grid mr-5 active"
                    href="shop-grid.html"
                  />
                  <a className="view-type-list" href="shop-list.html" />
                </div>
              </div>
            </div>
          </div> */}
                <div className="p-20 mb-3 order-last order-lg-last" style={{ boxShadow: '0px 0px 17px 6px #ccc', borderRadius: '16px', backgroundColor: '#fff', height: "100%" }}>

                  {
                    allcats.length > 1 ? "" : allcats.length == 1 ? allcats[0].child.length > 0 ? "" : <Error404 message="No product found" /> : ""
                  }

                  {

                    allcats.length > 1
                      ? allcats.map((v, i) => (
                        <>


                          <div className="col-xl-4 col-lg-3 col-6">
                            <div className="card-grid-style-3">
                              <div className="card-grid-inner">

                                <div className="image-box">

                                  <a href={"/sub/" + v.bread_crumbs}>
                                    <img
                                      style={{ height: "200px", width: "200px" }}
                                      className="text-center item-homepage-card-img"
                                      src={v.img}
                                      alt={v.title}
                                    />
                                  </a>
                                </div>
                                <div className="info-right text-cnter">

                                  <a
                                    className="color-brand-3"
                                    style={{
                                      textAlign: 'center',
                                      fontWeight: 400,
                                      fontSize: '1px', // No need for !important in inline styles
                                    }}
                                    href={"/sub/" + v.bread_crumbs}
                                  >
                                    {v.title}
                                  </a>


                                </div>
                              </div>
                            </div>
                          </div>

                        </>
                      ))
                      : <div className="row row-sm">
                        {allcats.length == 1
                          ? allcats[0].child.map((v, i) => (
                            <>
                              {/* new  start*/}
                              <div className="col-xl-3 col-lg-3 col-md-6 col-6">
                                {
                                  allcats[0].code != 'product' ? <>
                                    <div className="card-grid-style-3">
                                      <div className="card-grid-inner">

                                        <div className="image-box">


                                          <a href={"/sub/" + v.bread_crumbs}>
                                            <img
                                              style={{ height: "200px", width: "200px" }}
                                              className="text-center item-homepage-card-img"
                                              src={v.img}
                                              alt={v.title}
                                            />
                                          </a>
                                        </div>
                                        <div className="info-right">

                                          <a
                                            className="color-brand-3 card_text_color"
                                            href={"/sub/" + v.bread_crumbs}
                                          >
                                            {v.title}
                                          </a>


                                        </div>
                                      </div>
                                    </div></> : <>
                                    <div className="card-grid-style-3">
                                      <div className="card-grid-inner">

                                        <div className="image-box">

                                          <a href={"/product?id=" + v.id + "&s=" + v.name}>
                                            {/* <a href={"/sub/HERE"+v.bread_crumbs}> */}
                                            <img
                                              style={{ height: "200px", width: "200px" }}
                                              className="text-center item-homepage-card-img"
                                              src={v.img}
                                              alt={v.name}
                                            />
                                          </a>
                                        </div>
                                        <div className="info-right" >

                                          <a
                                            className="card_text_color"
                                            href={"/product?id=" + v.id + "&s=" + v.name}
                                          >
                                            {v.name}
                                          </a>


                                        </div>
                                      </div>
                                    </div></>
                                }



                              </div>
                              {/* new end */}

                            </>
                          ))
                          : <Load />}
                      </div>}



                </div>
                {/* <nav>
            <ul className="pagination">
              <li className="page-item">
                <a className="page-link page-prev" href="#" />
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link active" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  4
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  5
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  6
                </a>
              </li>
              <li className="page-item">
                <a className="page-link page-next" href="#" />
              </li>
            </ul>
          </nav> */}
              </div>
              {/* <div className="col-lg-3 order-last order-lg-first">
                <div className="sidebar-border mb-0">
                  <div className="sidebar-head">
                    <h6 className="color-gray-900">Product Categories</h6>
                  </div>
                  <div className="sidebar-content">
                    <ul className="list-nav-arrow">
                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Apparel & Garments"
                            );
                            window.location.assign(
                              "/sub/apparel-garments"
                            );
                          }}
                        >
                          Apparel & Garments
                        </a>
                      </li>
                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem("title", "Food & Beverages");
                            window.location.assign(
                              "/sub/food-beverages"
                            );
                          }}
                        >
                          Food & Beverages
                        </a>
                      </li>

                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              "Electronics & Electrical"
                            );
                            window.location.assign(
                              "/sub/electronics-electrical"
                            );
                          }}
                        >
                          Electronics & Electrical
                        </a>
                      </li>
                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              "Building Construction"
                            );
                            window.location.assign(
                              "/sub/building-construction"
                            );
                          }}
                        >
                          Building Construction
                        </a>
                      </li>
                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Computer & IT Solutions"
                            );
                            window.location.assign(
                              "/sub/computer-it-solutions"
                            );
                          }}
                        >
                          Computer & IT Solutions
                        </a>
                      </li>

                      <li style={{ cursor: "pointer" }}>
                        <a

                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              "Drugs & Pharmaceuticals"
                            );
                            window.location.assign(
                              "/sub/drugs-pharmaceuticals"
                            );
                          }}
                        >
                          Drugs & Pharmaceuticals
                        </a>
                      </li>


                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              "Industrial Plants & Machinery"
                            );
                            window.location.assign(
                              "/sub/industrial-plants-machinery"
                            );
                          }}
                        >
                          Industrial Plants & Machinery
                        </a>
                      </li>

                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              "Hospital & Diagnostics"
                            );
                            window.location.assign(
                              "/sub/hospital-diagnostics"
                            );
                          }}
                        >
                          Hospital & Diagnostics
                        </a>
                      </li>
                      <li style={{ cursor: "pointer", color: 'blue' }}>
                        <a
                          onClick={() => {
                            navigate("/main")
                          }}
                        >
                          All Categories
                        </a>
                      </li>


                    </ul>



                  </div>
                </div>



              </div> */}





            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="ModalFiltersForm"
          tabIndex={-1}
          aria-hidden="true"
          style={{ display: "none" }}
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content apply-job-form">
              <div className="modal-header">
                <h5 className="modal-title color-gray-1000 filters-icon">
                  Addvance Fillters
                </h5>
                <button
                  className="btn-close"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body p-30">
                <div className="row">
                  <div className="col-w-1">
                    <h6 className="color-gray-900 mb-0">Brands</h6>
                    <ul className="list-checkbox">
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" defaultChecked="checked" />
                          <span className="text-small">Apple</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Samsung</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Baseus</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Remax</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Handtown</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Elecom</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Razer</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Auto Focus</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Nillkin</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Logitech</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">ChromeBook</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="col-w-1">
                    <h6 className="color-gray-900 mb-0">Special offers</h6>
                    <ul className="list-checkbox">
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">On sale</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" defaultChecked="checked" />
                          <span className="text-small">FREE shipping</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Big deals</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Shop Mall</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                    </ul>
                    <h6 className="color-gray-900 mb-0 mt-40">Ready to ship in</h6>
                    <ul className="list-checkbox">
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">1 business day</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" defaultChecked="checked" />
                          <span className="text-small">1–3 business days</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">in 1 week</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Shipping now</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="col-w-1">
                    <h6 className="color-gray-900 mb-0">Ordering options</h6>
                    <ul className="list-checkbox">
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Accepts gift cards</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Customizable</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" defaultChecked="checked" />
                          <span className="text-small">Can be gift-wrapped</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Installment 0%</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                    </ul>
                    <h6 className="color-gray-900 mb-0 mt-40">Rating</h6>
                    <ul className="list-checkbox">
                      <li className="mb-5">
                        <a href="#">
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                            (5 stars)
                          </span>
                        </a>
                      </li>
                      <li className="mb-5">
                        <a href="#">
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                            (4 stars)
                          </span>
                        </a>
                      </li>
                      <li className="mb-5">
                        <a href="#">
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                            (3 stars)
                          </span>
                        </a>
                      </li>
                      <li className="mb-5">
                        <a href="#">
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                            (2 stars)
                          </span>
                        </a>
                      </li>
                      <li className="mb-5">
                        <a href="#">
                          <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <img
                            src="assetsss/imgs/template/icons/star-gray.svg"
                            alt="Ecom"
                          />
                          <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                            (1 star)
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-w-2">
                    <h6 className="color-gray-900 mb-0">Material</h6>
                    <ul className="list-checkbox">
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Nylon (8)</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Tempered Glass (5)</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" defaultChecked="checked" />
                          <span className="text-small">
                            Liquid Silicone Rubber (5)
                          </span>
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li>
                        <label className="cb-container">
                          <input type="checkbox" />
                          <span className="text-small">Aluminium Alloy (3)</span>
                          <span className="checkmark" />
                        </label>
                      </li>
                    </ul>
                    <h6 className="color-gray-900 mb-20 mt-40">Product tags</h6>
                    <div>
                      <a className="btn btn-border mr-5" href="#">
                        Games
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        Electronics
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        Video
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        Cellphone
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        Indoor
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        VGA Card
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        USB
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        Lightning
                      </a>
                      <a className="btn btn-border mr-5" href="#">
                        Camera
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-start pl-30">
                <a className="btn btn-buy w-auto" href="#">
                  Apply Fillter
                </a>
                <a className="btn font-sm-bold color-gray-500" href="#">
                  Reset Fillter
                </a>
              </div>
            </div>
          </div>
        </div>




      </main>
      <Footer />
    </>
  )
}

export default Subcat
import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Nav from "../../../navbar";

function Buyleads() {
  const navigate = useNavigate();
  const [uid, setUid] = useState("");
  const [productList, setProductList] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
      setUid(v[0].id);
      viewProduct(v[0].id);
    }
  }, []);

  const viewProduct = async (id) => {
    const data = new FormData();
    data.append("uid", id);
    data.append("val", "view");

    axios({
      method: "post",
      url: "/pro/inventory/submit_productinfo.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("product list ", s.data);
        const a = s.data;
        setProductList(a);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  const handleSearch = (data, searchText) => {
    if (!searchText) return data;
    return data.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  const columns = [
    {
      name: "Sr. No",
      selector: (_, index) => index + 1,
      maxWidth: "50px",
      sortable: true,
    },
    {
      name: "Product Code",
      selector: (row) => row.p_code,
      sortable: true,
    },
    {
      name: "Product Name",
      selector: (row) => row.p_name,
      sortable: true,
    },
    {
      name: "Total Units",
      selector: (row) => row.tunits,
      sortable: true,
      width: "150px",
    },
    {
      name: "Total Amount",
      selector: (row) => row.amx,
      sortable: true,
      width: "150px",
    },
    {
      name: "Stock Levels",
      selector: (row) =>
        row.level === "high" ? (
          <button className="btn btn-success btn-sm">{row.level}</button>
        ) : row.level === "low" ? (
          <button className="btn btn-warning btn-sm">{row.level}</button>
        ) : row.level === "medium" ? (
          <button className="btn btn-primary btn-sm">{row.level}</button>
        ) : row.level === "very low" ? (
          <button className="btn btn-danger btn-sm">{row.level}</button>
        ) : (
          <></>
        ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <button
          className="btn btn-sm btn-primary"
          onClick={() => navigate("/productpricing")}
        >
          Purchase Stock
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "200px",
    },
  ];

  const ExpandableRow = ({ data }) => (
    <div style={{ padding: "10px" }}>
      <p>
        <strong>Product Category:</strong> {data.p_category || "N/A"}
      </p>
      <p>
        <strong>Product Sub Category:</strong> {data.p_sub_cat || "N/A"}
      </p>
      <p>
        <strong>Brand Name:</strong> {data.brand_name || "N/A"}
      </p>
      <p>
        <strong>Purchase Units:</strong> {data.purchase_units || "N/A"}
      </p>
      <p>
        <strong>Product Group:</strong> {data.product_group || "N/A"}
      </p>
      <p>
        <strong>Product Supplier:</strong> {data.p_supplier || "N/A"}
      </p>
      <p>
        <strong>Tax Code:</strong> {data.tax_code || "N/A"}
      </p>
    </div>
  );

  return (
    <body>
      <Nav />
      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <section id="main-content">
              <div style={{ marginLeft: "20px", marginTop: "10px" }}>
                <Link to="/inventory">
                  <button className="btn btn-secondary px-2">
                    <i
                      style={{ paddingRight: "10px" }}
                      className="ti-arrow-left"
                    ></i>
                    Back
                  </button>
                </Link>
              </div>
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Inventory Stock Levels</h4>
                  </div>
                  <div className="row">
                  <div className="col-md-6">
                    <div className="text-right">
                      <input
                        placeholder="Search"
                        className="form-control"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <DataTable
                      columns={columns}
                      data={handleSearch(productList, searchText)}
                      pagination
                      responsive
                      highlightOnHover
                      expandableRows
                      expandableRowsComponent={ExpandableRow}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}

export default Buyleads;

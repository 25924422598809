import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import Nav from "./navbar";
import DataTable from "react-data-table-component";

function FacebookPosts() {
  const [uid, setUid] = useState("");
  const [posts, setPosts] = useState([]);
  const [selectedPageId, setSelectedPageId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [darw, setDarw] = useState(true);

  // Modal state
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isLargeModal, setIsLargeModal] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("USER"));
    if (user) {
      setUid(user[0].id);
      fetchPagesData(user[0].id);
    }
  }, []);

  const fetchPagesData = async (uid) => {
    const data = new FormData();
    data.append("uid", uid);
    data.append("run", "uid");

    axios
      .post("/pro/facebook/facebook_pages_view.php", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        if (response.data) {
          const formattedData = response.data.map((page) => ({
            value: page.page_id,
            label: page.page_name,
          }));
          setPages(formattedData);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch Facebook pages:", error);
      });
  };

  const fetchPosts = async (pageId) => {
    setLoading(true);
    setSelectedPageId(pageId);

    axios
      .post("/pro/facebook/get_facebook_posts.php", { page_id: pageId, uid })
      .then((response) => {
        if (response.data) {
          setPosts(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching Facebook posts:", error);
      })
      .finally(() => setLoading(false));
  };

  const handleSearch = (data, searchText) => {
    if (!searchText) return data;
    return data.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  const handleModalOpen = (message) => {
    setModalMessage(message || "No message available.");
    setIsLargeModal((message?.length || 0) > 300); // Use large modal if message is longer than 300 characters
    setShowModal(true);
  };

  const columns = [
    {
      name: "#",
      selector: (_, index) => index + 1,
      maxWidth: "50px",
    },
    {
      name: "Media Type",
      selector: (row) => row.media_type || "text",
      sortable: true,
    },
    // {
    //     name: "Image/Video",
    //     cell: (row) =>
    //       row.media_type === "photo" || row.media_type === "video" ? (
    //         <img
    //           src={row.media_url || "https://via.placeholder.com/100"} // Fallback thumbnail
    //           alt="Thumbnail"
    //           width={100}
    //           height={100}
    //           style={{ objectFit: "cover" }}
    //         />
    //       ) : (
    //         "N/A"
    //       ),
    //   },
       
    {
      name: "Message",
      cell: (row) => (
        <>
          <p
            style={{
              maxWidth: "200px",
              minWidth: "200px",
              overflow: "hidden",
              display: "inline-block",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              color: "black",
            }}
          >
            {row.post_content}
          </p>
        </>
      ),
    },
    {
        name: "",
        cell: (row) => (
          <>
            <button
              className="btn btn-sm btn-info pull-right ms-2"
              onClick={() => handleModalOpen(row.post_content)}
            >
             <i class="fa fa-eye" aria-hidden="true"></i>
            </button>
          </>
        ),
        maxWidth: "50px",
        minWidth: "50px",
    },
    {
      name: "Post with Alahdeen",
      cell: (row) => (
        <div
          style={{
            color: row.from_alahdeen ? "green" : "red",
            textAlign: "center",
            width: "100%",
          }}
        >
          {row.from_alahdeen ? "Yes" : "No"}
        </div>
      ),
      center: true, // Use the DataTable `center` property for alignment
    },
    {
      name: "Created Time",
      selector: (row) => row.created_time || "N/A",
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <a
          href={row.permalink_url}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-sm btn-primary"
        >
          Open Post
        </a>
      ),
      maxWidth: "200px",
    },
  ];

  return (
    <div>
      <Nav setDarw={setDarw} darw={darw} />
      <div
        style={{
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          padding: "0px",
        }}
        className={
          darw
            ? [
                "header-protected-screen padding-dashboard-table",
                "mobile-table padding-dashboard-table",
              ].join(" ")
            : [
                "header-protected-screen-full-width padding-dashboard-table",
                "mobile-table padding-dashboard-table",
              ].join(" ")
        }
      >
        <div className="container-fluid">
          <h4 className="mt-3"> Facebook Posts List</h4>
          <div className="row mt-3">
            <div className="col-md-6">
              <select
                className="form-control"
                onChange={(e) => fetchPosts(e.target.value)}
              >
                <option value="">Select Page</option>
                {pages.map((page) => (
                  <option key={page.value} value={page.value}>
                    {page.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                placeholder="Search posts..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </div>
          <div className="row mt-3">
            <DataTable
              columns={columns}
              data={handleSearch(posts, searchText)}
              pagination
              progressPending={loading}
              highlightOnHover
              responsive
            />
          </div>
        </div>
      </div>

      {/* Bootstrap Modal */}
      <div
        className="modal fade show modal-info"
        style={{ display: showModal ? "block" : "none" }}
        tabIndex="-1"
        role="dialog"
      >
        <div
          className={`modal-dialog ${
            isLargeModal ? "modal-xl" : ""
          }`} // Dynamically add `modal-lg` class
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Message</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowModal(false)}
              />
            </div>
            <div className="modal-body">
              <p>{modalMessage}</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FacebookPosts;

import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import LoginImage from '../images/buyer-seller-forget.jpg';
import Navmain from '../main/header';
import Footer from '../main/footer';



function Login() {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [opt, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [uid, setId] = useState("");
  const [status, setStatus] = useState("1");
  const [pss, setpss] = useState("");
  const [ps, setps] = useState("");
  const [error, seterror] = useState("");
  const [phone, setPhone] = useState("");

  const stepFirst = async () => {
    const data = new FormData();
    data.append("email", phone);
    data.append("status", "1");
    axios({
      method: "post",
      url: "/pro/auth/forgot_pass.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log(s.data);
        if (s.data[0].code == "pass") {
          setId(s.data.uid);
          setStatus("2");
        } else {
          alert("Try again?  Phone not found !");
        }
      })
      .catch((s) => {
        //navigate("/")
      });
  };

  const stepSecond = async () => {
    const data = new FormData();
    data.append("otp", opt);
    data.append("status", "2");
    axios({
      method: "post",
      url: "/pro/auth/forgot_pass.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
       
        if (s.data[0].code == "true") {
          setStatus("3");
        } else {

          alert("OTP is Worng Try again !");
        }
      })
      .catch((s) => {
        //navigate("/")
      });
  };
  const stepThrd = async () => {
    const data = new FormData();

    data.append("email", phone);
    data.append("otp", opt);
    data.append("pass", ps);
    data.append("status", "3");
    data.append("uid", uid);
    axios({
      method: "post",
      url: "/pro/auth/forgot_pass.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        
        if (s.data[0].code == "true") {

          navigate("/login");
        } else {
          alert("OTP is Worng Try again !");
        }
      })
      .catch((s) => {
        //navigate("/")
      });
  };

  const stepResend = async () => {
    const data = new FormData();
    data.append("email", phone);
    axios({
      method: "post",
      url: "/pro/auth/forgot_pass.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        if (s.data.code == "pass") {
          setId(s.data.uid);
          setStatus("2");
        }
      })
      .catch((s) => {
        //navigate("/")
      });
  };

  const text_type=(input)=>{
    // Regular expression to check if the string contains only alphabetic characters
  const regex = /^[A-Za-z\s]+$/;

  // Test the input against the regular expression
  return regex.test(input);

  }

  return (
<div>
    <Navmain />
    <main className="main">
      <section className="bg-home9 no-gutters">
      <div className="container d-flex justify-content-center align-items-center">
      <div className="row py-5 w-100">
      <div className="col-6 d-none d-lg-block p-2">
    <div className="p-4 border-0 rounded login-content"
      style={{
        width: '100%',
        height: '100%',
        overflow: 'hidden' // Prevents any overflow outside the container
      }}
    >
      <img 
        className="form-image"
        src={LoginImage} 
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover' // Ensures the image covers the entire div without distortion
        }}
      />
    </div>
</div>
        <div className="col-md-6 col-12">
          <div className="p-4  border-0 rounded login-content">
                <div style={{ opacity: 0.9 }} className="login-form">
                {status == "1" ? (
                  <>
                    <div className="login-form-forget">
                      <h4>Forgotten Password</h4>
                      <center>
                  </center>
                  <div style={{ padding: "0px" }} className= {error =="phone"?"form-group col-12 has-error":"form-group col-12"} >
                  <label>Phone No.</label>
                  <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroupPrepend">+92</span>
        </div>
        <input type="text"  placeholder="Phone No." aria-describedby="inputGroupPrepend" className="form-control" value={phone}   onChange={(v) => {
          if(v.target.value.length<=10)
          {
            
            if( !text_type(v.target.value))
              {
                setPhone(v.target.value);
                
              }
          }
                        

                      }} />
                       
       
      
</div>

     
                  
                  </div>
                     
                      <button
                        type="submit"
                        onClick={() => {
                          stepFirst();
                        }}
                        className="btn btn-primary btn-flat m-b-30 m-t-30"
                      >
                        Submit
                      </button>

                      <div className="register-link  text-center">
                        <p>
                          Already have account ? <a href="login">Sign in</a>{" "}
                        </p>
                      </div>
                    </div>
                  </>
                ) : status == "2" ? (
                  <>
                    <div className="login-form-forget">
                      <h4>Forgotten Password</h4>

                      <div className="form-group">
                        <label>OTP ( 6 Digit)</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="otp"
                          value={opt}
                          onChange={(v) => {

                            if(v.target.value.length<7)
                            {
                              setOtp(v.target.value);
                            }
                            
                          }}
                        />
                      </div>
                      <button
                        type="submit"
                        onClick={() => {
                          stepSecond();
                        }}
                        className="btn btn-primary btn-flat m-b-30 m-t-30"
                      >
                        Submit
                      </button>

                      <div className="register-link m-t-15 text-center">
                        <p>
                          Already have account ? <a href="login">Sign in</a>{" "}
                        </p>
                      </div>
                    </div>
                  </>
                ) : status == "3" ? (
                  <>
                    <div className="login-form-forget">
                      <h4>Forgotten Password</h4>

                      <div className="form-group">
                        <label>Password</label>
                        <input
                          type="text"
                          className="form-control"
                          value={ps}
                          placeholder="Enter Your Password"
                          onChange={(v) => {
                            setps(v.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Your Password"
                          value={pss}
                          onChange={(v) => {
                            setpss(v.target.value);
                          }}
                        />
                      </div>
                      <button
                        type="submit"
                        onClick={() => {
                          stepThrd();
                        }}
                        className="btn btn-primary btn-flat m-b-30 m-t-30"
                      >
                        Submit
                      </button>

                      <div className="register-link m-t-15 text-center">
                        <p>
                          Already have account ? <a href="login">Sign in</a>{" "}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
      </main>
      <Footer />
      </div>
  );
}

export default Login;

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Error404 = ({ message }) => {
  return (
    <div className="error-container">
      <div className="error-content">
        <h2 className="fw-semibold">Uh-Oh...</h2>
        <p className="fs-5">
          {message || "The page you are looking for may have been moved, deleted, or never existed."}
        </p>
        <h1 className="error-code">404</h1>
      </div>
    </div>
  );
};

export default Error404;

import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Nav from "./navbar";
import axios from "axios";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Select from "react-select";

const FacebookPostPage = () => {
  const [uid, setUid] = useState("");
  const [pages, setPages] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPostButtonEnabled, setIsPostButtonEnabled] = useState(false);
  const fileInputRef = useRef(null);
  const [isFBInitialized, setIsFBInitialized] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("USER"));
    if (user === null) {
    } else {
      setUid(user[0].id);
      fetchPagesData(user[0].id);

      window.FB.init({
        appId: "2816878121820045",
        cookie: true,
        xfbml: true,
        version: "v21.0",
      });
      setIsFBInitialized(true);
  
      (function (d, s, id) {
        let fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          console.log("Facebook SDK script already loaded.");
          return;
        }
        let js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        js.onload = () => console.log("Facebook SDK script loaded.");
        js.onerror = () => console.error("Failed to load Facebook SDK script.");
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    }
  }, []);
  

  const isContentEmpty = (content) => {
    const strippedContent = content.replace(/<[^>]*>/g, "").trim(); // Remove all HTML tags and whitespace
    return strippedContent.length === 0;
  };

  useEffect(() => {
    const postContent = draftToHtml(convertToRaw(editorState.getCurrentContent())).trim();
    setIsPostButtonEnabled(
      selectedPages.length > 0 && !isContentEmpty(postContent) && !errorMessage
    );
  }, [selectedPages, editorState, errorMessage]);

  const fetchPagesData = (uid) => {
    const data = new FormData();
    data.append("uid", uid);
    data.append("run", "uid");

    axios
      .post("/pro/facebook/facebook_pages_view.php", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        if (response.data) {
          const formattedData = response.data
            .filter((item) => item.posting_status === "1")
            .map((page) => ({
              value: page.page_id,
              label: page.page_name,
              accessToken: page.access_token,
            }));
          setPages(formattedData);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch Facebook pages:", error);
      });
  };

  const renewAccessToken = (pageId, callback) => {
    setLoading(true);
    if (!isFBInitialized) {
      alert("Facebook SDK is not initialized yet. Please wait.");
      setLoading(false);
      return;
    }
  
    try {
      // Call FB.login
      window.FB.login(
        (response) => {
          if (response.authResponse) {
            const userAccessToken = response.authResponse.accessToken;
  
            // Send the short-lived user token and page ID to the backend
            axios
              .post(
                "/pro/facebook/updatePageToken.php",
                {
                  page_id: pageId,
                  user_access_token: userAccessToken, // Send the short-lived token
                },
                {
                  headers: { "Content-Type": "application/json" },
                }
              )
              .then((res) => {
                if (res.data.success) {
                  callback(res.data.page_access_token); // Return the new page token
                } else {
                  console.error("Failed to renew access token:", res.data.message);
                  alert("Failed to renew access token. Please try again.");
                }
              })
              .catch((error) => {
                console.error("Error during token renewal request:", error);
                alert("An error occurred while renewing the access token.");
              });
          } else {
            alert("Facebook login failed or permissions not granted.");
          }
        },
        {
          scope: "pages_show_list,business_management,pages_read_engagement,pages_manage_posts,pages_manage_metadata",
        }
      );
      setLoading(false);
    } catch (error) {
      // Detect popup blocker
      if (error.name === "TypeError" || error.message.includes("Blocked")) {
        alert(
          "Popup was blocked. Please enable popups for this site to renew your access token."
        );
      } else {
        console.error("Unexpected error:", error);
        alert("An unexpected error occurred. Please try again.");
      }
      setLoading(false);
    }
  };  
  

  const handlePost = () => {
    let postContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
  
    postContent = postContent
      .replace(/\*([^\*]+)\*/g, "**$1**")
      .replace(/#([a-zA-Z0-9_]+)/g, "#$1")
      .replace(/&nbsp;/g, " ")
      .replace(/<[^>]*>/g, "")
      .trim();
  
    const images = attachments.filter((attachment) => attachment.file.type.startsWith("image/"));
    const videos = attachments.filter((attachment) => attachment.file.type.startsWith("video/"));

    if (images.length > 0 && videos.length > 0) {
      alert("You can only attach either multiple images or multiple videos, not both.");
      setLoading(false);
      return;
    }

    if (videos.length > 1) {
        alert("You can only upload one video at a time.");
        setLoading(false);
        return;
    }

    if (images.length > 1) {
        alert("You can only upload one image at a time.");
        setLoading(false);
        return;
    }

    setLoading(true); // Start loading state
    selectedPages.forEach((page) => {
      const postToFacebook = (accessToken) => {
        setLoading(true);
        const savePostToDB = (facebookPostId, mediaType) => {
          // Generate post_url dynamically
          let postUrl = "";
          if (mediaType === "image") {
            postUrl = `https://www.facebook.com/photo/?fbid=${facebookPostId}`;
          } else if (mediaType === "video") {
            postUrl = `https://www.facebook.com/video.php?v=${facebookPostId}`;
          } else if (mediaType === "text") {
            const postId = facebookPostId.split("_")[1]; // Extract the second part of the ID
            postUrl = `https://www.facebook.com/${postId}`;
          }
  
          // Send the post details to the backend API to save in DB
          axios
            .post("/pro/facebook/savePost.php", {
              user_id: uid,
              page_id: page.value,
              facebook_post_id: facebookPostId,
              post_content: postContent,
              media_type: mediaType,
              media_url: postUrl,
            })
            .then((res) => {
              if (res.data.success) {
                console.log(`Post saved in DB for page ${page.label}`);
              } else {
                console.error(`Failed to save post for page ${page.label}: ${res.data.message}`);
              }
            })
            .catch((error) => {
              console.error(`Error saving post for page ${page.label}:`, error);
            });
        };
  
        if (images.length > 0) {
          const imagePromises = images.map((image) => {
            const formData = new FormData();
            formData.append("caption", postContent);
            formData.append("access_token", accessToken);
            formData.append("images", image.file);
  
            return axios
              .post(`https://graph.facebook.com/${page.value}/photos`, formData)
              .then((res) => {
                if (res.data.id) {
                  alert(`Image posted on ${page.label}`);
                  savePostToDB(res.data.id, "image");
                }
              })
              .catch((error) => {
                if (error.response?.data?.error) {
                  const errorMessage = error.response.data.error.message || "An error occurred.";
                  alert(`Error posting image to ${page.label}: ${errorMessage}`);
  
                  // Retry if token has expired
                  if (error.response.data.error.code === 190) {
                    renewAccessToken(page.value, (newToken) => {
                      postToFacebook(newToken);
                    });
                  }
                } else {
                  console.error(`Error posting image to ${page.label}:`, error);
                }
              });
          });
  
          Promise.all(imagePromises).finally(() => setLoading(false));
        } else if (videos.length > 0) {
          const videoPromises = videos.map((video) => {
            const formData = new FormData();
            formData.append("description", postContent);
            formData.append("source", video.file);
            formData.append("access_token", accessToken);
  
            return axios
              .post(`https://graph.facebook.com/${page.value}/videos`, formData)
              .then((res) => {
                if (res.data.id) {
                  alert(`Video posted on ${page.label}`);
                  savePostToDB(res.data.id, "video");
                }
              })
              .catch((error) => {
                if (error.response?.data?.error) {
                  const errorMessage = error.response.data.error.message || "An error occurred.";
                  alert(`Error posting video to ${page.label}: ${errorMessage}`);
  
                  // Retry if token has expired
                  if (error.response.data.error.code === 190) {
                    renewAccessToken(page.value, (newToken) => {
                      postToFacebook(newToken);
                    });
                  }
                } else {
                  console.error(`Error posting video to ${page.label}:`, error);
                }
              });
          });
  
          Promise.all(videoPromises).finally(() => setLoading(false));
        } else {
          const formData = new FormData();
          formData.append("message", postContent);
          formData.append("access_token", accessToken);
  
          axios
            .post(`https://graph.facebook.com/${page.value}/feed`, formData)
            .then((res) => {
              if (res.data.id) {
                alert(`Post created on ${page.label}`);
                savePostToDB(res.data.id, "text");
              }
            })
            .catch((error) => {
              if (error.response?.data?.error) {
                const errorMessage = error.response.data.error.message || "An error occurred.";
                alert(`Error creating post on ${page.label}: ${errorMessage}`);
  
                // Retry if token has expired
                if (error.response.data.error.code === 190) {
                  renewAccessToken(page.value, (newToken) => {
                    postToFacebook(newToken);
                  });
                }
              } else {
                console.error(`Error creating post on ${page.label}:`, error);
              }
            })
            .finally(() => setLoading(false));
        }
      };
  
      postToFacebook(page.accessToken);
    });
  };  

  const handleAttachmentChange = (e) => {
    const files = Array.from(e.target.files);
    const images = files.filter((file) => file.type.startsWith("image/"));
    const videos = files.filter((file) => file.type.startsWith("video/"));
  
    if (images.length > 0 && videos.length > 0) {
      setErrorMessage("You can only attach either multiple images or multiple videos, not both.");
      fileInputRef.current.value = ""; // Reset the input value
      return;
    }
  
    setErrorMessage("");
  
    const generateThumbnails = files.map((file) => {
      if (file.type.startsWith("video/")) {
        return new Promise((resolve) => {
          const video = document.createElement("video");
          video.src = URL.createObjectURL(file);
          video.currentTime = 0.1;
          video.addEventListener("loadeddata", () => {
            const canvas = document.createElement("canvas");
            canvas.width = 100;
            canvas.height = 100;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            resolve({ file, thumbnail: canvas.toDataURL() });
          });
        });
      } else {
        return Promise.resolve({ file, thumbnail: URL.createObjectURL(file) });
      }
    });
  
    Promise.all(generateThumbnails).then((results) => {
      setAttachments((prev) => [...prev, ...results]);
    });
  };
  

const removeAttachment = (index) => {
    const updatedAttachments = attachments.filter((_, i) => i !== index);
    setAttachments(updatedAttachments);

    // Update the file input value to reflect the remaining attachments
    const updatedFiles = updatedAttachments.map((attachment) => attachment.file);
    const dataTransfer = new DataTransfer();
    updatedFiles.forEach((file) => dataTransfer.items.add(file));
    fileInputRef.current.files = dataTransfer.files;
};

  return (
    <>
      <Nav />
      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <section id="main-content">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h3>Create Facebook Post</h3>
                  </div>
                  <div className="card-body p-0">
                    <div className="form-group mb-3">
                      <label className="form-label">Select Pages: <span className="text-danger">*</span></label>
                      <Select
                        options={pages}
                        isMulti
                        onChange={(selected) => setSelectedPages(selected)}
                        placeholder="Select Pages to Post"
                      />
                    </div>

                    <div className="form-group mb-3">
                      <label className="form-label">Post Content: <span className="text-danger">*</span></label>
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        toolbar={{
                          options: ["inline", "blockType", "list", "textAlign", "link", "emoji", "image"],
                          inline: { options: ["bold", "italic", "underline"] },
                          emoji: true,
                        }}
                        wrapperClassName="editor-wrapper border border-secondary rounded"
                        editorClassName="editor p-2"
                      />
                    </div>

                    <div className="form-group mb-3">
                      <label className="form-label">Attachments:</label>
                      <div className="d-flex flex-wrap mb-2">
                      {attachments.map((attachment, index) => (
                        <div
                          key={index}
                          className="me-2 mb-2 position-relative"
                          style={{
                            width: "100px",
                            height: "100px",
                            overflow: "hidden",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                          }}
                        >
                          <button
                            className="btn-close position-absolute top-0 end-0"
                            style={{ zIndex: 1, backgroundColor: "white", borderRadius: "50%" }}
                            onClick={() => removeAttachment(index)}
                          ></button>
                          <img
                            src={attachment.thumbnail}
                            alt="Thumbnail"
                            style={{ width: "100%", height: "100%", objectFit: "cover" }}
                          />
                        </div>
                      ))}
                    </div>
                      <input
                        type="file"
                        // multiple
                        accept="image/*,video/*"
                        className="form-control"
                        ref={fileInputRef}
                        onChange={handleAttachmentChange}
                      />
                      {errorMessage && <div className="text-danger mt-2">{errorMessage}</div>}
                    </div>

                    <button
                      className="btn btn-primary w-100"
                      onClick={handlePost}
                      disabled={!isPostButtonEnabled || loading}
                    >
                      {loading ? "Posting..." : "Post to Facebook"}
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default FacebookPostPage;

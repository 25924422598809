import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Nav from "./navbar";
import DataTable from "react-data-table-component";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/dist/modal";

function FacebookListing() {
  const [pagesData, setPagesData] = useState([]);
  const [uid, setUid] = useState("");
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [darw, setDarw] = useState(true);
  const [deletePageId, setDeletePageId] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("USER"));
    if (user && user[0]?.id) {
      setUid(user[0].id);
      fetchPagesData(user[0].id);
    }
  }, []);

  const fetchPagesData = (uid) => {
    const data = new FormData();
    data.append("uid", uid);
    data.append("run", "uid");

    axios
      .post("/pro/facebook/facebook_pages_view.php", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        if (response.data) {
          const formattedData = response.data.map((item) => ({
            ...item,
            posting_status: Boolean(Number(item.posting_status)),
          }));
          setPagesData(formattedData);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch Facebook pages:", error);
      });
  };

  const updatePostingStatus = (id, status) => {
    axios
      .post("/pro/facebook/facebook_pages_update_status.php", { id, status }, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => {
        setPagesData((prevData) =>
          prevData.map((page) =>
            page.id === id ? { ...page, posting_status: status } : page
          )
        );
      })
      .catch((error) => console.error("Error updating posting status:", error));
  };

  const deletePage = () => {
    axios
      .post("/pro/facebook/facebook_pages_delete.php", { id: deletePageId }, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => {
        setPagesData((prevData) =>
          prevData.filter((page) => page.id !== deletePageId)
        );
        setDeletePageId(null);
      })
      .catch((error) => console.error("Error deleting page:", error));
  };

  const filteredData = pagesData.filter((row) =>
    Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const paginatedData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const columns = [
    {
      name: "Sr. No.",
      selector: (_, index) => index + 1 + (currentPage - 1) * rowsPerPage,
      sortable: true,
    },
    { name: "Page Name", selector: (row) => row.page_name, sortable: true },
    { name: "Page ID", selector: (row) => row.page_id, sortable: true },
    {
      name: "Created At",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "Posting Status",
      cell: (row) => (
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id={`switch-${row.id}`}
            checked={row.posting_status == 1}
            onChange={(e) =>
              updatePostingStatus(row.id, e.target.checked)
            }
          />
          <label
            className="form-check-label"
            htmlFor={`switch-${row.id}`}
          >
            {row.posting_status == 1 ? "Active" : "Inactive"}
          </label>
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <button
            className="btn btn-primary me-1"
            onClick={() => {
              if (row.page_link) {
                window.open(row.page_link, "_blank");
              } else {
                alert("We do not have a valid page link.");
              }
            }}
          >
            <i className="fa fa-external-link"></i>
          </button>
          <button
            className="btn btn-info me-1"
            onClick={() => {
                window.open('/FacebookIntegrationPage?id='+row.id);
            }}
          >
            <i className="fa fa-pencil"></i>
          </button>
          <button
            className="btn btn-danger"
            data-bs-toggle="modal"
            data-bs-target="#deleteModal"
            onClick={() => setDeletePageId(row.id)}
          >
            <i className="fa fa-trash-o"></i>
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '200px' 
    },
  ];

  return (
    <div>
      <Nav setDarw={setDarw} darw={darw} />
      <div
        style={{ overflow: "auto", display: "flex", flexDirection: "column", padding: "0px" }}
        className={
          darw
            ? [
                "header-protected-screen padding-dashboard-table",
                "mobile-table padding-dashboard-table",
              ].join(" ")
            : [
                "header-protected-screen-full-width padding-dashboard-table",
                "mobile-table padding-dashboard-table",
              ].join(" ")
        }
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between mt-3 mb-3">
            <h4>Facebook Pages</h4>
            <input
              type="text"
              className="form-control w-25"
              placeholder="Search Facebook Pages..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <DataTable
            columns={columns}
            data={paginatedData}
            pagination
            paginationServer
            paginationTotalRows={filteredData.length}
            paginationDefaultPage={currentPage}
            paginationRowsPerPageOptions={[10, 20, 30]}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            highlightOnHover
            responsive
          />
        </div>
      </div>

      {/* Delete Modal */}
      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">
                Confirm Delete
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this page?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={deletePage}
                data-bs-dismiss="modal"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FacebookListing;

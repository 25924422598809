import React from "react";
import Footer from "../../main/footer";
import Nave from "../../main/header";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useNavigate } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import { Textarea } from "react-bootstrap-icons";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "./styles.css";
import Logo from '../../images/logo.png';
import Img3 from '../../images/img3.png';
import '../../styles/header.css';
import { SiGooglemaps } from "react-icons/si";
import { FaPhone } from "react-icons/fa6";
import Image2 from '../../images/b2.png'
import ProductQuote from "../../main/get_product_quote"; 


function Companypage() {

  const navigate = useNavigate();
  const [sheet, setSheet] = useState([
    {
      id: 1,
      name: "",
      quantity: "",
      piece: "",
      price: "",
      des: "",
      status: "normal",
    },
  ]);
  const [alert1, setAlert1] = useState(false)
  const [uid, setUid] = useState("")
  const pid = useRef('');
  const [user, setUser] = useState([]);
  const [allUID, setAllUID] = useState([]);
  const [profile, setProfile] = useState([]);
  const [company, setCompany] = useState([]);
  const [director, setDirector] = useState([]);
  const [view, setView] = useState(1);
  const [cname, setCname] = useState("");
  const [imgCompany, setImgCompany] = useState([]);
  const [imgLogo, setImgLogo] = useState([]);
  const [mainBanner, setMainBanner] = useState([]);
  

  useEffect(() => {
    const aa = localStorage.getItem("USER");
    if (aa != null) {
      const as = JSON.parse(aa);

      console.log("ttsss", as);

      setUid(as[0].id);

      setUser(as);
    } else {
    }
    get();

  }, []);

  const get = async () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get("business");

    if (id == null) {
      if (user.length > 0) {

        getProductbyuid(user[0].id)
        CompanyProfileView(user[0].id)
        ViewImgCompany(user[0].id)
        pid.current = user[0].id;
        Director(user[0].id)
      } else {
        navigate("/")
      }


    } else {

      getProductbyuid(id)
      CompanyProfileView(id)
      ViewImgCompany(id)
      Director(id)
      pid.current = id;
    }



  }

  const ViewImgCompany = async (uid) => {
    const data = new FormData();
    data.append('uid', uid);
    data.append('val', "view");
    axios({
      method: 'post',
      url: 'pro/profile/manage_profile_images.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        setImgCompany(s.data)

        setImgLogo(s.data.find(item => item.type === "logo"));
        setMainBanner(s.data.find(item => item.type === "main banner"));

        console.log("compny images",s.data)
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  const add_lead = async () => {

    const data = new FormData();

    data.append("uid", uid);
    data.append("sheet", JSON.stringify(sheet));
    data.append("cat", "cat");
    //data.append("img", "");

    axios({
      method: "post",
      url: "/pro/lead_add.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {

        setAlert1(!alert1);
        navigate('/product-buy-request')
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });

  };
  const changeArayquote = (i, changes) => {
    const oldArr = [...sheet];
    const oldObj = oldArr[i];
    oldArr[i] = { ...oldObj, ...changes };
    setSheet(oldArr);
  };


  const getProductbyuid = async (id) => {
    const data = new FormData();

    data.append("uid", id);

    axios({
      method: "post",
      url: "/mainsite/productbyuid.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {

        setAllUID(s.data);

      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };
  const CompanyProfileView = async (id) => {

    const data = new FormData();

    data.append("uid", id);

    axios({
      method: "post",
      url: "/pro/profile/profile_view_company.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {

        const dd = JSON.parse(s.data[0].aray_pprofile);
        console.log("company Profile", dd);
        setProfile(dd);
        const d = JSON.parse(s.data[0].aray_com);
        setCompany(d);
        setCname(dd[0].companyname)

        // console.log("Personal Profile", dd);

      })
      .catch((s) => {

        console.log("here catch =>>", s);
      });
  };


  const Director = async (uid) => {
    const data = new FormData();

    data.append("uid", uid);

    axios({
      method: "post",
      url: "/pro/profile/profile_view_director.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setDirector(s.data)
        // console.log("Director List", s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };






  return (
    <div>
      <Nave />



      {/* <div class="row">
        <div class="col-md-12">
          <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet" />
          <div class="">
            <div class="row">
              <div class="col-md-12">
                <div id="content" class="content content-full-width">
                  <div class="profile">
                    <div
                      style={{ backgroundImage: `url("https://alahdeen.com/pro/image/bgcover.png")`, backgroundSize: "auto", backgroundRepeat: "no-repeat", height: "350px", opacity: "0.7" }}

                      class="profile-header">
                      <div class="profile-header-cover">
                        <h1 style={{ position: "relative", top: "40%", }} className="text-center text-white">{cname}'s Marketplace</h1>

                      </div>
                      <div class="profile-header-content">
                    </div>
                  </div>

                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="">

        {
          view == 1 ?
            <>
              <div className="row">
              <div className="col-12 d-flex align-items-stretch" style={{ padding: "0px" }}>
      <div
        className="mb-0 header-border-styles d-flex align-items-stretch w-100"
        style={{
          boxShadow: "0px 0px 17px 6px #ccc",
          marginRight: "10px",
          marginLeft: "10px",
          height: "40vh",
          overflow: "hidden",
          position: "relative",
          backgroundImage: `url(${mainBanner?.image || Image2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed"
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            background: "rgba(0, 0, 0, 0.4)",
          }}
        ></div>
        <div
          className="carousel-caption text-center"
          style={{ position: "absolute", top: "20%"}}
        >
          <h5 style={{ fontWeight: "bold", color: "#fff" }}>
            {(company[0] && company[0].mainHeadings) || 'Connecting Wholesalers to Retailers across Pakistan'}
          </h5>
          <p style={{ color: "#f1f1f1" }}>
            {(company[0] && company[0].mainParagraphs) || 'Simplified platform for B2B e-commerce dealing with a million products.'}
          </p>
          <h5 className="company-name" style={{ fontWeight: "bold", color: "#fff"}}>
            {cname}
          </h5>
        </div>
      </div>
    </div>
<div className="col-12">
  <div className="d-flex align-items-center justify-content-center px-3 w-100">
    <img
      src={imgLogo?.image || Logo} // Replace with your logo path
      alt="Company Logo"
      className="rounded bg-light shadow-sm"
      style={{ width: '150px', height: '100px', border: '2px solid #4f97a1', marginTop: '-80px' }}
    />
  </div>
  <div className="divider-line mt-2" />
</div>

                </div>
                <div className="container">
                <div className="row">

                {/* Our Company Starts */}
                <div className="col-12">
                
                {/* <hr /> */}

                <div className="row d-flex align-items-stretch">
                {/* Left Column */}
                <div className="col-lg-8 col-md-8 col-sm-12 d-flex">
                  <div className="card card-estore-company p-3 w-100">
                    <h5 className="pt-3 text-left" style={{ color: "#1A78B4" }}>About Us</h5>
                    <div className="d-flex flex-column gap-5 mt-3 position-relative">
                      <div className="d-flex gap-2 align-items-start">
                        <i className="fas fa-user" style={{ color: "#2C99A1" }}></i>
                        <p className="mb-0 text-dark">
                          {company.length > 0 ? company[0].description : ""}
                        </p>
                      </div>
                      <div className="d-flex gap-2 align-items-center">
                        <i className="fas fa-store" style={{ color: "#2C99A1" }}></i>
                        <span className="mb-0 text-dark estore-header-details">
                          {profile[0] && profile[0].address}
                        </span>
                      </div>
                      <div className="d-flex gap-2 align-items-center">
                        <i className="fas fa-phone" style={{ color: "#2C99A1" }}></i>
                        <span className="mb-0 text-dark estore-header-details">
                          {profile[0] && profile[0].companyphone}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Right Column */}
                <div className="col-lg-4 col-md-4 col-sm-12 d-flex">
                  <div className="card card-estore-company w-100 d-flex flex-column">
                    <div className="d-flex justify-content-center align-items-center gap-5 h-100">
                      {/* Get Product Quote Form */}
                      <ProductQuote
                        product={[]}
                        user={user}
                        uid={uid}
                        piduser={pid.current}
                        navigate={navigate}
                        type={'business'}
                      />
                    </div>
                  </div>
                </div>
              </div>

                </div>
                {/* Our Company Ends */}


                {/* Products Section */}
                
                <div className="col-12">
                <div className="mt-5">
  <h2 className="text-center mb-4">Products</h2>
  <div className="row justify-content-center">
    {allUID.map((item, index) => (
      <div className="col-md-3 col-sm-6 mb-4" key={index}>
        <div className="card h-100 d-flex flex-column" style={{ borderRadius: '16px' }}>
          <a href={`product?id=${item.id}&s=${item.name}`} className="text-decoration-none">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                height: '200px',
                overflow: 'hidden',
                borderTopLeftRadius: '16px',
                borderTopRightRadius: '16px'
              }}
            >
              <img
                src={item.img.replace('https://alahdeen.com', 'http://localhost')}
                className="img-fluid"
                alt="Product"
                style={{
                  maxHeight: '100%',
                  maxWidth: '100%',
                  objectFit: 'contain'
                }}
              />
            </div>
          </a>
          <div className="card-body flex-grow-1 d-flex flex-column">
            <h5 className="card-title">{item.name}</h5>
            <p className="card-text">Rs. {item.price}</p>
            <div className="mt-auto">
              <a href={`product?id=${item.id}&s=${item.name}`}>
                <button
                  className="btn w-100"
                  style={{
                    borderRadius: '16px',
                    backgroundColor: 'rgb(69, 172, 179)',
                    color: 'white'
                  }}
                >
                  Get Quote
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
</div>

</div>



              </div>
              </div>

              {/* Add other sections like products and features below */}

              {/* <section className="padding-bottom">
                <header className="section-heading heading-line">
                  <h4 className="title-section text-uppercase">
                    Request for Quotation
                  </h4>
                </header>

                <div className="row">
                  <div className="col-md-12 col-sm-12 col-lg-8">
                    <div
                      className="card-banner banner-quote overlay-gradient"
                      style={{
                        backgroundImage: "url('assets/images/banners/banner9.jpg')",
                      }}>
                      <div className="card-img-overlay white">
                        <h3 className=" text-light mx-4 my-4 ">Want to Get Quotations?</h3>
                        <p className="text-light mx-4">
                          Your Product is only one click away. Click and Get your
                          desired Product
                        </p>
                        <a href="" className="btn btn-primary rounded-pill mx-4">
                          Learn more
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className=" col-lg-4 col-md-12 col-sm-12">
                    <aside className="special-home-right">
                      <div className=" card-body">
                        <h5 className="pb-2  text-center text-uppercase">
                          Get Quote
                        </h5>

                        <div className="form-group">
                          <input
                            style={{ color: "black" }}
                            className="form-control"
                            placeholder="Name of Product"
                            type="text"
                            value={sheet[0].name}
                            onChange={(e) => {
                              changeArayquote(0, { name: e.target.value });
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <div className="input-group">
                            <input

                              className="form-control"
                              placeholder="Quantity"
                              type="number"
                              value={sheet[0].qty}
                              onChange={(e) => {
                                changeArayquote(0, { qty: e.target.value });
                              }}
                            />
                            <div className="form-control">
                              <select
                                style={{ borderColor: "transparent", fontSize: "16px" }}
                                value={sheet[0].piece}
                                onChange={(e) => {
                                  changeArayquote(0, { piece: e.target.value });
                                }}
                                className=" mx-3 "
                              >

                                <option >Units</option>
                                <option>Pieces</option>
                                <option>Grams</option>
                                <option>Kilograms</option>
                                <option>Tons</option>
                                <option>Litres</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 form-group">
                            <input
                              className="form-control"
                              placeholder="Approx Price Rs"
                              type="number"
                              value={sheet[0].price}
                              onChange={(e) => {
                                changeArayquote(0, { price: e.target.value });
                              }}
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <textarea

                            className="form-control"
                            placeholder="Requirement Description"
                            maxLength={100}

                            onChange={(e) => {
                              changeArayquote(0, { des: e.target.value });
                            }}
                          >{sheet[0].des}</textarea>
                        </div>

                        <div
                          onClick={() => {

                            sheet[0].name === '' ? alert('Please Fill the Exact Get Quote Form') :
                              add_lead();

                          }}
                          className="form-group text-center"
                        >

                          <button
                            className="btn btn-primary"
                          >
                            Get Quote
                          </button>
                          {alert1 ? (
                            <>
                              <SweetAlert
                                success
                                confirmBtnText="Thanks !"
                                confirmBtnBsStyle="primary"
                                timeout={2000}
                                title="Your Requirement Successfully Sent to the Seller"
                                onConfirm={() => {
                                  setAlert1(!alert1);
                                }}
                                onCancel={() => {
                                  setAlert1(!alert1);
                                }}
                                focusCancelBtn
                              >
                                <br />
                              </SweetAlert>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </aside>
                  </div>

                </div>
              </section> */}


              {/* <section className="padding-bottom-sm my-5">
                <header className=" section-heading heading-line">
                  <h4 className=" title-section text-uppercase">Our Products</h4>
                </header>
                <div style={{ cursor: "pointer", borderRadius: "30px" }} className="row mx-3 my-4">
                  {allUID.map((v, i) => (
                    <>
                      <div
                        style={{ cursor: "pointer", borderRadius: "20px" }}
                        className="col-md-4 col-sm-6 col-lg-3  card-product-grid card-sm"
                      >
                        <a href={"product?id=" + v.id + "s=" + v.name}>
                          <article className="card card-post">
                            <img

                              heigt={100}
                              width={100}
                              src={v.img}
                              className="card-img-top"
                            />

                            <div className="card-body">
                              <h6
                                style={{ height: "20px", overflowY: "auto" }}
                                className=" text-center"
                              >
                                {v.name}
                              </h6>
                              <p className="text-center">{v.price}</p>
                              <div
                                style={{ marginTop: "10px" }}

                                className="form-group text-center"
                              >
                                <button className="btn btn-primary">Get quote</button>
                              </div>
                            </div>
                          </article>
                        </a>
                      </div>
                    </>
                  ))}
                </div>
              </section> */}
{
  director.length === 1 ? (
    <section id="main-content" className="container">
      <div className="text-center">
        <h2 className="mb-4">Company's Director</h2>
      </div>
      {/* Using justify-content-center to center the columns if they don't fill the row */}
      <div className="row justify-content-center">
        {director.map((director, index) => (
          <div
            className="col-md-3 col-sm-6 mb-4 d-flex align-items-stretch"
            key={index}
          >
            <div className="card w-100" style={{ borderRadius: '16px' }}>
              <div className="card-body text-center">
                <div className="mb-3">
                  <img
                    src={director.img ? director.img : Img3}
                    alt="Director"
                    className="rounded-circle img-thumbnail"
                    style={{
                      width: '150px',
                      height: '150px',
                      objectFit: 'cover',
                    }}
                  />
                </div>
                <h5 className="card-title">Designation</h5>
                <p className="card-text">
                  {director.name ? director.name : 'Asad'}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  ) : (
    <section className="container pt-5 pb-5 mb-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="text-center">
              <h2 className="mb-4">Company's Director</h2>
            </div>
          </div>
          <div className="col-12 text-center">
            <a
              className="btn btn-primary mb-3 mr-1"
              href="#carouselExampleIndicators2"
              role="button"
              data-slide="prev"
            >
              <i className="fa fa-arrow-left"></i>
            </a>
            <a
              className="btn btn-primary mb-3"
              href="#carouselExampleIndicators2"
              role="button"
              data-slide="next"
            >
              <i className="fa fa-arrow-right"></i>
            </a>
          </div>
          <div className="col-12">
            <div
              id="carouselExampleIndicators2"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  {/* Center the row content if not a full grid */}
                  <div className="row justify-content-center">
                    {director.map((v, i) => (
                      <div
                        className="col-md-3 col-sm-6 mb-3 d-flex align-items-stretch"
                        key={i}
                      >
                        <div className="card w-100" style={{ borderRadius: '16px' }}>
                          <div className="card-body text-center">
                            <div className="mb-3">
                              <img
                                src={v.img ? v.img : Img3}
                                alt="Director"
                                className="rounded-circle img-thumbnail"
                                style={{
                                  width: '150px',
                                  height: '150px',
                                  objectFit: 'cover',
                                }}
                              />
                            </div>
                            <h3 className="card-title text-center">{v.designation}</h3>
                            <h4 className="card-title">{v.name}</h4>
                            <p className="card-text">{v.description}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* You can add additional carousel items if needed */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}





            </> : <></>
        }
      </div>


      <div>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/5.3.45/css/materialdesignicons.css" integrity="sha256-NAxhqDvtY0l4xn+YVa6WjAcmd94NNfttjNsDmNatFVc=" crossOrigin="anonymous" />


      </div>








      <Footer/>
    </div>
  );
}

export default Companypage;

import React, { useState } from "react";
import axios from "axios";
import Navmain from "../../../../main/header";
import Footer from "../../../../main/footer";
import { useEffect, useRef } from "react";
import { json, useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set,update, onValue, off, onValueChange } from "firebase/database";
import { useMediaRecorder } from "use-media-recorder"; // Install via npm if needed

import Whatsapp from '../../../../components/landingpage/whatsapp';


// import database from '@react-native-firebase/database';
// import { firebase } from '@react-native-firebase/database';
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

function App() {

  let activeChatRef = null; // Keeps track of the currently active chat reference

  const navigate = useNavigate();
  const [uid, setUid] = useState("");
  const [msg, setMsg] = useState("");
  const [allviewMsg, setAllViewMsg] = useState([]);
  const [file, setFile] = useState('');

  const [chat, setChat] = useState([]);
  const [chatid, setChatid] = useState("");
  const [roll, setroll] = useState("");
  const [senderid, setsenderid] = useState("");
  const [uiduser, setuiduser] = useState("");
  const [Chatid_NotficationRCV, setChatid_NotficationRCV] = useState("");
  const [avatars, setAvatars] = useState({}); // State to store avatars


  const handleSpanClick = (id) => {
    // Update the URL without reloading the page
    const newUrl = `/chat?cid=${id}`;
    window.history.pushState({}, '', newUrl);

    // Optionally, you can trigger any side effects here
    console.log(`Navigated to ${newUrl}`);
  };


  // Add this state at the top of your component
  const [mediaRecorder, setMediaRecorder] = useState(null); // State for MediaRecorder
  const [recording, setRecording] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [audioURL, setAudioURL] = useState(null);
  const [duration, setDuration] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  const [audioDurations, setAudioDurations] = useState({}); // Store durations of all audio
  const [audioProgress, setAudioProgress] = useState({}); // Store current progress for all audio

  const handleLoadedMetadata = (event, sourceId) => {
    const audioElement = event.target;
    const duration = audioElement.duration; // Get the total duration of the audio
    const formattedDuration = formatTime(duration);
  
    // Set the duration as part of a custom attribute or update UI as needed
    audioElement.setAttribute("data-duration", formattedDuration);
  };
  
  const handleTimeUpdate = (event) => {
    const audioElement = event.target;
    const currentTime = audioElement.currentTime; // Get the current playback time
    const formattedCurrentTime = formatTime(currentTime);
  
    // Update the displayed current time dynamically
    audioElement.setAttribute("data-current-time", formattedCurrentTime);
  };
  
  // Helper function to format time
  const formatTime = (time) => {
    if (isNaN(time) || time === undefined) return "0:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  
  // startRecording function
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);

      const chunks = [];
      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      recorder.onstop = () => {
        const audioBlob = new Blob(chunks, { type: "audio/mpeg" });
        setRecordedAudio(audioBlob);
        setAudioURL(URL.createObjectURL(audioBlob)); // For playback
      };

      recorder.start();
      setMediaRecorder(recorder); // Save the recorder instance
      setRecording(true);
      setDuration(0);

      // Start timer
      const id = setInterval(() => setDuration((prev) => prev + 1), 1000);
      setIntervalId(id);
    } catch (err) {
      alert("Error starting recording:" + err);
    }
  };

  // stopRecording function
  const stopRecording = () => {
    if (!mediaRecorder) return; // Ensure the recorder exists
    mediaRecorder.stop(); // Stop the recording
    mediaRecorder.stream.getTracks().forEach((track) => track.stop()); // Stop all audio tracks
    setRecording(false);

    // Stop the timer
    clearInterval(intervalId);
    setIntervalId(null);
  };

  const cancelRecording = () => {
    setRecordedAudio(null);
    setAudioURL(null);
    setRecording(false);
  
    // Stop timer
    clearInterval(intervalId);
    setDuration(0);
  };

  const sendRecording = () => {
    if (recordedAudio) {
      const audioFile = new File([recordedAudio], "audio_message.mp3", { type: "audio/mpeg" });
      ChatboxImage(audioFile, "audio", audioFile.name);
      cancelRecording(); // Reset after sending
    }
  };
  
  
  

  // Firebase Work

  const firebaseConfig = {
    apiKey: "AIzaSyCNThPGDzsQTuxrGZYBuG-H8yCK4CTg1Jo",
    authDomain: "alahdeen-24173.firebaseapp.com",
    databaseURL: "https://alahdeen-24173-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "alahdeen-24173",
    storageBucket: "alahdeen-24173.appspot.com",
    messagingSenderId: "298890559548",
    appId: "1:298890559548:web:6fda9c54ae5e295d868339",
    measurementId: "G-6Q2NLSNF9T"
  };
  const app = initializeApp(firebaseConfig);

  const db = getDatabase(app);

  const markMessagesAsSeen = (chatid) => {
    const data = new FormData();
    data.append("uid", uid);
    data.append("status", "seen");
    data.append("cid", chatid);
  
    axios({
      method: "post",
      url: "/mainsite/chatbox/chat.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("Chatbox user list view: ", s.data);
        const responseMessages = s.data;
  
        if (uid && responseMessages && Array.isArray(responseMessages)) {
          // Loop through all messages in the response
          responseMessages.forEach((message) => {
            if (message.cid == chatid && message.sendby != uid) {
              updateFirebaseDatabaseRecord(chatid, message.id, { message_status: 'seen' });
            }
          });
          //clickChatbox();
        }
      })
      .catch((error) => {
        console.error("Error marking messages as seen:", error);
      });
  };
  

  const updateFirebaseDatabaseRecord = (chatid, messageId, updates) => {
    const db = getDatabase(app);
  
    try {
      // Create a reference to the specific message you want to update
      const messageRef = ref(db, `/chat/${chatid}/${messageId}`);
      
      // Update only the fields provided in the `updates` object
      update(messageRef, updates);
      
      } catch (error) {
    }
  };
  
  

  const saveToFirebaseDatabse = async (msg) => {
    const chatid = msg[0].cid;
    const id = msg[0].id;
    const send = msg[0].sendby;
    const mess = msg[0].mesg;
    const date = msg[0].date;
    const status = msg[0].status;
    const time = msg[0].time;
    const type = msg[0].type;
    const user_name = msg[0].user_name ?? '';
    const message_status = msg[0].message_status ?? 'sent';
  
    const db = getDatabase(app);
    set(ref(db, '/chat/' + chatid + '/' + id), {
      id: id,
      chat_id: chatid,
      sent_by: send,
      message: mess,
      date: date,
      status: status,
      time: time,
      type: type,
      user_name: user_name,
      message_status: message_status, // Add status
    });
    jumpToLastChild();
  };

  // Function to directly jump to the last child
  const jumpToLastChild = () => {
    setTimeout(() => {
      const chatList = document.querySelector(".chat-list");
      if (chatList) {
        const lastMessage = chatList.querySelector("li:last-child");
        if (lastMessage) {
          lastMessage.scrollIntoView({ behavior: "auto", block: "nearest" });
        }
      } else {
        console.warn("chat-list not found");
      }
    }, 500); // Delay to allow rendering
  };  

  // Function to jump from the second-to-last child to the last child
  const smoothScrollToLastChild = () => {
    const chatList = document.querySelector(".chat-list");
    if (chatList) {
      const secondLastMessage = chatList.querySelector("li:nth-last-child(2)");
      const lastMessage = chatList.querySelector("li:last-child");
      if (secondLastMessage) {
        secondLastMessage.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
      if (lastMessage) {
        setTimeout(() => {
          lastMessage.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }, 300); // Delay as needed
      }
    } else {
      console.warn("chat-list not found");
    }
  };
  
  

  const scrollToBottom = () => {
    const chatList = document.querySelector(".chat-list");
    if (chatList) {
      const lastMessage = chatList.querySelector("li:last-child");
      if (lastMessage) {
        lastMessage.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    } else {
      console.warn("chat-list not found");
    }
  };
  

  const LoadChat = async (as) => {
    const data = new FormData();
    data.append("status", 'view');
    data.append("cid", as);

    axios({
      method: "post",
      url: "/mainsite/chatbox/chat.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("chat list ", s.data);
        const a = s.data;
        if (a[0].code === "pass") {
          //setMessages(json)
        }
      })
      .catch((s) => {
        if (s.message == "Network Error") {
          console.log("network");

        } else {
          console.log("network");
        }
      });
  };
  // const reference = firebase
  // .app()
  // .database('https://alahdeen-24173-default-rtdb.asia-southeast1.firebasedatabase.app/')
  // .ref('/chat');

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));

    if (v === null) {
    } else {
      setUid(v[0].id);
      const uiduser = localStorage.getItem("chatuserid");
      const chatid = localStorage.getItem("piduser");
      if (chatid === "") {
        window.location.href = '/main';
      } else {
        // alert(puiduser);
        setAllViewMsg([]);
        setuiduser(uiduser);
        viewchat(v[0].id, uiduser);
        Viewchatbox(v[0].id);
        setroll(v[0].roll);
        getFirebasechat(chatid);
      }
    }
  }, []);

  const viewchat = async (id, i) => {
    const data = new FormData();
    data.append("one", id);
    data.append("two", i);

    axios({
      method: "post",
      url: "/pro/chatbox/chatbox.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("chat list ", s.data);
        const a = s.data;
        if (a[0].code == "pass") {
          // setProductList(a);
          localStorage.setItem("List abc", JSON.stringify(s.data));
        }
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };
  const getFirebasechat = (id) => {
    // Clear previous messages and stop previous listeners
    setAllViewMsg([]);
  
    if (!id) {
      return false;
    }
  
    const db = getDatabase(app);
    const chatRef = ref(db, `/chat/${id}`);
  
    // Detach the previous listener if it exists
    if (activeChatRef) {
      off(activeChatRef);
      activeChatRef = null;
    }
  
    // Set the new chat reference as active
    activeChatRef = chatRef;
  
    onValue(chatRef, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists() && data != null) {
        const messages = Object.values(data);
        setAllViewMsg(messages);
        markMessagesAsSeen(id);
        console.log("Firebase chat", messages);
        jumpToLastChild();
      } else {
        console.log("No Chat found with that client...");
      }
    });
  };
  const clickChatbox = () => {
    // Extract `cid` from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const cid = urlParams.get('cid');
  
    if (cid) {
      // Find the button with the ID that matches `cid`
      const targetButton = document.getElementById(cid);
  
      // Trigger a click if the button is found
      if (targetButton) {
        targetButton.click();
        return; // Exit the function after clicking
      }
    }
  
    // Fallback: Click the first `li` with the class `chat-list-item`
    const chatItems = document.querySelectorAll('li.chat-list-item span');
    if (chatItems.length > 0) {
      chatItems[0].click(); // Click the first item
    }
  };
  
  

  // Function to convert URLs in the message to clickable links
  const makeLinksClickable = (message) => {
    // Ensure message is a string
    if (typeof message !== "string") {
      console.error("Invalid message type:", typeof message);
      return message; // Return the input as is if it's not a string
    }

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return message.replace(
      urlRegex,
      (url) =>
        `<a href="${url}" target="_blank" rel="noopener noreferrer" style="color: blue; text-decoration: underline;">${url}</a>`
    );
  };


  const Viewchatbox = async (id) => {
    const data = new FormData();
    data.append("uid", id);
  
    axios({
      method: "post",
      url: "/mainsite/chatbox/view_inbox.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("chatbox user list view ", s.data);
        const a = s.data;
        setChat(a);
  
        if(a[0].code && a[0].code == "fails") {
          window.location.href = '/main';
          return;
        }
  
        // Extract `cid` from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const cid = urlParams.get('cid');
  
        if (cid) {
          // Find the chat with the matching `senderid`
          const matchedChat = a.find(chat => chat.senderid === cid);
  
          if (matchedChat) {
            console.log("Matched chat: ", matchedChat.chat);
            getFirebasechat(matchedChat.chat); // Load chat data from Firebase
            setChatid(matchedChat.chat); // Set the chat ID
          } else {
            // If no `cid` is provided, default to the first chat
            if (a.length > 0) {
              console.log("chatid testing viewbox ", a[0].chat);
              getFirebasechat(a[0].chat);
              setChatid(a[0].chat);
            }
          }
        } else {
          // If no `cid` is provided, default to the first chat
          if (a.length > 0) {
            console.log("chatid testing viewbox ", a[0].chat);
            getFirebasechat(a[0].chat);
            setChatid(a[0].chat);
          }
        }
        //clickChatbox();
      })
      .catch((error) => {
        console.error("Error fetching chatbox data: ", error);
      });
  };
  

  const chatinbox = async (chatid) => {
    setMsg("");
    const data = new FormData();
    data.append("uid", uid);
    data.append("status", "in");
    data.append("cid", chatid);
    data.append("msg", msg);
    data.append("type", "message");

    axios({
      method: "post",
      url: "/mainsite/chatbox/chat.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("chatbox message ", s.data);
        const a = s.data;
        saveToFirebaseDatabse(a);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  const generateAvatar = (name, size = 200) => {
      // Extract initials
      const words = name.trim().split(/\s+/); // Split by spaces
      const initials = words.length > 1 
          ? words[0][0].toUpperCase() + words[1][0].toUpperCase() // First letter of first and second word
          : words[0][0].toUpperCase(); // Only first letter if one word

      // FNV-1a hashing for unique hash
      const generateColorFromName = (name) => {
          let hash = 2166136261; // FNV-1a 32-bit offset basis
          for (let i = 0; i < name.length; i++) {
              hash ^= name.charCodeAt(i);
              hash += (hash << 1) + (hash << 4) + (hash << 7) + (hash << 8) + (hash << 24);
          }
          // Convert hash to RGB values
          const r = (hash & 0xFF0000) >> 16; // Red
          const g = (hash & 0x00FF00) >> 8;  // Green
          const b = hash & 0x0000FF;         // Blue
          return `rgb(${r}, ${g}, ${b})`;    // Return as RGB string
      };

      const bgColor = generateColorFromName(name);

      // Create canvas
      const canvas = document.createElement('canvas');
      canvas.width = canvas.height = size;
      const ctx = canvas.getContext('2d');

      // Background color
      ctx.fillStyle = bgColor;
      ctx.fillRect(0, 0, size, size);

      // Text styling
      ctx.fillStyle = '#ffffff';
      ctx.font = `${size / 2}px Arial`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(initials, size / 2, size / 2);

      // Return the image data URL
      return canvas.toDataURL('image/png');
  };


  const ChatboxImage = async (file, type, name) => {
    setMsg("");
    const data = new FormData();
    data.append("uid", uid);
    data.append("status", "in");
    data.append("cid", chatid);
    data.append("msg", name);
    data.append("type", type);
    data.append("img", file);

    axios({
      method: "post",
      url: "/mainsite/chatbox/chat.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("chatbox view ", s.data);

        const a = s.data;
        if (a[0].code == "pass") {
          saveToFirebaseDatabse(a);
        }

      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };
  // const viewinbox = async (chatid) => {
  //   const data = new FormData();
  //   data.append("status", "view");
  //   data.append("cid", chatid);
  //   axios({
  //     method: "post",
  //     url: "/pro/chatbox/chat.php",
  //     data: data,
  //     headers: { "Content-Type": "multipart/form-data" },
  //   })
  //     .then((s) => {
  //       console.log("chatbox view ", s.data);
  //       setAllViewMsg(s.data);
  //       console.log(chatid);
  //     })
  //     .catch((s) => {
  //       console.log("here catch =>>", s);
  //     });
  // };

  return (
    <>
      <Navmain />
      <main className="main">
      <section

        style={{ marginTop: "10px", marginBottom: "50px", }}
        id="main-content"
      >
        <div className=" container">
          <div
            style={{
              border: "1px solid black",
              borderRadius: "15px",
              padding: "2px",
            }}
            className="row"
          >
            <div className="col-md-4 col-lg-5 col-xl-4 mb-4 mb-md-0">
              <h5 className="font-weight-bold mb-3 text-center text-lg-start">
                <div
                  onClick={() => {
                    // navigate("/");
                  }}
                  className="text-left col-xl-12"
                >Chat Box
                </div>
                <hr />
              </h5>
              <div   >
                <div data-bs-spy="scroll" data-bs-target="#navbar-example3" data-bs-offset="0" tabindex="0" className="card-body">
                  <ul className="list-unstyled mb-0">
                    
                      <SimpleBar
                        style={{
                          maxHeight: 600,
                          paddingRight: "20px",
                          cursor: "pointer",
                        }}
                      >
                        {chat.map((v, i) => (
                          <>
                            <li
                              style={{
                                backgroundColor: chatid === v.chat ? "#303347" : "#f7f5f5",
                                borderRadius: "10px"
                              }}
                              className="p-2 mb-2 chat-list-item border-bottom"
                            ><span
                              onClick={() => {
                                // viewinbox(v.chat);
                                getFirebasechat(v.chat)
                                setChatid(v.chat);
                                setChatid_NotficationRCV(v.chat);
                                setsenderid(v.senderid);
                                handleSpanClick(v.senderid);
                              }}
                              id={v.senderid ? v.senderid : 'default-id'}
                              className="d-flex justify-content-between"
                              style={{
                                "marginTop": "1px"
                              }}
                            >
                              <div className="d-flex flex-row" >
                                <span
                                  style={{ "backgroundColor": 'white' }}
                                  src={
                                    v.name
                                      ? generateAvatar(v.name) // Use `generateAvatar` if `v.user_name` exists
                                      : "https://alahdeen.com/assets/images/logos/logo.png" // Default image
                                  }
                                  alt="avatar"
                                  className="rounded-circle d-flex align-self-center me-4 "
                                  width="100"
                                />
                                <img
                                  style={{ "backgroundColor": 'white', }}
                                  src={
                                    v.name
                                      ? generateAvatar(v.name) // Use `generateAvatar` if `v.user_name` exists
                                      : "https://alahdeen.com/assets/images/logos/logo.png" // Default image
                                  }
                                  alt="avatar"

                                  className="rounded-circle d-flex align-self-center me-4"
                                  width="80"
                                  height="80"
                                />
                                <div className="pt-1 centered-content">
                                  {
                                    chatid == v.chat ? <>
                                      <span style={{ color: "#ffffff" }} >{v.name}</span>
                                    </> : <>
                                      <span style={{ color: "#303347" }} >{v.name}</span>
                                    </>
                                  }

                                  {/* <p style={{color:"white"}} className="fw-bold mb-0 text-uppercase">{v.name}</p> */}


                              

                                </div>
                              </div>
                            </span>
                            </li>
                          </>
                        ))}
                      </SimpleBar>
                    
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-8 col-lg-7 col-xl-8">
              <div style={{ backgroundColor: `#f7f5f5`}} className="card">
                {/* <div style={{ height: "80px" }}></div> */}
                {/* <div className="col-md-6 col-lg-7 col-xl-8"> */}
                <ul
                  // style={{
                  //   height: '800px',
                  //   scrollBehavior: 'auto',
                  //   scrollBehavior: 'smooth',
                  // }}
                  style={{ scrollBehavior: "smooth", }}
                  className="list-unstyled chat-list"
                >
                  <SimpleBar style={{ maxHeight: 375, paddingRight: "0px" }}>
                    {allviewMsg.map((v, i) => (
                      <>
                        {v.sent_by == uid ? (
                          <>
                            <li className="d-flex justify-content-between mb-4">
                              <img
                                src={
                                  v.user_name
                                    ? generateAvatar(v.user_name) // Use `generateAvatar` if `v.user_name` exists
                                    : "https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI=" // Default image
                                }
                                alt="avatar"
                                className="rounded-circle d-flex align-self-start me-3 shadow-1-strong"
                                width="60"
                                height="60"

                              />
                              <div style={{ backgroundColor: "#e6e6e6", paddingTop: "0px", paddingBottom: "0px" }} className="card w-100">
                                <div className="card-body">
                                  {
                                    v.type == "image" ? <>
                                      <a download={v.message} href={v.message} target="_blank" title="ImageName">
                                        <img src={v.message} height="auto" width="200px" />
                                      </a>
                                    </> : v.type == "file" ? <>
                                      <a download={v.message} href={v.message} target="_blank" title="ImageName">
                                      <p style={{ color: "blue" }} className="mb-0">{v.message.split('/').pop()} {/* Extracts the file name */}</p>
                                      </a>
                                    </> : v.type === "audio" ? (
                                        <>
                                        <audio
                                            controls
                                            src={v.message}
                                            onLoadedMetadata={(e) => handleLoadedMetadata(e, v.id)}
                                            onTimeUpdate={(e) => handleTimeUpdate(e)}
                                            data-duration="0:00"
                                            data-current-time="0:00"
                                          >
                                            Your browser does not support the audio element.
                                          </audio>
                                        <div>
                                        </div>
                                        </>
                                      ) : v.type == "imageText" ? <> <p style={{ color: "black" }} className="mb-0" dangerouslySetInnerHTML={{ __html: v.message }}></p>
                                    </> : <> <p style={{ color: "black" }} className="mb-0" dangerouslySetInnerHTML={{ __html: makeLinksClickable(v.message) }}></p></>
                                  }

                                  <small style={{ float: 'right' }}>
                                    {v.message_status === "delivered" ? (
                                      <i className="fas fa-check-double"></i>
                                    ) : v.message_status === "seen" ? (
                                      <i className="fas fa-check-double text-primary"></i>
                                    ) : (
                                      <i className="fas fa-check-double"></i>  // This handles "sent"
                                    )}
                                  </small>

                                </div>
                              </div>
                            </li>
                          </>
                        ) : (
                          <>
                            <li className="d-flex justify-content-between mb-4">
                              <div style={{ backgroundColor: "#d9ead3", paddingTop: "0px", paddingBottom: "0px" }} className="card w-100">
                                <div className="card-body">
                                  {
                                    v.type == "image" ? <>
                                      <a download={v.message} href={v.message} target="_blank" title="ImageName">
                                        <img src={v.message} height="auto" width="200px" />
                                      </a>
                                    </> : v.type == "file" ? <>
                                      <a download={v.message} href={v.message} target="_blank" title="ImageName">
                                      <p style={{ color: "blue" }} className="mb-0">{v.message.split('/').pop()} {/* Extracts the file name */}</p>
                                      </a>
                                    </> : v.type === "audio" ? (
                                        <>
                                          <audio
                                            controls
                                            src={v.message}
                                            onLoadedMetadata={(e) => handleLoadedMetadata(e, v.id)}
                                            onTimeUpdate={(e) => handleTimeUpdate(e)}
                                            data-duration="0:00"
                                            data-current-time="0:00"
                                          >
                                            Your browser does not support the audio element.
                                          </audio>
                                        </>
                                      ) : v.type == "imageText" ? <> <p style={{ color: "black" }} className="mb-0" dangerouslySetInnerHTML={{ __html: v.message }}></p>
                                    </> : <> <p style={{ color: "black" }} className="mb-0" dangerouslySetInnerHTML={{ __html: makeLinksClickable(v.message) }}></p></>
                                  }
                                  <small style={{ float: 'right' }}>
                                    {v.message_status === "delivered" ? (
                                      <i className="fas fa-check-double"></i>
                                    ) : v.message_status === "seen" ? (
                                      <i className="fas fa-check-double text-primary"></i>
                                    ) : (
                                      <i className="fas fa-check-double"></i>  // This handles "sent"
                                    )}
                                  </small>
                                </div>
                              </div>
                              <img
                                src={
                                  v.user_name
                                    ? generateAvatar(v.user_name) // Use `generateAvatar` if `v.user_name` exists
                                    : "https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI=" // Default image
                                }
                                alt="avatar"
                                className="rounded-circle d-flex align-self-start ms-3 shadow-1-strong"
                                width="60"
                                height="60"
                              />
                            </li>
                          </>
                        )}
                      </>
                    ))}
                  </SimpleBar>

                  <div
                    style={{
                      position: "absolute",
                      bottom: "0px",
                      float: "right",
                    }}
                    className="row"
                  >
                    <div className="col-10 col-sm-11" style={{minHeight: "40px"}}>
<div
  className="d-flex align-items-center"
  style={{
    display: "flex",
    alignItems: "center",
    gap: "10px",
  }}
>

  {/* Cancel and Send Buttons with Audio Player */}
  {recordedAudio && (
    <>
<audio
  controls
  src={audioURL}
  onLoadedMetadata={(e) => handleLoadedMetadata(e, "local")}
  onTimeUpdate={(e) => handleTimeUpdate(e, "local")}
  data-duration="0:00"
  data-current-time="0:00"
  style={{
    height: "25px",
  }}
>
  Your browser does not support the audio element.
</audio>


      <button
        className="btn btn-success btn-rounded"
        onClick={sendRecording}
        style={{
          cursor: "pointer",
          borderRadius: "20px",
          marginLeft: "10px",
        }}
        title="Send Recording"
      >
        <i className="fas fa-paper-plane"></i>
      </button>
    </>
  )}
      {/* Stop Recording */}
      {recording && (
    <div
      style={{
        display: "flex",
        flexDirection: "column", // Stack the timer and button vertically
        alignItems: "center", // Center the timer and button horizontally
        gap: "5px", // Space between the timer and button
      }}
    >
      <span
        style={{
          fontSize: "20px",
          color: "red",
          marginTop: "10px", // Space below the timer
        }}
      >
        {`${Math.floor(duration / 60)}:${String(duration % 60).padStart(2, "0")}`}
      </span>
    </div>
  )}
</div>

</div>

                    <div style={{ backgroundColor: "primary" }} className="col-3 col-sm-3 col-xl-2">
                      <div className="btn-group">
                        {/* Start Recording */}
  {!recording && !recordedAudio && (
    <button
      className="btn btn-dark btn-rounded"
      onClick={startRecording}
      style={{ cursor: "pointer", borderRadius: "20px", marginRight:"5px" }}
      title="Start Recording"
    >
      <i className="fas fa-microphone"></i>
    </button>
  )}
    {/* Stop Recording */}
    {recording && (
    <div
      style={{
        display: "flex",
        flexDirection: "column", // Stack the timer and button vertically
        alignItems: "center", // Center the timer and button horizontally
        gap: "5px", // Space between the timer and button
      }}
    >
      <button
        className="btn btn-danger btn-rounded"
        onClick={stopRecording}
        style={{
          cursor: "pointer",
          borderRadius: "20px",
          marginRight:"5px"
        }}
        title="Stop Recording"
      >
        <i className="fas fa-stop"></i>
      </button>
    </div>
  )}
    {/* Cancel and Send Buttons with Audio Player */}
    {recordedAudio && (
    <>
      <button
        className="btn btn-secondary btn-rounded"
        onClick={cancelRecording}
        style={{
          cursor: "pointer",
          borderRadius: "20px",
          marginRight:"5px" 
        }}
        title="Cancel Recording"
      >
        <i className="fas fa-times"></i>
      </button>
    </>
  )}
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFile"
                          onChange={(v) => {
                            const file = v.target.files[0];

                            if (file) {
                              const fileType = file.type;
                              setFile(file);
                              // Check if the file is an image
                              if (fileType.startsWith("image/")) {
                                // Trigger functions for images
                                ChatboxImage(file, 'image', file.name);
                              } else {
                                // Trigger function for non-image files
                                ChatboxImage(file, 'file', file.name);
                              }
                            }
                          }}
                        />
                        <label style={{ width: "65px", cursor: "pointer"}} className="custom-file-label text-center" htmlFor="customFile">
                          <i className="fas fa-paperclip"></i> {/* Attach icon */}
                        </label>
                      </div>
                      </div>
                    </div>
                    <div className="col-8 col-sm-7 col-xl-6">
                      <div className="bg-white">
                        <div className="">
                          {/* <label className="form-label" for="textAreaExample2">
                      Message
                    </label> */}

                          <input
                            placeholder="Enter Message Here"
                            onChange={(v) => {
                              setMsg(v.target.value);
                            }}
                            value={msg}
                            className="form-control"
                            id="textAreaExample2"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-4 col-xl-2">
                      <div className="btn-group">
                      <button
                        type="button"
                        onClick={(v) => {
                          chatinbox(chatid);
                          getFirebasechat(chatid);
                          setChatid(chatid);
                          // Viewchatbox(uid);
                        }}
                        className="btn btn-info btn-rounded  "
                      >
                        Send
                      </button>
                      <button
                        style={{marginLeft:"5px"}}
                        type="button"
                        onClick={(v) => {
                          chatinbox(chatid);
                          // viewchat();
                          getFirebasechat(chatid);
                          setChatid(chatid);
                          if (chatid != "") {
                            //localStorage.setItem("List abc", JSON.stringify(s.data));
                            if (roll == "seller") {
                              localStorage.setItem("invoicechatid", chatid);
                              navigate("/chatboxinvoice");
                            } else {
                              localStorage.setItem("invoicechatid", chatid);
                              navigate("/companydetail?business=" + senderid);
                            }
                          } else {

                          }
                        }}
                        className="btn btn-info btn-rounded"
                      >
                        <i className="ti ti-plus mx-1"></i>
                        {
                          roll == "seller" ? <>Invoice</> : <>Get Quote</>
                        }
                      </button>
                      </div>
                    </div>
                    
                  </div>
                </ul>
              </div>
            </div>
            {/* <hr /> */}
          </div>
        </div>
      </section>
      </main>
      {/* </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
}

export default App;
